import BreadcrumbBanner from "../components/BreadcrumbBanner";
import BreadcrumbList from "../components/Breadcrumb";
import VideoExamModal from "../components/videoExam/PortraitModal";
import OnlyText from "../components/videoExam/OnlyText";
import TextAndImage from "../components/videoExam/TextAndImage";
import TitleImage from "../components/videoExam/TitleImage";
import ExamForm from "../components/videoExam/UserInfoForm";
import ReactPlayer from 'react-player';
import { useState, useEffect, useCallback, useRef, useContext } from "react";
import { useNavigate, useParams, useSearchParams, useLocation } from "react-router-dom";
import context from "../hooks/context";
import localStorage from '../service/localStorageService';
import { requestQuestionnaireByEmvtoken, requestTestCategoryQuestionnaire, requestTopicPurposeQuestionnaire, requestAgainQuestionnaireByEmvtoken, requestAgainTestCategoryQuestionnaire } from '../API/request'; 
import { htmlTextLimit } from "../helps/textLimit";
import { parseCategory } from '../helps/common';
import PageTitle from "../components/PageTitle";

const VideoExam = () => {
  const defaultBreadcrumb = [
    { text: '首頁', url: '/' },
    { text: '影片測驗', url: '/examOverview' },
    { text: '測驗', url: '/', header: '測驗' },
  ]

  const getUserAgent = () =>
     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && (window.orientation !== 180 && window.orientation !== 0);

  const location = useLocation();

  const navigate = useNavigate();
  const params = useParams();
  const paramsTopicId = params.topicId;
  const paramsTopicCode = params.topicCode;
  const { setUserTestingUUID, userVideoAnswer, setUserVideoAnswer, setUserExplainWatched, categoryCode, setCategoryCode, emvToken, isFromMvdis, setExamExplain, examCode, setExamCode, setIsFirstExamResult, setIsGeneralTest } = useContext(context);
  const paramsCode = categoryCode || params.code;
  const paramsToken = params.token;
  const [searchParams] = useSearchParams();
  const previousTestingUUID = searchParams.get('previousTestingUUID');

  const [isMobileUserAgent, setUserAgent] = useState(false);
  const [isShowForm, setIsShowForm] = useState(false);
  const [examList, setExamList] = useState({});
  const [paramsId, setParamsId] = useState(params.id);
  const [breadcrumb, setBreadcrumb] = useState(defaultBreadcrumb);
  const [dateTimeDesc, setDateTimeDesc] = useState('');

  const [examData, setExamData] = useState({
    isPlaying: false,
    isShowStart: true,
    examIndex: 0,
    isQuestionType: false,
    showQuestion: false,
    played: 0,
    userClickTimes: 0,
    userClickSeconds: 0,
    isDone: false,
  })

  const [isPortraitModal, setIsPortraitModal] = useState(false);
  const [answerTemp, setAnswerTemp] = useState([]); // 記錄 user 答案
  const currentQuestionIndex = 0;
  const player = useRef();
  let isVideoEnd = useRef(false);
  const videoDesc = useRef();
  const dangerBtn = useRef(null);
  const videoBox = useRef(null);

  const validateString = (str) => {
    let _str = "";
      for (let index = 0; index < str.length; index++) {
        let escape = "";
        switch (str.charCodeAt(index)) {
          case 34: // "
            escape = "&quot;";
            break;
          case 38: // &
            escape = "&amp;";
            break;
          case 39: // '
            escape = "&#x27;";
            break;
          case 60: // <
            escape = "&lt;";
            break;
          case 62: // >
            escape = "&gt;";
            break;
          default:
            escape = str[index];
          // continue;
        }
        _str += escape;
      }
    return _str;
  }

  const submitForm = (formState, formInfo) => {
    setIsShowForm(false);
    localStorage.setItem([
      {key: localStorage.keys[0], value: false},
      {key: localStorage.keys[1], value: formState},
      {key: localStorage.keys[2], value: formInfo}
    ]);
  }

  const showForm = () => {
    const isFirstTime = localStorage.getItem(localStorage.keys[0]);
    if(isFirstTime) setIsShowForm(true);
  }

  const requestQuestionnaire = async() => {
    console.log('previousTestingUUID===>',previousTestingUUID);
    if (paramsTopicId && paramsTopicCode) {
      return await requestTopicPurposeQuestionnaire(paramsTopicCode, paramsTopicId);
    } else if (isFromMvdis) {
      return previousTestingUUID ? await requestAgainQuestionnaireByEmvtoken(paramsToken, previousTestingUUID) : await requestQuestionnaireByEmvtoken(paramsToken);
    }
    return previousTestingUUID ? await requestAgainTestCategoryQuestionnaire(paramsCode, paramsId, previousTestingUUID) : await requestTestCategoryQuestionnaire(paramsCode, paramsId);
  }

  const getQuestionnaire = async () => {
    const questionnaireInfo = await requestQuestionnaire();
    const exams = [];
    let title = '';
    let desc = '';
    let id = '';
    if (questionnaireInfo && questionnaireInfo.rc === '0000' && questionnaireInfo.data) {
      const { data } = questionnaireInfo;
      const {testingUUID, name, testingVideos, lastModifiedDateTimeDesc, videoBaseCode, videoBaseName, videoCategoryCode, videoCategoryName, videoTopicCode, videoTopicName} = data;
      title = name;
      desc = data.description;
      id = testingUUID;
      testingVideos.forEach((item, index) => {
        const {testingVideoUUID, name,videoTestingModeName, description, videoUrl, clickLimit, videoTestingMode, questionAndAnswerInfo, buttonName} = item;
          let events = null;
          if (questionAndAnswerInfo?.triggerType) {
            const {triggerType, triggerTime, timeRangeFrom, timeRangeTo, question, questionImageUrl, isMultipleCorrectAnswer, answerOptionList, qaType } = questionAndAnswerInfo;
            let showTime = null;
            let showTimeRange = null;
            let questionOptions = [];
            let type = 'OnlyText';
            if (triggerType === 'AUTO') {
              const min = parseInt(triggerTime / 60, 10);
              const sec = triggerTime % 60;
              let secTxt = (sec > 9) ? `${sec}` : `0${sec}`;
              showTime = `0${min}:${secTxt}`;
            } else if (triggerType === 'CLICK') {
              const startMin = parseInt(timeRangeFrom / 60, 10);
              const startSec = timeRangeFrom % 60;
              let startSecTxt = (startSec > 9) ? `${startSec}` : `0${startSec}`;
              const endMin = parseInt(timeRangeTo / 60, 10);
              const endSec = timeRangeTo % 60;
              let endSecTxt = (endSec > 9) ? `${endSec}` : `0${endSec}`;
              showTimeRange = {
                start: `0${startMin}:${startSecTxt}`, 
                end: `0${endMin}:${endSecTxt}`, 
              };
            }
            if (answerOptionList?.length > 0) {
              questionOptions = answerOptionList.map(({optionId,option,optionImageUrl}) => ({ no: optionId,text: option,optionImage: optionImageUrl}));
              if (qaType === 'Q_IMG') {
                type = 'TitleImage';
              } else if (qaType === 'A_IMG') {
                type = 'TextAndImage';
              }
            }
            events = [{
              questionText: question,
              triggerType,
              showTime,
              showTimeRange,
              single: !isMultipleCorrectAnswer,
              type,
              questionImage: questionImageUrl,
              questionOptions
            }];
          }
          exams[index] = {
            questionId: testingVideoUUID,
            questionImage: item.questionImageUrl,
            type: videoTestingMode,
            title: name,
            videoTestingModeName,
            description,
            videoUrl,
            clickLimit,
            events,
            buttonName
          };
      });  

      if (paramsTopicId && paramsTopicCode) {
        const { url, header } = breadcrumb[2];
        breadcrumb[1] = {text: videoBaseName, url: `/topicOverview/${videoBaseCode}`};
        breadcrumb[2] = {text: videoCategoryName, url: `/topicOverview/${videoCategoryCode}`};
        breadcrumb[3] = {text: videoTopicName, url: `/topicArticle/${videoTopicCode}`};
        breadcrumb[4] = {
          text: title,
          url,
          header
        }
      } else if (isFromMvdis) {
       // no change breadcrumb
       breadcrumb[2].text = title;
      } else {
        const { url, header } = breadcrumb[2];
        breadcrumb[2] = {text: parseCategory(paramsCode), url: `/examOverview/${paramsCode}`};
        breadcrumb[3] = {
          text: title,
          url,
          header
        }
      }
      
      
      setBreadcrumb(breadcrumb);
      setUserTestingUUID(id);
      setParamsId(id);
      setExamList({
        id,
        title, 
        desc,
        examQuestions: exams
      });
      setDateTimeDesc(lastModifiedDateTimeDesc);
    } else {
      console.error("getQuestionnaire  error");
      navigate(`/examOverview`, { replace: true })
    }
  }

  Element.prototype.documentOffsetTop = function () {
    return this.offsetTop + ( this.offsetParent ? this.offsetParent.documentOffsetTop() : 0 );
  };


  const playVideo = () => {
    setExamData(state => ({
      ...state,
      isPlaying: true,
      isShowStart: false,
    }))

    if(dangerBtn?.current) {
      const timer = setTimeout(() => {
        dangerBtn.current.focus();    
        // scroll to element
        // var top = videoBox.current.documentOffsetTop() - ( window.innerHeight / 6 );
        // window.scrollTo( 0, top );

        videoBox.current.scrollIntoView();
        clearTimeout(timer)
      }, 100);
    }
  }

  const transferSecond = (time) => {
    const minute = Number(time.split(':')[0]);
    const second = Number(time.split(':')[1]);
    return minute * 60 + second;
  }

  const recodeClickTime = () => {
    if(examData.isQuestionType || !examData.isPlaying) return;
    const clickSeconds = player.current.getCurrentTime().toFixed(2);

    setExamData(state => ({
      ...state,
      userClickSeconds: clickSeconds
    }))

    setAnswerTemp(arr => [...arr, clickSeconds]);
    
    
    if(examData.isPlaying && examData.userClickTimes <= examList.examQuestions[examData.examIndex]?.clickLimit) {
      const circles = document.querySelectorAll('.circle');
      circles[examData.userClickTimes]?.classList.add('active');
    }

    setExamData(state => ({
      ...state,
      userClickTimes: state.userClickTimes + 1
    }))
  }

  const handleEnded = () => {
    isVideoEnd.current = true;
    const { examQuestions } = examList;
    const { examIndex } = examData;
    const { questionId, type } = examQuestions[examIndex];
    const result = { testingVideoUUID: questionId, clickTimeList: [], selectAnswerOptionIds: [] };

    answerTemp.forEach((answer) => {
      if (typeof(answer) == 'string') {
        result.clickTimeList[result.clickTimeList.length] = parseFloat(answer);
      } else {
        result.selectAnswerOptionIds[result.selectAnswerOptionIds.length] = answer;
      }
    });
    setUserVideoAnswer(arr => [...arr, result]);
    setAnswerTemp([]);

    handleIsQuestion();

    setExamData(state => ({
      ...state,
      isPlaying: false,
      isShowStart: true,
      showQuestion: false,
      userClickTimes: 0,
      userClickSeconds: 0,
      isDone: false
    }))

    const circles = document.querySelectorAll('.circle');
    circles.forEach(item => {
      item?.classList.remove('active');
    })

    if(examData.examIndex + 1 === examList.examQuestions.length && paramsId) {
      navigate(`/examResult/${validateString(paramsId)}`, { replace: true });
    } else {
      setExamData(state => ({
        ...state,
        examIndex: state.examIndex + 1
      }))

      focusDangerBtn(examList.examQuestions, examData.examIndex);
    }
  }

  const focusDangerBtn = (examQuestions, examIndex) => {
    const nextExamType = examQuestions[examIndex + 1].type;
    if(nextExamType !== 'QUESTIONNAIRE' && dangerBtn) {
      // 等 disable 打開後再 focus
      const timer = setTimeout(() => {
        dangerBtn.current?.focus();
        clearTimeout(timer)
      }, 100);
    }
  }

  const handleIsQuestion = useCallback(() => {
    const { examQuestions } = examList;
    if(examQuestions?.length > 0) {
      setExamData(state => ({
        ...state,
        isQuestionType: examQuestions[examData.examIndex].type === 'QUESTIONNAIRE'
      }))
    }
  }, [examData.examIndex, examList])


  const handleProgress = (progress) => {
    const durationSeconds = player.current.getDuration();
    const currentSeconds = player.current.getCurrentTime() ? player.current.getCurrentTime().toFixed(2) : 0;
    const played = Math.round(((parseFloat(currentSeconds) + 1.3) / durationSeconds) * 100) / 100;
    // console.info(`currentSeconds: ${currentSeconds}`);
    // console.info(`played: ${played}`);
    setExamData(state => ({
      ...state,
      played: examData.isShowStart ? 0 : played
    }))

    if(examData.isDone) return;

    const examQuestions = examList.examQuestions[examData.examIndex];

    if(examQuestions.type !== 'QUESTIONNAIRE' && examQuestions.type !== 'COMBO') return;
    if(currentQuestionIndex < examQuestions.events.length) {
      switch (examQuestions.type) {
        case 'QUESTIONNAIRE':
          const showTime = examQuestions.events[currentQuestionIndex].showTime;
          const quesTime = transferSecond(showTime);

          if(currentSeconds >= quesTime) {
            setExamData(state => ({
              ...state,
              isPlaying: false,
              showQuestion: true,
              isDone: true,
            }))
          }
          break;

        case 'COMBO':
          // COMBO 判斷是否在時間內點擊，且 user 點擊次數不超過 clickLimit 次，
          const startTime = examQuestions.events[currentQuestionIndex].showTimeRange.start;
          const endTime = examQuestions.events[currentQuestionIndex].showTimeRange.end;
          const startSecond = transferSecond(startTime);
          const endSecond = transferSecond(endTime);

          if (examData.userClickSeconds >= startSecond && examData.userClickSeconds < endSecond && examData.userClickTimes <= examQuestions.clickLimit) {
            setExamData(state => ({
              ...state,
              isPlaying: false,
              showQuestion: true,
              isDone: true,
            }))
          }
          break;

        default:
          break;
      }
    }
  }

  const exitExam = () => {
    handleEnded();
    if (paramsId) navigate(`/examResult/${validateString(paramsId)}`, { replace: true });
  }

  const setPortraitModal = useCallback(() => {
    setUserAgent(getUserAgent());
    const horizontal = window.orientation === 180 || window.orientation === 0;
    if (horizontal && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsPortraitModal(true);
    }
  }, [])

  const fetchData = useCallback(async () => {
    try {
        await getQuestionnaire();
    } catch (error) {
      console.log('getQuestionnaire:', error);
    }
  }, [])

  const closePortraitModal = () => setIsPortraitModal(false);

  const orientationCircle = useCallback(() => {
    if(examData.isQuestionType) return;

    const mobileCircles = document.querySelectorAll('.mobile-times .circle');
    const desktopCircles = document.querySelectorAll('.desktop-times .circle');

    mobileCircles.forEach((item, idx) => {
      if(idx < examData.userClickTimes) {
        item?.classList.add('active')
      }
    })
    desktopCircles.forEach((item, idx) => {
      if(idx < examData.userClickTimes) {
        item?.classList.add('active')
      }
    })
  }, [examData])

  window.addEventListener("onorientationchange" in window ? "orientationchange" : "resize", function() {
    setPortraitModal();
  }, false);

  useEffect(() => {
    setExamExplain(null);
    showForm();
    fetchData();
    setUserExplainWatched([]);
    if (categoryCode !== paramsCode) setCategoryCode(paramsCode);
    if (examCode !== paramsId) setExamCode(paramsId);
  }, [fetchData, setUserExplainWatched])

  useEffect(() => {
    handleIsQuestion();
  }, [examList, handleIsQuestion])

  useEffect(() => {
    setUserVideoAnswer([])
  }, [setUserVideoAnswer])

  useEffect(() => {
    orientationCircle();
  }, [isMobileUserAgent, orientationCircle])

  useEffect(() => {
    setPortraitModal();
  }, [setPortraitModal])

  useEffect(() => {
    console.log('userVideoAnswer', userVideoAnswer);
  }, [userVideoAnswer])

  useEffect(() => {
    console.log('answerTemp===>',answerTemp);
  }, [answerTemp])

  useEffect(() => {
    isVideoEnd.current = examData.played >= 0 ? false : true;
  }, [examData.played])

  useEffect(() => {
    htmlTextLimit(videoDesc.current, 100);
  }, [examList.desc])

  useEffect(() => {
    setIsFirstExamResult(true);
  }, [setIsFirstExamResult])

  useEffect(() => {
    const isGeneralTest = location.state?.isGeneralTest;
    if(isGeneralTest === false) {
      setIsGeneralTest(false);
    } else {
      setIsGeneralTest(true);
    }
  }, [location, setIsGeneralTest])

  return (
    <main className={`video-exam ${isMobileUserAgent ? 'mobileAgent' : ''}`}>
      <PageTitle title={`機車危險感知教育平台 - ${breadcrumb[breadcrumb.length-1].text}`} />
      <BreadcrumbBanner breadcrumb={breadcrumb} hasSidebar={false} dateTimeDesc={dateTimeDesc} />

      <section className={!isMobileUserAgent ? 'p-3 pt-0 p-lg-0' : ''}>
        <div className="video-container">

          <div className="video-interface bg-white rounded p-3">
            測驗介面說明：<br/>
            以下的影片播放介面因屬測驗性質，故在影片開始播放後，介面上不提供暫停鍵及其他影片操控功能。
          </div>
          
          {(!isMobileUserAgent && !isFromMvdis) &&
            <div className="d-flex justify-content-end">
              <button type="button" className='btn-end btn-outline' disabled={!examData.isPlaying} onClick={exitExam}>
                結束測驗
                <span className="icon-return ms-2"></span>
              </button>
            </div>
          }

          <div className="video-box" ref={videoBox}>
            <div className='player-box'>
              <div className="player-wrapper" onClick={recodeClickTime}>
                <ReactPlayer
                  url={(examList.examQuestions?.length > 0 && examList.examQuestions[examData.examIndex].videoUrl) || ''}
                  width='100%'
                  height='100%'
                  className="react-player"
                  playing={examData.isPlaying}
                  playsinline={true}
                  ref={player}
                  onEnded={handleEnded}
                  onProgress={handleProgress}
                  progressInterval={100}
                />
              </div>

              {isMobileUserAgent &&
              <div className={'tool-bar h6 dark'}>
                <div className="bar-left">
                  <span className="mobile-video-number me-2">{examData.examIndex + 1}/{examList.examQuestions?.length}</span>

                  {!isFromMvdis? 
                    (<button
                      type="button"
                      className='btn-outline'
                      disabled={!examData.isPlaying}
                      onClick={exitExam}>
                      結束測驗
                    </button>)
                  :[]}
              
                </div>

                <div>
                  {/* {examData.isShowStart && !examData.isQuestionType &&
                  <BreadcrumbList breadcrumb={breadcrumb} />} */}

                  {!examData.isQuestionType && !examData.isShowStart &&
                  <div className="mobile-times h4">
                    <span className="me-2">點擊次數</span>
                    {examList.examQuestions?.length > 0 && 
                      new Array(examList.examQuestions[examData.examIndex]?.clickLimit).fill(0).map((item, index) => <span key={`circle_${index}`} className="circle"></span>)}
                  </div>}
                </div>

                {!examData.isQuestionType &&
                <div className="bar-right">
                  <button
                    type="button"
                    className="mobile-danger"
                    disabled={!examData.isPlaying}
                    onClick={recodeClickTime}>
                    <span className="icon-hand"></span>
                  </button>
                </div>}

              </div>}

            </div>

            {!isMobileUserAgent &&
            <span className="video-number me-2">
              {examData.examIndex + 1}/{examList.examQuestions?.length}
            </span>}

            {isShowForm && <ExamForm submitForm={submitForm}/>}

            {examData.isShowStart &&
            <div className={`video-question ${examData.isShowStart ? 'semi-darkness' : ''}`}>
              <div className="start-box">
                <button type="button"
                  className="btn-large start h3_1"
                  onClick={playVideo}>開始測驗</button>
                <div>
                  <div className="mb-2">
                    本題為
                    <span className="video-name">{(examList.examQuestions?.length > 0 && examList.examQuestions[examData.examIndex].videoTestingModeName) || ''}</span>
                    影片，請仔細閱讀以下的測驗說明
                  </div>
                  <div className="video-desc" ref={videoDesc} dangerouslySetInnerHTML={{__html: (examList.examQuestions?.length > 0 && examList.examQuestions[examData.examIndex].description) || ''}}></div>

                </div>
              </div>
            </div>}

            <progress max={1} value={examData.played} className={`progress-bar ${isVideoEnd.current ? '' : 'transition'}`}/>

            {examData.showQuestion &&
            <div className={`video-question h6 ${examData.showQuestion ? 'semi-darkness' : ''}`}>

              {examList.examQuestions[examData.examIndex]?.events[currentQuestionIndex].type === 'OnlyText' &&
              <OnlyText
                question={examList.examQuestions[examData.examIndex]?.events[currentQuestionIndex]}
                doneForQuestion={handleEnded}
                answerTemp={answerTemp}
                setAnswerTemp={setAnswerTemp} />}

              {examList.examQuestions[examData.examIndex]?.events[currentQuestionIndex].type === 'TextAndImage' &&
              <TextAndImage
                question={examList.examQuestions[examData.examIndex]?.events[currentQuestionIndex]}
                doneForQuestion={handleEnded}
                answerTemp={answerTemp}
                setAnswerTemp={setAnswerTemp} />}

              {examList.examQuestions[examData.examIndex]?.events[currentQuestionIndex].type === 'TitleImage' &&
              <TitleImage
                question={examList.examQuestions[examData.examIndex]?.events[currentQuestionIndex]}
                doneForQuestion={handleEnded}
                answerTemp={answerTemp}
                setAnswerTemp={setAnswerTemp} />}
            </div>}

          </div>

          {!isMobileUserAgent && !examData.isQuestionType &&
          <div className="desktop-times">
            {!examData.isShowStart && <div className="times h4">
              <span className="me-2">點擊次數</span>
              {examList.examQuestions?.length > 0 && 
              new Array(examList.examQuestions[examData.examIndex]?.clickLimit).fill(0).map((item, index) => <span key={`circle${index}`} className="circle"></span>)}
            </div>}
            <button
              className='btn-large danger h3'
              disabled={!examData.isPlaying}
              ref={dangerBtn}
              onClick={recodeClickTime}>
              <span className="icon-hand"></span>
              <span className="ms-1">{examList.examQuestions && examList.examQuestions[examData.examIndex]?.buttonName}</span>
            </button>
          </div>}
        </div>

        <VideoExamModal isPortraitModal={isPortraitModal} closePortraitModal={closePortraitModal} />
      </section>
    </main>
  );
}

export default VideoExam;