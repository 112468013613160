import BreadcrumbBanner from "../components/BreadcrumbBanner";
import PageTitle from "../components/PageTitle";

const Privacy = () => {
  const breadcrumb = [
    { text: '首頁', url: '/' },
    { text: '隱私權政策', url: '/privacy' },
  ]

  return (
    <main className="privacy">
      <PageTitle title="機車危險感知教育平台 - 隱私權政策" />
      <BreadcrumbBanner breadcrumb={breadcrumb} hasSidebar={false} />

      <section>
        <div className="privacy-container">
          <h2 className="title h3">隱私權政策</h2>
          <div>
            親愛的朋友，感謝您蒞臨「機車危險感知教育平台」網站，您個人的隱私權，「機車危險感知教育平台」絕對尊重並予以保護。為了幫助您瞭解， 「機車危險感知教育平台」如何保護您上網的權益，請您詳細閱讀下列資訊:<br/>
            <br/>
            <div className="text-bold">關於政策適用範圍</div>
            以下的隱私權政策，適用於您在「機車危險感知教育平台」網站活動時，所涉及的個人資料蒐集、運用與保護，但不適用於與本網站功能連結之各政府機關網站。凡經由「機車危險感知教育平台」連結之網站，各網站均有其專屬之隱私權政策，「機車危險感知教育平台」不負任何連帶責任。當您連結這些網站時，關於個人資料的保護，適用各該網站的隱私權政策。<br/>
            <br/>
            <div className="text-bold">關於個人資料之蒐集</div>
            I. 單純在「機車危險感知教育平台」網站的瀏覽及檔案下載行為，本網站並不會蒐集任何有關個人的身份資料。<br/>
            <br/>
            II. 利用「機車危險感知教育平台」所提供的各項服務，如「問卷調查」需申請人提供個人資料時，本網站會依需求請您提供姓名、聯絡電話、e-mail等個人資料。<br/>
            <br/>
            III. 網站不會記錄使用者上站的IP位址、上網時間以及在網站內所瀏覽的網頁等資料。<br/>
            <br/>
            IV. 「機車危險感知教育平台」有義務保護各申請人隱私，非經您本人同意不會自行修改或刪除任何個人資料及檔案。除非經過您事先同意或符合以下情況始得為之：<br/>
            1. 違反本網站規定事項，如出現謾罵或做人身攻擊的言論時。<br/>
            2. 保護或防衛相關個人的權利或所有權。<br/>
            3. 為保護本網站各相關單位之權益。<br/>
            <br/>
            V. 「機車危險感知教育平台」絕不會任意提供出售、交換或出租任何您的個人資料給其他團體、個人或私人企業。但有下列情形者除外：<br/>
            1. 配合司法單位合法的調查。<br/>
            2. 配合相關職權機關依職務需要之調查或使用。<br/>
            3. 基於善意相信揭露為法律需要，或為維護和改進網站服務而用於管理。<br/>
            <br/>
            <div className="text-bold">引用本局網站資料應注意事項</div>
            本局網站中所有資料(包括圖檔及文字檔)，著作權皆屬於本局所有(本局網站連結至外部之網站除外)，引用本局網站中之任何資料或連結至本局網站任一網頁前，應先徵得本局同意後方得引用。
          </div>
        </div>
      </section>
    </main>
  );
}

export default Privacy;