import ImageModal from "../components/ImageModal";
import { useState, useRef, useEffect } from "react";

const StepInfo = ({ data, targetIdx, targetTo }) => {
  const [imgModal, setImgModal] = useState({
    title: '',
    img: '',
    isShow: false,
    currentIdx: null,
    currentSubIdx: 0,
  })
  const sectionRefs = useRef([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const openImg = (img, title, idx, subIdx) => {
    if(screenWidth < 1024) return;
    setImgModal({
      title,
      img,
      currentIdx: idx,
      currentSubIdx: subIdx
    })
  }

  const closeModal = () => {
    setImgModal((state) => {
      return {
        ...state,
        currentIdx: null,
        currentSubIdx: null
      }
    })
  }

  useEffect(() => {
    if(targetIdx === null) return;
    window.scrollTo({
      top: sectionRefs.current[targetIdx].offsetTop - 40,
      behavior: "smooth"
    });
    targetTo(null);
  }, [targetIdx, targetTo])

  useEffect(() => {
    const getWindowWidth = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', getWindowWidth);

    return () => {
      window.removeEventListener('resize', getWindowWidth);
    }
  }, [])


  return (
    <div className="step-info">
      {data.map((item, idx) => <div key={item.title} className={idx !== data.length-1 ? 'step-box' : ''} ref={el => {sectionRefs.current[idx] = el}}>
        <div>步驟 {idx + 1}</div>

        <h2 className="title h3 mb-3 mb-md-4 line-clamp-1">{item.title}</h2>

        {item.data.map((subItem, subIdx) => <div key={subItem.image} className="d-md-flex  align-items-start">
          <div className="me-md-4 col-md-6">
            <button className="image" onClick={() => openImg(subItem.image, item.title, idx, subIdx)}>
              <img src={subItem.image} alt={item.title} className="img-fluid"/>
            </button>

            {imgModal.currentIdx === idx && imgModal.currentSubIdx === subIdx && <ImageModal image={imgModal.img} title={imgModal.title} closeModal={closeModal} />}

            {subIdx !== item.data.length-1 && <div className="d-flex justify-content-between align-items-center my-3">
              <div className="line"></div>
              或
              <div className="line"></div>
            </div>}
          </div>

          <div className="me-md-4 col-md-6">
            <div dangerouslySetInnerHTML={{__html: subItem.text}}></div>

            {subItem.link.length > 0 && <div className="link-box my-4">
              <div className="link-title">檔案連結</div>
              {subItem.link.map((linkItem) =>
                <a href={linkItem.url} target="_blank" key={linkItem.id} className='link' rel="noreferrer">
                  <span className="link-text">{linkItem.text}</span>
                  <span className="icon-open_in"></span>
                </a>
              )}
            </div>}
          </div>
        </div>)}
      </div>)}
    </div>
  )
}

export default StepInfo;