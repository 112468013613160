import { Link, useLocation } from "react-router-dom";
import { useRef, useState, Fragment, useEffect, useCallback } from 'react';
import { useNavigate} from "react-router-dom";
import logoText from "../assets/logoText.png";
import { getReferenceUrlInfos } from '../API/request';

const cxId = process.env.REACT_APP_CX_ID || '';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = location.pathname;
  const [isOpenMobileNav, setIsOpenMobileNav] = useState(false);
  const [isOpenMobileLink, setIsOpenMobileLink] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [navList, setNavList] = useState([]);

  const toggleMobileNav = () => setIsOpenMobileNav(!isOpenMobileNav);

  const toggleMobileLink = (item) => {
    if(item.nav) {
      setIsOpenMobileLink(!isOpenMobileLink);
    } else {
      setIsOpenMobileNav(false);
    }
  }

  const hideMobileNav = (e) => {
    if(e.target.className.includes('header-link-box')) {
      setIsOpenMobileNav(false)
    }
  }

  const addFontSizeIcon = useRef();
  const reduceFontSizeIcon = useRef();
  const guided = useRef();
  const searchInput = useRef();
  const tabStart = useRef();

  let currentFontSize = window.getComputedStyle(document.body).fontSize;

  const setFontSize = (fontSize) => {
    document.body.style.fontSize = fontSize;
    document.childNodes[1].style.fontSize = fontSize;
    currentFontSize = fontSize;
  }

  const addFontSize = () => {
    switch (currentFontSize) {
      case '16px':
        setFontSize('18px');
        addFontSizeIcon.current.classList.remove('disabled');
        reduceFontSizeIcon.current.classList.remove('disabled');
        break;

      case '18px':
        setFontSize('20px');
        addFontSizeIcon.current.classList.add('disabled');
        reduceFontSizeIcon.current.classList.remove('disabled');
        break;

      default:
        break;
    }
  }

  const reduceFontSize = () => {
    switch (currentFontSize) {
      case '20px':
        setFontSize('18px');
        reduceFontSizeIcon.current.classList.remove('disabled');
        addFontSizeIcon.current.classList.remove('disabled');
        break;

      case '18px':
        setFontSize('16px');
        reduceFontSizeIcon.current.classList.add('disabled');
        addFontSizeIcon.current.classList.remove('disabled');
        break;

      default:
        break;
    }
  }

  const handleKeypress = useCallback((e) => {
    const { key, altKey } = e;
    if (altKey && key === 'u') {
      guided.current.focus();
    }
    if (altKey && key === 's') {
      searchInput.current.focus();
    }
  },[navigate, searchKeyword]);

  const validateString = (str) => {
    let _str = "";
      for (let index = 0; index < str.length; index++) {
        let escape = "";
        switch (str.charCodeAt(index)) {
          case 34: // "
            escape = "&quot;";
            break;
          case 38: // &
            escape = "&amp;";
            break;
          case 39: // '
            escape = "&#x27;";
            break;
          case 60: // <
            escape = "&lt;";
            break;
          case 62: // >
            escape = "&gt;";
            break;
          default:
            escape = str[index];
          // continue;
        }
        _str += escape;
      }
    return _str;
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeypress);

    return () => document.removeEventListener('keydown', handleKeypress);

  }, [handleKeypress]);

  useEffect(() => {
    let defaultNavList = [
      { id: 0, text: '關於本站', url: '/sitemap'},
      { id: 1, text: '主題總覽', url: '/topicOverview'},
      { id: 2, text: '測驗說明', url: '/examInstructions'},
      { id: 3, text: '影片測驗', url: '/examOverview'},
      { id: 4, text: '問卷調查', url: '/questionnaire?url=main'},
      {
        id: 5,
        text: '相關連結',
        url: '',
        nav: []
      },
    ]

    const fetchNavList = async () => {
      const result = await getReferenceUrlInfos();
      if (result && result.rc === '0000') {
        if (result.data && result.data.length > 0) {
          defaultNavList[5].nav = result.data.map(({ name, url }) => {
            return { title: name, url };
          });;
        }
      }
      setNavList(defaultNavList);
    }
    if (navList.length === 0) {
      fetchNavList();
    }
  }, [navList, setNavList]);

  useEffect(() => {
    tabStart.current.focus();
  }, [pathName])

  return (
    <header className="header d-print-none">
      <button
        onClick={toggleMobileNav}
        className={`navbar-toggler d-md-none ${isOpenMobileNav ? 'open' : ''}`}
        type="button"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span className="icon-menu"></span>
      </button>

      <div className="header-contain position-relative">
        <div className="d-md-flex justify-content-between">
          <button
            type="button"
            className="tab-start d-none d-md-block"
            title="tab鍵起始位置"
            ref={tabStart}>
            <span>tab鍵起始位置</span>
          </button>
          {/* <a href="#content" id="gotocenter" title="移到主要內容" class="sr-only sr-only-focusable">跳到主要內容</a> */}
          <div className="logo-bg">
            <h1 className="logo-h1">
              <Link to="/"
                title='機車危險感知教育平台：回首頁'
                className="logo-img">                
                  <span className="d-none">機車危險感知教育平台</span>                
              </Link>
              <img src={logoText} className='logo-text img-fluid d-md-none' alt='回首頁'/>
            </h1>
          </div>

          <div className={`header-link-box w-100 ${isOpenMobileNav ? 'd-flex d-md-none' : 'd-none d-md-block'}`} onClick={hideMobileNav}>
            <div className="header-link d-none d-md-flex justify-content-end align-items-center">

              <button type="button" className="guided me-2" title="上方導覽連結區" accesskey="U" 
                ref={guided} >
                <span>:::</span>
              </button>

              <Link to='/' title='首頁'>首頁</Link>
              <span className="divide">|</span>

              <Link to='/sitemap' title='網站導覽'>網站導覽</Link>
              <span className="divide">|</span>

              <span>文字大小</span>
              <div className="font-icons d-flex align-items-center">
                <button
                  onClick={reduceFontSize}
                  className="font-icon icon-reduce"
                  ref={reduceFontSizeIcon}
                  title="文字縮小"
                  >
                  <span style={{display: 'none'}}>文字縮小</span>
                </button>
                <button
                  onClick={addFontSize}
                  className="font-icon icon-add"
                  ref={addFontSizeIcon}
                  title="文字放大"
                  >
                  <span style={{display: 'none'}}>文字放大</span>
                </button>
              </div>

            </div>

            <nav className="header-nav">
              <ul className="nav-lists">
                {(navList && navList.length > 0) ?  navList.map(
                  (item, itemIndex) => {
                  if (isOpenMobileNav && itemIndex === 0) return [];
                  return (
                    <li
                      key={item.id}
                      className={`nav-list ${item.nav ? 'has-subnav' : ''} ${item.id === 0 ? 'd-md-none' : ''}`}
                      onClick={() => toggleMobileLink(item)}>

                      {item.url &&
                        (<Link
                          to={item.url}
                          title={item.text}
                          className='nav-link'>
                            <span>{item.text}</span>
                            <span className="mobile-icon icon-chevron-right"></span>
                        </Link>
                      )}

                      {item.nav?.length > 0 && (<div className='nav-link'>
                        <span tabIndex="0">{item.text}</span>
                          <Fragment>
                            {isOpenMobileLink ?
                            <span className="mobile-icon icon-chevron-down"></span>
                              :
                            <span className="mobile-icon icon-chevron-top"></span>}
                          </Fragment>
                          </div>
                      )}

                      {item.nav && item.nav.length > 0 && (
                        <div className={`subnav-box ${isOpenMobileLink ? 'd-block d-md-none' : 'd-none'}`}>
                          <ul className="subnav-links">
                            {item.nav.map(subItem => (
                              <li key={subItem.title} className="subnav-link">
                                <a href={subItem?.url}
                                  target='_blank'
                                  title={`${subItem.title}『另開新視窗』`}
                                  rel="noreferrer"
                                  >
                                  {subItem.title}
                                  <span className="icon-open_in"></span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </li>
                  )}
                  ) : null}
              </ul>

              <form className="header-search-box">
                <div>
                  <input type='text'
                    placeholder="全站搜尋"
                    className="header-search"
                    aria-label="輸入關鍵字"
                    value={searchKeyword}
                    onChange={e => setSearchKeyword(e.target.value)}
                    accessKey="S"
                    onKeyPress={e => {
                      if (e.key === 'Enter' && e?.target?.value) {
                        window.open(cxId?`https://cse.google.com/cse?cx=${validateString(cxId)}#gsc.tab=0&gsc.q=${encodeURI(validateString(e.target.value))}&gsc.sort=`:'', '_blank')
                      }
                    }}
                    ref={searchInput}/>
                    <a href={(cxId && searchKeyword)?`https://cse.google.com/cse?cx=${validateString(cxId)}#gsc.tab=0&gsc.q=${encodeURI(validateString(searchKeyword))}&gsc.sort=`:''} title="全站搜尋『另開新視窗』" className="icon-search" target='_blank' accessKey="S" >
                      <span className="d-none">全站搜尋</span>
                    </a>
                </div>
              </form>

            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;