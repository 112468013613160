import { Fragment } from "react";

// 純文字
const Block1 = (props) => {
  const { block } = props;

  return (
    <Fragment>
      <div dangerouslySetInnerHTML={{__html: block.subContent}}></div>
    </Fragment>
  );
}

export default Block1;