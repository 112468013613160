import React, { useEffect } from 'react';

function PageTitle({ title }) {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return null; // 这个组件不渲染任何内容
}

export default PageTitle;