import Card from 'react-bootstrap/Card';
import { Link, useLocation } from "react-router-dom";
import default1 from "../assets/components/overview/default1.jpg";
import default2 from "../assets/components/overview/default2.jpg";
import default3 from "../assets/components/overview/default3.jpg";

const setDefaultImg = () => {
  const img = [
    default1, default2, default3
  ]
  const random = Math.floor(Math.random() * img.length);
  return img[random];
}

const OverviewCard = (props) => {
  const { card, id } = props;
  const location = useLocation();
  const overviewUrl = (cardId) => {
    console.log('cardId:' + cardId);
    let _cardId = validateString(cardId);
    console.log('cardId:' + _cardId);
    if(location.pathname.includes('topicOverview') && cardId) {
      return`/topicArticle/${_cardId}`
    } else if (id && cardId) {
      return `/videoExam/${id}/${_cardId}`
    }
    return '';
  };

  const validateString = (str) => {
    let isNumber = !isNaN(parseInt(str));
    if(isNumber){
      return str;
    }
    let _str = "";
      for (let index = 0; index < str.length; index++) {
        let escape = "";
        switch (str.charCodeAt(index)) {
          case 34: // "
            escape = "&quot;";
            break;
          case 38: // &
            escape = "&amp;";
            break;
          case 39: // '
            escape = "&#x27;";
            break;
          case 60: // <
            escape = "&lt;";
            break;
          case 62: // >
            escape = "&gt;";
            break;
          default:
            escape = str[index];
          // continue;
        }
        _str += escape;
      }
    return _str;
  }

  return (
    <Link to={card.id?overviewUrl(card.id):''} title={card.title} className="d-block">
      <Card>
        <Card.Img variant="top" src={`${card.image ? card.image : setDefaultImg()}`} alt='' />

        <Card.Body>
          <div className="card-title h5">
            {card.title}
          </div>
        </Card.Body>
      </Card>
    </Link>
  )
}

export default OverviewCard;