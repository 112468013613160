import 'bootstrap/js/src/modal';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const VideoExamModal = (props) => {
  const {isPortraitModal, closePortraitModal} = props;

  return (
    <Modal
      show={isPortraitModal}
      onHide={closePortraitModal}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Body className='modal-box'>
        <div className='mt-3 mb-5 text-center'>為達最佳操作體驗，請使用橫屏進行測驗</div>

        <Button className="modal-btn w-100 py-2" onClick={closePortraitModal}>我已了解</Button>
      </Modal.Body>
    </Modal>
  );
}

export default VideoExamModal;