import {request} from './interceptors'

/**
 * 首頁資訊
 */
export const getHomeData = () =>
    request({
        url: '/rest/questionnaireWebPage/homeData',
        method: 'GET',
    })

/**
 * 主題總覽
 */
export const getVideoTestingPageConfigInfo = () =>
    request({
        url: '/rest/questionnaireWebPage/videoTestingPageConfigInfo',
        method: 'GET',
    })

/**
 * 相關連結
 */
export const getReferenceUrlInfos = () =>
    request({
        url: '/rest/questionnaireWebPage/referenceUrlInfos',
        method: 'GET',
    })

/**
 * 測試者設定資訊
 */
export const getTesterProfileData = () =>
    request({
        url: '/rest/questionnaire/testerProfileData',
        method: 'GET',
    })

/**
 * 請求測驗卷
 */
export const getQuestionnaireByType = (
    videoAttributeType,
    videoAttributeCode
) =>
    request({
        url: `/rest/questionnaire/requestQuestionnaireByType?videoAttributeType=${videoAttributeType}&videoAttributeCode=${videoAttributeCode}`,
        method: 'GET',
    })

/**
 * 請求測驗卷
 */
export const requestQuestionnaireByEmvtoken = (emvtoken) =>
    request({
        url: `/rest/questionnaire/requestQuestionnaireByEmv?emvtoken=${emvtoken}`,
        method: 'GET',
    })

/**
 * 再次請求測驗卷
 */
export const requestAgainQuestionnaireByEmvtoken = (emvtoken, testingUUID) =>
    request({
        url: `/rest/questionnaire/requestQuestionnaireByEmv?testingUUID=${testingUUID}&emvtoken=${emvtoken}`,
        method: 'GET',
    })

/**
 * 送出作答測驗卷
 */
export const submitQuestionnaire = (answer) =>
    request({
        url: `/rest/questionnaire/submitQuestionnaire`,
        method: 'POST',
        data: answer,
        timeout: 10000,
    })

/**
 * 檢視測驗影片
 */
export const getReviewTestingVideo = (testingVideoUUID) =>
    request({
        url: `/rest/questionnaire/reviewTestingVideo?testingVideoUUID=${testingVideoUUID}`,
        method: 'GET',
    })

/**
 * 取得影片測驗
 */
export const getTestCategoryQuestionnaireList = () =>
    request({
        url: `/rest/questionnaireWebPage/testCategoryQuestionnaireList`,
        method: 'GET',
    })

/**
 * 請求測驗卷
 */
export const requestTestCategoryQuestionnaire = (testCategory, idx) =>
    request({
        url: `/rest/questionnaire/requestTestCategoryQuestionnaire?testCategory=${testCategory}&idx=${idx}`,
        method: 'GET',
    })

/**
 * 請求測驗卷
 */
export const requestAgainTestCategoryQuestionnaire = (testCategory, idx, testingUUID) =>
request({
    url: `/rest/questionnaire/requestTestCategoryQuestionnaire?testCategory=${testCategory}&idx=${idx}&testingUUID=${testingUUID}`,
    method: 'GET',
})

/**
 * 測驗說明清單
 */
export const demoQuestionnaireList = () =>
    request({
        url: `/rest/questionnaireWebPage/demoQuestionnaireList`,
        method: 'GET',
    })

/**
 * 請求測驗說明測驗卷
 */
export const requestDemoQuestionnaire = (videoTestingMode) =>
    request({
        url: `/rest/questionnaire/requestDemoQuestionnaire?videoTestingMode=${videoTestingMode}`,
        method: 'GET',
    })

/**
 * 確認完成檢視影片
 */
export const confirmReviewTestingVideo = (
    reviewVideoInSecond,
    testingVideoUUID
) =>
    request({
        url: `/rest/questionnaire/confirmReviewTestingVideo`,
        method: 'POST',
        data: {reviewVideoInSecond, testingVideoUUID},
    })

/**
 * 檢視結果
 */
export const reviewTestingQuestionnaireResult = (testingUUID) =>
    request({
        url: `/rest/questionnaire/reviewTestingQuestionnaireResult?testingUUID=${testingUUID}`,
        method: 'GET',
    })

/**
 * 危險感知主題
 */
export const videoTopicDescription = (videoTopicCode) =>
    request({
        url: `/rest/questionnaireWebPage/videoTopicDescription?videoTopicCode=${videoTopicCode}`,
        method: 'GET',
    })

/**
 * 取得問卷調查表
 */
export const getSurvey = () =>
    request({
        url: `/rest/survey/requestSurvey`,
        method: 'GET',
    })

/**
 * 送出問卷調查表
 */
export const submitSurvey = (answer) =>
    request({
        url: `/rest/survey/submitSurvey`,
        method: 'POST',
        data: answer,
    })

/**
 * 請求主題測驗卷
 */
export const requestTopicPurposeQuestionnaire = (videoTopicCode, id) =>
    request({
        url: `/rest/questionnaire/requestTopicPurposeQuestionnaire?videoTopicCode=${videoTopicCode}&id=${id}`,
        method: 'GET',
    })

/**
 * 取得協助流程說明資訊
 */
export const getAssistanceFolwDescription = () =>
request({
    url: `/rest/questionnaireWebPage/assistanceFolwDescription`,
    method: 'GET',
})