import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";
import { Fragment } from "react";

const BreadcrumbList = (props) => {
  const { breadcrumb } = props;
  const activeTitle = breadcrumb[breadcrumb.length - 1].text;
  const filterPrevTitle = () => {
    const breadList = breadcrumb.slice();
    breadList.pop()
    return breadList;
  }
  const prevList = filterPrevTitle();

  return (
    <Breadcrumb>
      {prevList.map(item => (
        <Fragment key={item.text}>
          <li className="breadcrumb-item h5">
            <Link to={item.url} title={item.text}>{item.text}</Link>
          </li>
          <span className="icon-chevron-right"></span>
        </Fragment>
      ))}
      <Breadcrumb.Item className="h5" active>{activeTitle}</Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default BreadcrumbList;