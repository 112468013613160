export const textLimit = (ref, limit) => {
  const text = ref.textContent;
  if(text.length > limit) {
    ref.textContent = text.slice(0, limit);
  }
}

export const htmlTextLimit = (ref, limit) => {
  const text = ref.textContent;
  const htmlText = ref.innerHTML;
  if(text.length < limit) return;

  if(htmlText.includes('<br>')) {
    // 要扣掉 <br> 的字數
    const htmlTextLength = htmlText.slice(0, limit).replaceAll('<br>', '').length;

    const brLength = limit - htmlTextLength;

    ref.innerHTML = htmlText.slice(0, limit + brLength) + '...';
  } else {
    ref.innerHTML = htmlText.slice(0, limit) + '...';
  }
}

export const domTextLimit = (dom, limit) => {
  dom.forEach(item => {
    const text = item.textContent;
    if(text.length > limit) {
      item.textContent = text.slice(0, limit) + '...';
    }
  })
}

export const domHtmlTextLimit = (dom, limit) => {
  dom.forEach(item => {
    const text = item.textContent;
    const htmlText = item.innerHTML;

    if(text.length < limit) return;

    if(htmlText.includes('<br>')) {
      // 要扣掉 <br> 的字數
      const htmlTextLength = htmlText.slice(0, limit).replaceAll('<br>', '').length;

      const brLength = limit - htmlTextLength;

      item.innerHTML = htmlText.slice(0, limit + brLength) + '...';
    } else {
      item.innerHTML = htmlText.slice(0, limit) + '...';
    }
  })
}