import { useState, useEffect, useCallback, useRef, useMemo, useContext } from "react";
import ReactPlayer from 'react-player';
import { Link, NavLink, useParams, useSearchParams, useNavigate } from "react-router-dom";
import context from "../hooks/context";
import BreadcrumbBanner from "../components/BreadcrumbBanner";
import OnlyText from "../components/videoExam/OnlyText";
import TextAndImage from "../components/videoExam/TextAndImage";
import TitleImage from "../components/videoExam/TitleImage";

import behavior from "../assets/pages/examInstructionsId/behavior.jpg";
import behaviorMobile from "../assets/pages/examInstructionsId/behavior-mobile.jpg";
import behaviorTest from "../assets/pages/examInstructionsId/behavior-test.jpg";
import behaviorTestMobile from "../assets/pages/examInstructionsId/behavior-test-mobile.jpg";
import behaviorExplain from "../assets/pages/examInstructionsId/behavior-explain.jpg";
import behaviorExplainMobile from "../assets/pages/examInstructionsId/behavior-explain-mobile.jpg";
import result from "../assets/pages/examInstructionsId/result.jpg";
import resultMobile from "../assets/pages/examInstructionsId/result-mobile.jpg";
import question from "../assets/pages/examInstructionsId/question.jpg";
import questionMobile from "../assets/pages/examInstructionsId/question-mobile.jpg";
import questionTest from "../assets/pages/examInstructionsId/question-test.jpg";
import questionTestMobile from "../assets/pages/examInstructionsId/question-test-mobile.jpg";
import questionExplain from "../assets/pages/examInstructionsId/question-explain.jpg";
import questionExplainMobile from "../assets/pages/examInstructionsId/question-explain-mobile.jpg";
import mix from "../assets/pages/examInstructionsId/mix.jpg";
import mixMobile from "../assets/pages/examInstructionsId/mix-mobile.jpg";
import mixTest from "../assets/pages/examInstructionsId/mix-test.jpg";
import mixTestMobile from "../assets/pages/examInstructionsId/mix-test-mobile.jpg";
import mixExplain from "../assets/pages/examInstructionsId/mix-explain.jpg";
import mixExplainMobile from "../assets/pages/examInstructionsId/mix-explain-mobile.jpg";

import { demoQuestionnaireList, requestDemoQuestionnaire } from '../API/request';

import { htmlTextLimit } from "../helps/textLimit";
import PageTitle from "../components/PageTitle";

const ExamInstructionsId = () => {
  const navigate = useNavigate();
  const params = useParams();
  const paramsId = params.id;
  const [searchParams] = useSearchParams();
  const urlfrom = searchParams.get('urlfrom');
  const code = searchParams.get('code');
  const urltype = searchParams.get('urltype') || '';
  const isFocusVideo = searchParams.get('video') === 'true';
  const { setUserTestingUUID, userVideoAnswer, setUserVideoAnswer, setUserExplainWatched, setExamExplain, setIsFirstExamResult } = useContext(context);


  let breadActivePage = () => {
    switch (paramsId) {
      case 'BEHAVIOR':
        return '行為判斷'

      case 'QUESTIONNAIRE':
        return '風險問答'

      case 'COMBO':
        return '複合題型'

      default:
        return ''
    }
  }

  const breadcrumb = [
    { text: '首頁', url: '/' },
    { text: '測驗說明', url: '/examInstructions' },
    { text: breadActivePage(), url: '/' },
  ]

  const tab = [
    {
      title: '行為判斷',
      url: '/examInstructions/BEHAVIOR'
    },
    {
      title: '風險問答',
      url: '/examInstructions/QUESTIONNAIRE'
    },
    {
      title: '複合題型',
      url: '/examInstructions/COMBO'
    }
  ]

  const step = [
    {
      title: '測驗說明',
    },
    {
      title: '測驗中',
    },
    {
      title: '測驗結果',
    },
    {
      title: '影片解析',
    }
  ]

  const data = useMemo(() => {
    return {
      BEHAVIOR: {
        title: "行為判斷",
        desc: "R2DEV監理服務網綜合測驗（YN制），含一支行為判斷影片，一支風險問答影片，一支複合題型影片。有及格標準（需答對2題），且強制看解答，有及格標準（需答對2題），且強制看解答，有及格標準（需答對2題），且強制看解答，有及格標準（需答對2題），且強制看解答，有及格標準（需答對2題），且強制看解答",
        videoDesc: 'https://i.imgur.com/vtUbRV6.mp4',
        desktopImage: behavior,
        mobileImage: behaviorMobile,
        examStep: {
          step1: {
            title: "行為判斷-測驗說明",
            image: behavior,
            mobileImage: behaviorMobile,
            text: '測驗開始前，請詳細閱讀題型介紹與測驗說明',
            list: [
              { text: '目前測驗影片數/總影片數' },
              { text: '點擊此鍵可中斷測驗並進入測驗評分結果。（監理服務網預約考照測驗不提供此功能）' },
              { text: '點擊此鍵即開始測驗' },
              { text: '影片題型說明' },
              { text: '測驗影片說明，請詳細閱讀以瞭解測驗題型' },
              { text: '測驗開始前，此按鍵無法點擊' },
            ]
          },
          step2: {
            title: "行為判斷-測驗中",
            image: behaviorTest,
            mobileImage: behaviorTestMobile,
            text: '行為判斷測驗，當發現符合測驗說明描述的情境時，<br/>請點擊「發現危險」按鍵或「影片畫面區域」進行作答',
            list: [
              { text: '目前測驗影片數/總影片數' },
              { text: '點擊此鍵可中斷測驗並進入測驗評分結果。（監理服務網預約考照測驗不提供此功能）' },
              { text: '點擊「發現危險」按鍵或「影片畫面區域」皆可進行作答' },
              { text: '注意影片點擊限制，超過視為無效點擊' },
            ]
          },
          step3: {
            title: "行為判斷-測驗結果",
            image: result,
            mobileImage: resultMobile,
            text: '測驗完畢，顯示測驗結果<br />如需預約考照，請詳細閱讀「預約考照」鍵下方的說明',
            list: [
              { text: '答對、答錯與未作答的題數' },
              { text: '測驗結果與評語' },
              { text: '測驗影片說明' },
              { text: '播放解析影片' },
              { text: '解析影片觀看狀態' },
              { text: '測驗影片的宣導主題說明' },
              { text: '影片截圖' },
              { text: '預約考照' },
              { text: '預約考照注意事項' },
              { text: '測驗影片答題結果' },
              { text: '填寫問卷' },
            ]
          },
          step4: {
            title: "行為判斷-影片解析",
            image: behaviorExplain,
            mobileImage: behaviorExplainMobile,
            text: '觀看解析影片以了解影片宣導主題<br />觀看完畢返回測驗結果',
            list: [
              { text: '點擊返回測驗結果頁' },
              { text: '介面標示說明' },
              { text: '正確點擊區間標示，在此區間內的有效點擊視為答對' },
              { text: '點擊點位置標示' },
              { text: '影片解析說明' },
            ]
          }
        },
        examQuestions: [
          {
            questionId: 1,
            type: "BEHAVIOR",
            videoUrl : "https://i.imgur.com/vtUbRV6.mp4"
          },
          {
            questionId: 2,
            type: "BEHAVIOR",
            videoUrl: "https://s3.hicloud.net.tw/hpt.video/motohpp/6c/32/7c/b3/-d/ac/6-4e56-827b-4d4730c8818f.mp4"
          },
          {
            questionId: 3,
            type: "BEHAVIOR",
            videoUrl : "https://s3.hicloud.net.tw/hpt.video/motohpp/da/f9/79/5b/-9/5a/f-482f-9174-8ec0faabafa5.mp4"
          }
        ]
      },
      QUESTIONNAIRE: {
        title: "風險問答",
        desc: "路段中行進，您需要減速時，<br />請於影片上任意處點擊，或是點擊右下方「發現危險」按鍵",
        videoDesc: 'https://s3.hicloud.net.tw/hpt.video/motohpp/6c/32/7c/b3/-d/ac/6-4e56-827b-4d4730c8818f.mp4',
        desktopImage: question,
        mobileImage: questionMobile,
        examStep: {
          step1: {
            title: "風險問答-測驗說明",
            image: question,
            mobileImage: questionMobile,
            text: '測驗開始前，請詳細閱讀題型介紹與測驗說明',
            list: [
              { text: '目前測驗影片數/總影片數' },
              { text: '點擊此鍵可中斷測驗並進入測驗評分結果。（監理服務網預約考照測驗不提供此功能）' },
              { text: '點擊此鍵即開始測驗' },
              { text: '影片題型說明' },
              { text: '測驗影片說明，請詳細閱讀以瞭解測驗題型' },
            ]
          },
          step2: {
            title: "風險問答-測驗中",
            image: questionTest,
            mobileImage: questionTestMobile,
            text: '影片播放至特定時間點時，將開啟問答視窗進行作答',
            list: [
              { text: '目前測驗影片數/總影片數' },
              { text: '點擊此鍵可中斷測驗並進入測驗評分結果。（監理服務網預約考照測驗不提供此功能）' },
              { text: '問答題題目' },
              { text: '點擊正確的選項，複選題可多選' },
              { text: '按此鍵完成作答，進入下一部影片' },
            ]
          },
          step3: {
            title: "風險問答-測驗結果",
            image: result,
            mobileImage: resultMobile,
            text: '測驗完畢，顯⽰測驗結果<br />如需預約考照，請詳細閱讀「預約考照」鍵下方的說明',
            list: [
              { text: '答對、答錯與未作答的題數' },
              { text: '測驗結果與評語' },
              { text: '測驗影片說明' },
              { text: '播放解析影片' },
              { text: '解析影片觀看狀態' },
              { text: '測驗影片的宣導主題說明' },
              { text: '影片截圖' },
              { text: '預約考照' },
              { text: '預約考照注意事項' },
              { text: '測驗影片答題結果' },
              { text: '填寫問卷' },
            ]
          },
          step4: {
            title: "風險問答-影片解析",
            image: questionExplain,
            mobileImage: questionExplainMobile,
            text: '觀看解析影片以了解影片宣導主題<br />觀看完畢返回測驗結果',
            list: [
              { text: '點擊返回測驗結果頁' },
              { text: '顯示正確答案' },
              { text: '顯示受測者選擇的答案' },
              { text: '影片解析說明' },
            ]
          }
        },
        examQuestions: [
          {
            questionId: 1,
            type: "QUESTIONNAIRE",
            videoUrl: "https://s3.hicloud.net.tw/hpt.video/motohpp/da/f9/79/5b/-9/5a/f-482f-9174-8ec0faabafa5.mp4",
            events: [
              {
                eventId: 1,
                showTime: "00:05",
                type: "OnlyText",
                questionText: "請問您這時應該要?",
                single: true,
                questionImage: "https://picsum.photos/500/300?random=2",
                questionOptions: [
                  {
                    optionImage: "https://picsum.photos/500/300?random=1",
                    no: "A",
                    text: "加速前進"
                  },
                  {
                    optionImage: "https://picsum.photos/500/300?random=2",
                    no: "B",
                    text: "減速並注意前方路況"
                  },
                  {
                    "optionImage": "https://picsum.photos/500/300?random=3",
                    no: "C",
                    text: "馬上停車"
                  },
                  {
                    "optionImage": "https://picsum.photos/500/300?random=4",
                    no: "D",
                    text: "迴轉"
                  }
                ]
              }
            ]
          },
          {
            questionId: 2,
            type: "QUESTIONNAIRE",
            videoUrl: "https://s3.hicloud.net.tw/hpt.video/motohpp/6c/32/7c/b3/-d/ac/6-4e56-827b-4d4730c8818f.mp4",
            events: [
              {
                eventId: 1,
                showTime: "00:05",
                type: "OnlyText",
                questionText: "請問您這時應該要?",
                single: true,
                questionImage: "https://picsum.photos/500/300?random=2",
                questionOptions: [
                  {
                    optionImage: "https://picsum.photos/500/300?random=1",
                    no: "A",
                    text: "加速前進"
                  },
                  {
                    optionImage: "https://picsum.photos/500/300?random=2",
                    no: "B",
                    text: "減速並注意前方路況"
                  },
                  {
                    "optionImage": "https://picsum.photos/500/300?random=3",
                    no: "C",
                    text: "馬上停車"
                  },
                  {
                    "optionImage": "https://picsum.photos/500/300?random=4",
                    no: "D",
                    text: "迴轉"
                  }
                ]
              }
            ]
          }
        ]
      },
      COMBO: {
        title: "複合題型",
        desc: "路段中行進，您需要減速時，<br />請於影片上任意處點擊，或是點擊右下方「發現危險」按鍵",
        videoDesc: 'https://s3.hicloud.net.tw/hpt.video/motohpp/da/f9/79/5b/-9/5a/f-482f-9174-8ec0faabafa5.mp4',
        desktopImage: mix,
        mobileImage: mixMobile,
        examStep: {
          step1: {
            title: "複合題型-測驗說明",
            image: mix,
            mobileImage: mixMobile,
            text: '測驗開始前，請詳細閱讀題型介紹與測驗說明',
            list: [
              { text: '目前測驗影片數/總影片數' },
              { text: '點擊此鍵可中斷測驗並進入測驗評分結果。（監理服務網預約考照測驗不提供此功能）' },
              { text: '點擊此鍵即開始測驗' },
              { text: '影片題型說明' },
              { text: '測驗影片說明，請詳細閱讀以瞭解測驗題型' },
              { text: '測驗開始前，此按鍵無法點擊' },
            ]
          },
          step2: {
            title: "複合題型-測驗中",
            image: mixTest,
            mobileImage: mixTestMobile,
            text: '發現符合測驗說明的情境時，點擊「發現危險」按鍵或「影片畫面區域」，接著螢幕彈出問答視窗進行作答',
            list: [
              { text: '目前測驗影片數/總影片數' },
              { text: '點擊此鍵可中斷測驗並進入測驗評分結果。（監理服務網預約考照測驗不提供此功能）' },
              { text: '點擊「發現危險」按鍵或「影片畫面區域」皆可進行作答' },
              { text: '注意影片點擊限制，超過視為無效點擊' },
              { text: '在正確區間點擊才會開啟問答視窗，點擊正確的選項，複選題可多選' },
              { text: '按此鍵完成作答，進入下一部影片' },
            ]
          },
          step3: {
            title: "複合題型-測驗結果",
            image: result,
            mobileImage: resultMobile,
            text: '測驗完畢，顯示測驗結果<br />如需預約考照，請詳細閱讀「預約考照」鍵下方的說明',
            list: [
              { text: '答對、答錯與未作答的題數' },
              { text: '測驗結果與評語' },
              { text: '測驗影片說明' },
              { text: '播放解析影片' },
              { text: '解析影片觀看狀態' },
              { text: '測驗影片的宣導主題說明' },
              { text: '影片截圖' },
              { text: '預約考照' },
              { text: '預約考照注意事項' },
              { text: '測驗影片答題結果' },
              { text: '填寫問卷' },
            ]
          },
          step4: {
            title: "複合題型-影片解析",
            image: mixExplain,
            mobileImage: mixExplainMobile,
            text: '觀看解析影片以了解影片宣導主題<br />觀看完畢返回測驗結果',
            list: [
              { text: '點擊返回測驗結果頁' },
              { text: '介面標示說明' },
              { text: '正確點擊區間標示，在此區間內的有效點擊視為答對' },
              { text: '點擊點位置標示' },
              { text: '顯示正確答案' },
              { text: '顯示受測者選擇的答案' },
              { text: '影片解析說明' },
            ]
          }
        },
        examQuestions: [
          {
            questionId: 1,
            type: "COMBO",
            videoUrl: "https://s3.hicloud.net.tw/hpt.video/motohpp/6c/32/7c/b3/-d/ac/6-4e56-827b-4d4730c8818f.mp4",
            events: [
              {
                eventId: 1,
                showTimeRange: {
                  start: "00:10",
                  end: "00:30"
                },
                type: "TextAndImage",
                questionText: "請問您這時應該要?",
                single: false,
                questionImage: "https://picsum.photos/500/300?random=3",
                questionOptions: [
                  {
                    optionImage: "https://picsum.photos/500/300?random=1",
                    no: "A",
                    text: "加速前進"
                  },
                  {
                    optionImage: "https://picsum.photos/500/300?random=2",
                    no: "B",
                    text: "減速並注意前方路況"
                  },
                  {
                    optionImage: "https://picsum.photos/500/300?random=3",
                    no: "C",
                    text: "馬上停車"
                  },
                  {
                    optionImage: "https://picsum.photos/500/300?random=4",
                    no: "D",
                    text: "迴轉"
                  }
                ]
              }
            ]
          },
          {
            questionId: 2,
            type: "COMBO",
            videoUrl : "https://s3.hicloud.net.tw/hpt.video/motohpp/da/f9/79/5b/-9/5a/f-482f-9174-8ec0faabafa5.mp4",
            events: [
              {
                eventId: 1,
                showTimeRange: {
                  start: "00:10",
                  end: "00:30"
                },
                type: "TitleImage",
                questionText: "請問您這時應該要?",
                single: false,
                questionImage: "https://picsum.photos/500/300?random=3",
                questionOptions: [
                  {
                    optionImage: "https://picsum.photos/500/300?random=1",
                    no: "A",
                    text: "加速前進"
                  },
                  {
                    optionImage: "https://picsum.photos/500/300?random=2",
                    no: "B",
                    text: "減速並注意前方路況"
                  },
                  {
                    optionImage: "https://picsum.photos/500/300?random=3",
                    no: "C",
                    text: "馬上停車"
                  },
                  {
                    optionImage: "https://picsum.photos/500/300?random=4",
                    no: "D",
                    text: "迴轉"
                  }
                ]
              }
            ]
          }
        ]
      },
    }
  }, [])

  const getUserAgent = () =>
     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const [isMobileUserAgent, setUserAgent] = useState(false);
  const [currentStepIdx, setCurrentStepIdx] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [demoList, setDemoList] = useState({});
  const [isShowReturnBtn, setIsShowReturnBtn] = useState(false);
  const [examList, setExamList] = useState({});
  const [dateTimeDesc, setDateTimeDesc] = useState('');

  const [explainData, setExplainData] = useState({
    isPlaying: false,
    isShowStart: true,
    played: 0
  });

  const [testData, setTestData] = useState({
    isPlaying: false,
    isShowStart: true,
    examIndex: 0,
    played: 0,
    isQuestionType: false,
    showQuestion: false,
    userClickTimes: 0,
    userClickSeconds: 0,
    isDone: false,
  })

  const [answerTemp, setAnswerTemp] = useState([]); // 記錄 user 答案

  const currentQuestionIndex = 0;
  const explainPlayer = useRef();
  const player = useRef();
  const isChangeTab = useRef(false);
  let isVideoEnd = useRef(false);
  const videoDesc = useRef();
  const pauseBtn = useRef();
  const playBtn = useRef();

  const changeTab = (url) => {
    // 如影片進行中切換tab直接重載頁面，影片才不會錯亂
    if(explainData.isPlaying || testData.isPlaying) {
      let appendURL = '';
      if (urlfrom && code) {
        appendURL = `?urlfrom=${urlfrom}&code=${code}&urltype=${urltype}`;
      }
      window.location.load(`${url}${appendURL}`);
    } else {
      isChangeTab.current = true;
      setCurrentStepIdx(0);
      handleExplainEnded();
      handleEnded();
      setTestData(state => ({
        ...state,
        isPlaying: false,
        isShowStart: true
      }))
    }
  }

  const changeUrl = (url) => {
    if (urlfrom && code) {
      return `${url}?urlfrom=${urlfrom}&code=${code}&urltype=${urltype}`;
    }
    return url;
  }

  const fetchDemoList = useCallback(async() => {
    const result = await demoQuestionnaireList();
    if (result?.rc === '0000' && result?.data) {
      const { data } = result;
      setDemoList({
        BEHAVIOR: data[0],
        QUESTIONNAIRE: data[1],
        COMBO: data[2]
      });
    }
  }, []);

  const explainPlayVideo = () => {
    setExplainData(state => ({
      ...state,
      isPlaying: true,
      isShowStart: false
    }));

    if(pauseBtn) {
      const timer = setTimeout(() => {
        pauseBtn.current.focus();
        clearTimeout(timer)
      }, 100);
    }

  }

  const handleExplainPlaying = () => {
    setExplainData(state => ({
      ...state,
      isPlaying: !state.isPlaying,
    }));
  }

  const handleExplainEnded = () => {
    setExplainData(state => ({
      ...state,
      isPlaying: false,
      isShowStart: true,
    }));
  }

  const handleExplainProgress = (progress) => {
    setExplainData(state => ({
      ...state,
      played: progress.played
    }));
  }

  const playVideo = () => {
    setTestData(state => ({
      ...state,
      isPlaying: true,
      isShowStart: false,
    }))
  }

  const transferSecond = (time) => {
    const minute = Number(time.split(':')[0]);
    const second = Number(time.split(':')[1]);
    return minute * 60 + second;
  }

  const recodeClickTime = () => {
    if(testData.isQuestionType || !testData.isPlaying) return;
    const clickSeconds = player.current.getCurrentTime().toFixed(2);

    setTestData(state => ({
      ...state,
      userClickSeconds: clickSeconds,
    }))

    setAnswerTemp(arr => [...arr, clickSeconds]);

    if(testData.isPlaying && testData.userClickTimes <= 2) {
      const circles = document.querySelectorAll('.circle');
      circles[testData.userClickTimes].classList.add('active');
    }

    setTestData(state => ({
      ...state,
      userClickTimes: state.userClickTimes + 1
    }))
  }

  const handleEnded = () => {
    isVideoEnd.current = true;
    const { examQuestions } = examList;
    const { examIndex } = testData;
    const { questionId, type } = examQuestions[examIndex];
    const result = { testingVideoUUID: questionId, clickTimeList: [], selectAnswerOptionIds: [] };

    answerTemp.forEach((answer) => {
      if (typeof(answer) == 'string') {
        result.clickTimeList[result.clickTimeList.length] = parseFloat(answer);
      } else {
        result.selectAnswerOptionIds[result.selectAnswerOptionIds.length] = answer;
      }
    });
    setUserVideoAnswer(arr => [...arr, result]);
    setAnswerTemp([]);

    handleIsQuestion();

    setTestData(state => ({
      ...state,
      isPlaying: false,
      isShowStart: true,
      showQuestion: false,
      userClickTimes: 0,
      userClickSeconds: 0,
      isDone: false
    }))

    const circles = document.querySelectorAll('.circle');
    circles.forEach(item => {
      item.classList.remove('active');
    })

    if(examIndex + 1 === examList.examQuestions.length) {
      let appendURL = '';
      if (urlfrom && code) {
        appendURL = `&urlfrom=${urlfrom}&code=${code}&urltype=${urltype}`;
      }
      navigate(`/examResult/${examList.id}?type=${type}${appendURL}`, { replace: true });
    } else if(isChangeTab.current) {
      setTestData(state => ({
        ...state,
        examIndex: 0,
        isShowStart: true,
        isPlaying: false,
        played: 0
      }))
    } else {
      setTestData(state => ({
        ...state,
        examIndex: state.examIndex + 1,
      }))
    }

    isChangeTab.current = false;
  }

  const handleIsQuestion = useCallback(() => {
    const examQuestions = examList.examQuestions;
    if(examQuestions) {
      setTestData(state => ({
        ...state,
        isQuestionType: examQuestions[testData.examIndex].type === 'QUESTIONNAIRE'
      }))
    }
  }, [testData.examIndex, examList])

  const handleProgress = (progress) => {
    const durationSeconds = player.current.getDuration();
    const currentSeconds = player.current.getCurrentTime() ? player.current.getCurrentTime().toFixed(2) : 0;
    const played = Math.round((currentSeconds / durationSeconds) * 100) / 100;
    setTestData(state => ({
      ...state,
      played
    }))
    if(testData.isDone) return;

    if(progress.played <= 0) return;

    const examQuestions = examList.examQuestions[testData.examIndex];

    if(examQuestions.type !== 'QUESTIONNAIRE' && examQuestions.type !== 'COMBO') return;

    if(currentQuestionIndex < examQuestions.events.length) {
      switch (examQuestions.type) {
        case 'QUESTIONNAIRE':
          const showTime = examQuestions.events[currentQuestionIndex].showTime;

          const quesTime = transferSecond(showTime);

          if(currentSeconds >= quesTime) {
            setTestData(state => ({
              ...state,
              isPlaying: false,
              showQuestion: true,
              isDone: true
            }))
          }
          break;

        case 'COMBO':
          // COMBO 判斷是否在時間內點擊，且 user 點擊次數不超過3次，
          const startTime = examQuestions.events[currentQuestionIndex].showTimeRange.start;
          const endTime = examQuestions.events[currentQuestionIndex].showTimeRange.end;
          const startSecond = transferSecond(startTime);
          const endSecond = transferSecond(endTime);

          if (testData.userClickSeconds >= startSecond && testData.userClickSeconds < endSecond && testData.userClickTimes <= 2) {
            setTestData(state => ({
              ...state,
              isPlaying: false,
              showQuestion: true,
              isDone: true
            }))
          }
          break;

        default:
          break;
      }
    }
  }

  useEffect(() => {
    const imageList = [behavior, behaviorMobile, behaviorTest, behaviorTestMobile, behaviorExplain, behaviorExplainMobile, result, resultMobile, question, questionMobile, questionTest, questionTestMobile, questionExplain, questionExplainMobile, mix, mixMobile, mixTest, mixTestMobile, mixExplain, mixExplainMobile]
    imageList.forEach((image) => {
      new Image().src = image
    });
  }, [])

  const fetchData = useCallback(async() => {
    const questionnaireInfo = await requestDemoQuestionnaire(paramsId);
    const exams = [];
    let title = '';
    let desc = '';
    let id = '';
    if (questionnaireInfo && questionnaireInfo.rc === '0000' && questionnaireInfo.data) {
      const { data } = questionnaireInfo;
      const {testingUUID, name, testingVideos, lastModifiedDateTimeDesc} = data;
      title = name;
      desc = data.description;
      id = testingUUID;
      testingVideos.forEach((item, index) => {
        const {testingVideoUUID, name,videoTestingModeName, description, videoUrl, clickLimit, videoTestingMode, questionAndAnswerInfo} = item;
          let events = null;
          if (questionAndAnswerInfo?.triggerType) {
            const {triggerType, triggerTime, timeRangeFrom, timeRangeTo, question, questionImageUrl, isMultipleCorrectAnswer, answerOptionList, qaType } = questionAndAnswerInfo;
            let showTime = null;
            let showTimeRange = null;
            let questionOptions = [];
            let type = 'OnlyText';
            if (triggerType === 'AUTO') {
              const min = parseInt(triggerTime / 60, 10);
              const sec = triggerTime % 60;
              let secTxt = (sec > 9) ? `${sec}` : `0${sec}`;
              showTime = `0${min}:${secTxt}`;
            } else if (triggerType === 'CLICK') {
              const startMin = parseInt(timeRangeFrom / 60, 10);
              const startSec = timeRangeFrom % 60;
              let startSecTxt = (startSec > 9) ? `${startSec}` : `0${startSec}`;
              const endMin = parseInt(timeRangeTo / 60, 10);
              const endSec = timeRangeTo % 60;
              let endSecTxt = (endSec > 9) ? `${endSec}` : `0${endSec}`;
              showTimeRange = {
                start: `0${startMin}:${startSecTxt}`, 
                end: `0${endMin}:${endSecTxt}`, 
              };
            }
            if (answerOptionList?.length > 0) {
              questionOptions = answerOptionList.map(({optionId,option,optionImageUrl}) => ({ no: optionId,text: option,optionImage: optionImageUrl}));
              if (qaType === 'Q_IMG') {
                type = 'TitleImage';
              } else if (qaType === 'A_IMG') {
                type = 'TextAndImage';
              }
            }
            events = [{
              questionText: question,
              triggerType,
              showTime,
              showTimeRange,
              single: !isMultipleCorrectAnswer,
              type,
              questionImage: questionImageUrl,
              questionOptions
            }];
          }
          exams[index] = {
            questionId: testingVideoUUID,
            questionImage: item.questionImageUrl,
            type: videoTestingMode,
            title: name,
            videoTestingModeName,
            description,
            videoUrl,
            clickLimit,
            events
          };
      });  
      setDateTimeDesc(lastModifiedDateTimeDesc);
    }
    setUserTestingUUID(id);
    setExamList({
      id,
      title, 
      desc,
      examQuestions: exams
    });
    setDataList(data[paramsId]);
    if (isFocusVideo && playBtn) {
      const timer = setTimeout(() => {
        playBtn.current.focus();
        clearTimeout(timer)
      }, 100);
    }
  }, [paramsId, data])

  const getReturnUrl = () => {
    if (urltype === 'topic') {
      return `/videoExam/topic/${code}/${urlfrom}`
    } else if (urltype === 'emvtoken') {
      return `/examOverview?emvtoken=emvtoken`
    }
    return `/videoExam/${code}/${urlfrom}`;
  }

  useEffect(() => {
    setUserVideoAnswer([])
  }, [setUserVideoAnswer])

  useEffect(() => {
    setExamExplain(null);
    fetchData();
    setUserExplainWatched([]);
  }, [fetchData, setUserExplainWatched])

  useEffect(() => {
    fetchDemoList();
  }, [fetchDemoList]);

  useEffect(() => {
    handleIsQuestion();
  }, [examList, handleIsQuestion])

  useEffect(() => {
    setUserAgent(getUserAgent());
    urlfrom ? setIsShowReturnBtn(true) : setIsShowReturnBtn(false);
    if(urltype === 'emvtoken'){
      setIsShowReturnBtn(true)
    }
  }, [urlfrom])

  useEffect(() => {
    const timer = setInterval(() => {
      if(currentStepIdx >= 3) {
        setCurrentStepIdx(0);
      } else {
        setCurrentStepIdx(currentStepIdx + 1);
      }
    }, 30000);

    return () => {
      clearInterval(timer);
    }
  }, [currentStepIdx])

  useEffect(() => {
    isVideoEnd.current = testData.played >= 0 ? false : true;
  }, [testData.played])

  useEffect(() => {
    htmlTextLimit(videoDesc.current, 100);
  }, [dataList.desc])

  useEffect(() => {
    setUserVideoAnswer([])
  }, [setUserVideoAnswer])

  useEffect(() => {
    console.log('userVideoAnswer', userVideoAnswer);
  }, [userVideoAnswer])

  useEffect(() => {
    setIsFirstExamResult(true);
  }, [setIsFirstExamResult])

  return (
    <main className="exam-instructions-id">
      <PageTitle title="機車危險感知教育平台 - 題型說明" />
      <BreadcrumbBanner breadcrumb={breadcrumb} hasSidebar={false} customTitle="題型說明"/>
      <section className="step-bg">
        <div className="step-container">
          <div className="step-desrc">
            本平台提供三種影片題型：行為判斷、風險問答與複合題型。<br/>
            每個題型皆分為四個步驟進行說明，請仔細閱讀三種題型的測驗與介面操作方式。<br/>
            點擊以下任一頁籤，將會進入該題型的第一個步驟。
          </div>

          <div className="d-flex justify-content-center">
            <div className="tab-box">
              {tab.map(item =>
                <NavLink to={changeUrl(item.url)}
                className="tab"
                tabIndex={`${item.url.includes(paramsId) ? -1 : 0}`}
                key={item.url}
                onClick={() => changeTab(item.url)}>{item.title}</NavLink>)}
            </div>
          </div>

          <div className="step-box">
            {step.map((item, index) =>
            <div className={`step-item ${currentStepIdx === index ? 'active' : ''}`}
            key={item.title}>

              <button type="button"
                className={`step-circle ${currentStepIdx === index ? 'ios-focus' : ''}`}
                tabIndex={`${currentStepIdx === index ? '' : 0}`}
                onClick={() => setCurrentStepIdx(index)}
                >
                <div className="step-num">{index + 1}</div>
              </button>

              <div className="step-text">{item.title}</div>
            </div>)}
          </div>

          {dataList.examStep && <div className="text-center">
            <div className="hint" dangerouslySetInnerHTML={{__html: dataList.examStep[`step${currentStepIdx + 1}`].text}}>
            </div>
          </div>}

        </div>
      </section>

      <section className="legend-bg">
        <div className="legend-container">
          <div className="legend-box">

            <div className="legend-image">
              {dataList.examStep &&
              <img src={isMobileUserAgent ? dataList.examStep[`step${currentStepIdx + 1}`].mobileImage : dataList.examStep[`step${currentStepIdx + 1}`].image} className='img-fluid' alt={dataList.examStep[`step${currentStepIdx + 1}`].title} />}
            </div>

            <ul className="legend-items">
              {dataList.examStep && dataList.examStep[`step${currentStepIdx + 1}`].list.map((item, idx) =>
              <li className="legend-item" key={item.text}>
                <span className="circle-num">{idx + 1}</span>
                <span>{item.text}</span>
              </li>)}
            </ul>

          </div>
        </div>

        {isShowReturnBtn && <Link to={getReturnUrl()} className="btn-return">
          <span>返回測驗</span>
          <span className="icon-return ms-2"></span>
        </Link>}
      </section>

      {demoList && demoList[paramsId] && demoList[paramsId].videoUrl && <section className="video-legend-bg">
        <div className="video-legend-container">

          <div className="video-legend-box">
            <h2 className="video-legend-title">影片說明區</h2>

            <div className="video-exam explain">
              <div className="video-box">
                <div className="player-wrapper">
                  <ReactPlayer
                    url={demoList[paramsId] && demoList[paramsId].videoUrl}
                    width='100%'
                    height='100%'
                    className="react-player"
                    ref={explainPlayer}
                    onEnded={handleExplainEnded}
                    onProgress={handleExplainProgress}
                    playing={explainData.isPlaying}
                    playsinline={true}
                  />
                </div>

                {explainData.isShowStart && <div className='play-box'>
                  <button
                    type='button'
                    className='btn-play'
                    onClick={explainPlayVideo}
                    aria-label="影片說明播放">
                    <span className='icon-play'></span>
                  </button>
                </div>}

                {explainData.isShowStart &&
                <div className={`video-question ${explainData.isShowStart ? 'dark' : ''}`}>
                </div>}

                {!explainData.isShowStart && <progress max={1} value={explainData.played} className="progress-bar transition"/>}

                {!explainData.isShowStart &&
                <button type="button" className='pause'
                  onClick={handleExplainPlaying}
                  ref={pauseBtn}>
                  {explainData.isPlaying && <span className="icon-pause"></span>}
                  {!explainData.isPlaying && <span className="icon-triangle play"></span>}
                </button>}

              </div>
            </div>
          </div>
        </div>
      </section>}

      <section className="video-legend-bg">
        <div className="test-container">
          <div className="video-interface bg-white rounded p-3 mb-3">
            測驗介面說明：<br/>
            以下的影片播放介面因屬測驗性質，故在影片開始播放後，介面上不提供暫停鍵及其他影片操控功能。
          </div>
          <div>
            <h2 className="video-legend-title">模擬測驗區</h2>
            <div className="video-exam">
              <div className="video-box">
                <div className="player-wrapper" onClick={recodeClickTime}>
                  <ReactPlayer
                    url={examList.examQuestions && examList.examQuestions[testData.examIndex] && examList.examQuestions[testData.examIndex].videoUrl}
                    width='100%'
                    height='100%'
                    className="react-player"
                    playing={testData.isPlaying}
                    playsinline={true}
                    ref={player}
                    onEnded={handleEnded}
                    onProgress={handleProgress}
                    progressInterval={100}
                  />
                </div>


                <span className="video-number me-2">
                  {testData.examIndex + 1}/{examList.examQuestions?.length}
                </span>

                {testData.isShowStart &&
                <div className={`video-question h6 ${testData.isShowStart ? 'dark' : ''}`}>
                  <div className="start-box">
                    <button
                      type="button"
                      className="btn-large start h3"
                      onClick={playVideo}
                      ref={playBtn}
                      aria-label="開始測驗">開始測驗</button>
                    <div>
                      <div className="mb-2">
                        本題為
                        <span className="video-name">{examList.title}</span>
                        影片，請仔細閱讀以下的測驗說明
                      </div>

                      <div className="video-desc" ref={videoDesc} dangerouslySetInnerHTML={{__html: (examList.examQuestions?.length > 0 && examList.examQuestions[testData.examIndex]?.description) || ''}}></div>

                    </div>
                  </div>
                </div>}

                <progress max={1} value={testData.played} className={`progress-bar ${isVideoEnd.current ? '' : 'transition'}`}/>

                {testData.showQuestion &&
                <div className={`video-question h6 ${testData.showQuestion ? 'dark' : ''}`}>

                  {examList.examQuestions[testData.examIndex]?.events[currentQuestionIndex].type === 'OnlyText' &&
                  <OnlyText
                    question={examList.examQuestions[testData.examIndex]?.events[currentQuestionIndex]}
                    doneForQuestion={handleEnded}
                    answerTemp={answerTemp}
                    setAnswerTemp={setAnswerTemp} />}

                  {examList.examQuestions[testData.examIndex]?.events[currentQuestionIndex].type === 'TextAndImage' &&
                  <TextAndImage
                    question={examList.examQuestions[testData.examIndex]?.events[currentQuestionIndex]}
                    doneForQuestion={handleEnded}
                    answerTemp={answerTemp}
                    setAnswerTemp={setAnswerTemp} />}

                  {examList.examQuestions[testData.examIndex]?.events[currentQuestionIndex].type === 'TitleImage' &&
                  <TitleImage
                    question={examList.examQuestions[testData.examIndex]?.events[currentQuestionIndex]}
                    doneForQuestion={handleEnded}
                    answerTemp={answerTemp}
                    setAnswerTemp={setAnswerTemp} />}
                </div>}

              </div>

              {!testData.isQuestionType &&
              <div className="desktop-times">
                {!testData.isShowStart && <div className="times h4">
                  <span className="me-2">點擊次數</span>
                  {examList.examQuestions?.length > 0 && 
              new Array(examList.examQuestions[testData.examIndex]?.clickLimit).fill(0).map((item, index) => <span key={`circle_${index}`} className="circle"></span>)}
                </div>}

                <button
                  className='btn-large danger h3'
                  disabled={!testData.isPlaying}
                  onClick={recodeClickTime}>
                  <span className="icon-hand"></span>
                  <span className="ms-1">發現危險</span>
                </button>
              </div>}

            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ExamInstructionsId;