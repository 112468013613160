import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getTesterProfileData } from '../../API/request';
import hasSelect from "../../assets/pages/videoExam/agree-noSelect.svg";
import noSelect from "../../assets/pages/videoExam/noAgree-noSelect.svg";

const ExamForm = (props) => {
  const { submitForm } = props;
  const getUserAgent = () =>
     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && (window.orientation !== 180 && window.orientation !== 0);

  const params = useParams();
  const paramsTopicId = params.topicId;
  const paramsTopicCode = params.topicCode;
  const paramsId = params.id;
  const paramsCode = params.code;
  const paramsToken = params.token;
  const [isMobileUserAgent, setUserAgent] = useState(false);

  const defaultFormInfo = {
    age: [],
    gender: [],
    country: [],
    license: []
  }

  const [{agree, age, gender, country, driverLicense}, setFormState] = useState({
    agree: false,
    age: '',
    gender: '',
    country: '',
    driverLicense: ''
  });

  const [ formInfo, setFormInfo ] = useState(defaultFormInfo);

  const toggleAgree = () => {
    setFormState((state) => ({
        ...state,
        agree: !state.agree,
    }))
  }

  const changeGender = (e) => {
    setFormState((state) => ({
      ...state,
      gender: e.target.value,
    }))
  }

  const changeAge = (e) => {
    setFormState((state) => ({
      ...state,
      age: e.target.value
    }))
  }

  const changeCountry = (e) => {
    setFormState((state) => ({
      ...state,
      country: e.target.value
    }))
  }

  const changeDriverLicense = (e) => {
    setFormState((state) => ({
      ...state,
      driverLicense: e.target.value
    }))
  }

  const getFormInfo  = async () => {
    const result = await getTesterProfileData();
    if (result && result.rc === '0000') {
      const { residenceList, ageGroupList, genderList, driverLicenseList, videoAttributeTypeList } = result.data;
      formInfo.country = residenceList;
      formInfo.age = ageGroupList;
      formInfo.gender = genderList;
      formInfo.license = driverLicenseList;
      formInfo.videoAttributeTypeList = videoAttributeTypeList;
      setFormInfo(formInfo);
    }
  }

  const getParams = () => {
    if (paramsTopicId && paramsTopicCode) {
      return  `/examInstructions?urlfrom=${validateString(paramsTopicId)}&code=${validateString(paramsTopicCode)}&urltype=topic`;
    } else if (paramsId !== undefined && paramsCode !== undefined) {
      return `/examInstructions?urlfrom=${validateString(paramsId)}&code=${validateString(paramsCode)}`;
    } else if(paramsToken !== undefined){
      return `/examInstructions?urlfrom=${paramsToken}&code=${paramsToken}&urltype=${paramsToken}`;
    }
    return '';
  }

  const validateString = (str) => {
    let _str = "";
      for (let index = 0; index < str.length; index++) {
        let escape = "";
        switch (str.charCodeAt(index)) {
          case 34: // "
            escape = "&quot;";
            break;
          case 38: // &
            escape = "&amp;";
            break;
          case 39: // '
            escape = "&#x27;";
            break;
          case 60: // <
            escape = "&lt;";
            break;
          case 62: // >
            escape = "&gt;";
            break;
          default:
            escape = str[index];
          // continue;
        }
        _str += escape;
      }
    return _str;
  }

  useEffect(() => {
    setUserAgent(getUserAgent());
    getFormInfo();
  }, [setUserAgent])

  const styleSelect = {
    backgroundImage: agree ? `url(${hasSelect})` : `url(${noSelect})`
  }

  return (
    <div className='exam-form'>
     <form className={`form-box h5 ${isMobileUserAgent ? 'mobile' : '' }`}>

        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value={agree}
            id="agree"
            onChange={toggleAgree}/>
          <label className="form-check-label" htmlFor="agree">
          「我已瞭解<Link to={getParams()} className="form-link">測驗方式</Link>，可以開始測驗。」
          </label>
        </div>

        <div className="mt-md-1">為了更有效的服務本平台用戶，提供以下資訊：</div>

        <div className={`row ${!agree ? 'disabled' : ''}`}>
          <div className="col-9 col-md-8">
            <div className="form-group">
              <label className="form-label" for="age" >年齡</label>
              <select
                id="age"
                className="form-select h5"
                aria-label="選擇年齡"
                value={age}
                onChange={changeAge}
                disabled={!agree}
                style={styleSelect}
              >
                <option value=''>請選擇</option>
                {formInfo.age.map(item => (
                  <option value={item.code} key={item.code}>{item.name}</option>))
                }
              </select>
            </div>

            <div className="form-group">
              <label className="form-label">性別</label>
              {formInfo.gender.map(item => (
                <div className="form-check me-3" key={item.code}>
                <input
                  className="form-check-input"
                  type="radio"
                  name={gender}
                  id={item.code}
                  value={item.code}
                  checked={item.code === gender}
                  onChange={changeGender}
                  disabled={!agree}
                  aria-label="選擇性別"
                />

                <label className="form-check-label text-nowrap" htmlFor={item.code}>
                  {item.name}
                </label>
              </div>))}
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="residence" >居住地</label>
              <select
                id="residence"
                className="form-select h5"
                aria-label="選擇居住地"
                value={country}
                onChange={changeCountry}
                disabled={!agree}
                style={styleSelect}
              >
                <option value=''>請選擇</option>
                {formInfo.country.map(item => (
                  <option value={item.code} key={item.code}>{item.name}</option>))
                }
              </select>
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="drving_license" >是否持有駕照</label>
              <select
                id="drving_license"
                className="form-select h5"
                aria-label="選擇是否持有駕照"
                value={driverLicense}
                onChange={changeDriverLicense}
                disabled={!agree}
                style={styleSelect}
              >
                <option value=''>請選擇</option>
                {formInfo.license.map(item => (
                  <option value={item.code} key={item.code}>{item.name}</option>))
                }
              </select>
            </div>
          </div>

          <div className="col-3 col-md-4 d-flex align-items-end">
            <button
              type="button"
              className="submit"
              onClick={() => {
                submitForm({ageGroup: age, gender, residence: country, driverLicense}, formInfo);
              }}
              disabled={!age || !gender || !country || !driverLicense || !agree}
              title="送出表單"
            >送出</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ExamForm;