import { Fragment } from "react";

// 一文三圖
const Block5 = (props) => {
  const { block } = props;

  const imgArr = block.images.filter((item, idx) => idx < 3)

  return (
    <Fragment>
      <div dangerouslySetInnerHTML={{__html: block.subContent}}></div>

      <div className="row images-box">
        {imgArr.map((item, idx) =>
          <div key={idx} className="col-md-4 my-3 my-md-0">

            <div className="square-box">
              <img src={item.url} className='ratio-img' alt={item.content}/>
            </div>

            <div className="square-content mt-1" dangerouslySetInnerHTML={{__html: (item.content || '')}}></div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default Block5;