import BreadcrumbBanner from "../components/BreadcrumbBanner";
import Sidebar from "../components/Sidebar";
import OverviewCard from "../components/OverviewCard";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState, useCallback, useContext } from "react";
import context from '../hooks/context';
import {getTestCategoryQuestionnaireList} from '../API/request';
import PageTitle from "../components/PageTitle";

const ExamOverview = () => {
  const breadcrumb = [
    { text: '首頁', url: '/' },
    { text: '影片測驗', url: '/examOverview' },
  ]

  const params = useParams();
  const paramsId = params.id;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const emvtoken = searchParams.get('emvtoken');
  const { setIsFromMvdis, setCategoryCode, setEmvToken } = useContext(context);

  const [sidebar, setSidebar] = useState([]);
  const firstId = 'PROMOTION';
  const [cards, setCards] = useState([]);
  const [dateTimeDesc, setDateTimeDesc] = useState('');

  useEffect(() => {
    emvtoken ? setIsFromMvdis(true) : setIsFromMvdis(false)
    console.info('emvtoken:', emvtoken);
    setEmvToken(emvtoken);
    if (emvtoken) {
      navigate(`/videoExam/${emvtoken}`, { replace: true });
    }
  }, [emvtoken, setIsFromMvdis])

  useEffect(() => {
    if (emvtoken) {
      return;
    } else if(!paramsId) {
      navigate(firstId, { replace: true })
    } else {
      setCategoryCode(paramsId);
      let subId = '';
      const parseSidebar = (result) => {
        let exams = [];
        if (result?.rc === '0000') {
          const { data } = result;
          exams = data.map((item) => {
            const {testCategoryCode, testCategoryName, testCategoryQuestionnaireList, lastModifyDateTimeDesc} = item;
            let sub = [];
            if (testCategoryQuestionnaireList?.length > 0) {
              sub = testCategoryQuestionnaireList.map((detail, detailIndex) => {
                const {idx, questionnaireName,questionnaireImageUrl} = detail;
                if (subId === '') {
                  if ((detailIndex === 0 && paramsId === testCategoryCode) || (paramsId === idx)) {
                    subId = testCategoryCode;
                  }
                }
                return {id: idx,title: questionnaireName,image:questionnaireImageUrl};
              });
            }
            if (!dateTimeDesc) {
              setDateTimeDesc(lastModifyDateTimeDesc);
            }
            return {
              id: testCategoryCode,
              title: testCategoryName,
              sub,
              lastModifyDateTimeDesc
            }
          });
        }
        return exams;
      }

      const fetchExams = async() => {
        try {
          const result = await getTestCategoryQuestionnaireList();
          let exams = parseSidebar(result);
          setSidebar(exams);
          exams.forEach(item => {
            if(item.id === paramsId) {
              setCards(item);
            }
          })
        } catch (error) {
          console.log(error);
        }
      }

      if (sidebar?.length > 0) {
        sidebar.forEach(item => {
          if(item.id === paramsId) {
            setCards(item);
          }
        })
      } else {
        fetchExams();
      }
    }
  }, [paramsId, navigate, dateTimeDesc])


  return (
    <main className="overview-page">
      <PageTitle title="機車危險感知教育平台 - 影片測驗" />
      <BreadcrumbBanner breadcrumb={breadcrumb} hasSidebar={true} dateTimeDesc={dateTimeDesc}/>
      <section className="container d-md-flex d-print-flex py-3">
        <Sidebar sidebar={sidebar} hasSubnav={false}/>
        <div className="overview">
          <h2 className="title h4 mb-3">{cards.title}</h2>

          <div className='row'>
            {cards.sub && cards.sub.map(item => (<div className='col-6 col-lg-4 mb-3 col-print-4' key={item.id}>
              <OverviewCard card={item} id={paramsId}/>
            </div>))}
          </div>
        </div>
      </section>
    </main>
  );
}

export default ExamOverview;