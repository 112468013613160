import 'bootstrap/js/src/modal';
import Modal from 'react-bootstrap/Modal';
import { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate,  } from "react-router-dom";
import context from '../../hooks/context';
import { textLimit } from "../../helps/textLimit";

const InputModal = (props) => {
  const {isOpenInputModal, closeInputModal, reserveExamUrl, reserveExamToken, httpMethod} = props;
  const emvTokenReturn = useRef();
  const [url, setUrl] = useState('');
  const [token, setToken] = useState('');
  const [method, setMethod] = useState('POST');
  const navigate = useNavigate();
  const { isFromMvdis, userTestingUUID } = useContext(context);
  const modalTitle = useRef(null);

  const [name, setName] = useState('')

  const changeName = (e) => {
    setName(e.target.value);
  }

  const validateString = (str) => {
    let _str = "";
      for (let index = 0; index < str.length; index++) {
        let escape = "";
        switch (str.charCodeAt(index)) {
          case 34: // "
            escape = "&quot;";
            break;
          case 38: // &
            escape = "&amp;";
            break;
          case 39: // '
            escape = "&#x27;";
            break;
          case 60: // <
            escape = "&lt;";
            break;
          case 62: // >
            escape = "&gt;";
            break;
          default:
            escape = str[index];
          // continue;
        }
        _str += escape;
      }
    return _str;
  }

  const handleDownload = () => {
    if (userTestingUUID && name) {
      window.open(`${process.env.REACT_APP_API_URL || '/'}rest/questionnaire/printTestingResult?testingUUID=${validateString(userTestingUUID)}&testerName=${validateString(name)}`);
    } else {
      window.open(`${process.env.REACT_APP_API_URL || '/'}rest/questionnaire/printTestingResult?testingUUID=${validateString(userTestingUUID)}`);
    }
    if(!isFromMvdis){
      closeInputModal();
      navigate('/');
    }    
  }

  useEffect(() => {
    if(!isOpenInputModal) return;
    textLimit(modalTitle.current, 36);
    setUrl(reserveExamUrl);
    setToken(reserveExamToken);
    setMethod(httpMethod);
  }, [isOpenInputModal, token, url, name])

  return (
    <Modal
      show={isOpenInputModal}
      onHide={closeInputModal}
      backdrop="static"
      keyboard={false}
      centered
      className='questionnaire-modal modal-lg'
    >
      <Modal.Body>
        <div className='text-center h3' ref={modalTitle}>請在下列欄位填寫您的個人資訊，我們將稍後為你生成測驗結果檔案。</div>

        <input type="text"
        placeholder='輸入名稱'
        className='form-control'
        onChange={changeName}/>

        <div className='d-flex justify-content-center'>

        {isFromMvdis &&
         <button
         className="btn-modal btn-fill"
         onClick={() => {
          emvTokenReturn.current.submit();
          navigate('/');
        }} >登錄成績</button>}

        <button
        title='下載成績單『另開新視窗』'
        type='button'
        className="btn-modal btn-outline"
        target="_blank"
        rel="noreferrer"
        onClick={handleDownload}
        >下載成績單</button>

        </div>
        <form id="emvTokenReturn" ref={emvTokenReturn} style={{ display: 'none' }} action={url} method={method} target="_blank" >
          <input id="emvToken" name="token" value={token} />
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default InputModal;