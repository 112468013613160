import BreadcrumbBanner from "../components/BreadcrumbBanner";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getVideoTestingPageConfigInfo } from '../API/request';
import { getTestCategoryQuestionnaireList} from '../API/request';
import { getReferenceUrlInfos } from '../API/request';
import PageTitle from "../components/PageTitle";

const Sitemap = () => {
  const breadcrumb = [
    { text: '首頁', url: '/' },
    { text: '網站導覽', url: '/' },
  ]

  const [videoBaseTestingInfoList, setVideoBaseTestingInfoList] = useState([]);
  const [testCategoryQuestionnaireList, setTestCategoryQuestionnaireList] = useState([]);
  const [referenceUrlInfoList, setReferenceUrlInfoList] = useState([]);
  const [dateTimeDesc, setDateTimeDesc] = useState('');

  useEffect(() => {
    const fetchVideoBaseTestingInfo = async () => {
      try {
        const res = await getVideoTestingPageConfigInfo();
        if (res.data && res.rc === '0000') {
          const videoBaseTestingInfos = res.data.videoBaseTestingInfos;
          const { lastModifyDateTimeDesc } = res.data;
          // videoBaseTestingInfos.forEach((videoBase, videoBaseIdx) => {
          //   console.info(`http://hptdemo.rsquare.com.tw/hpt/topicOverview/${videoBase.code}`);
          //   videoBase.videoCategoryTestingInfos.forEach((videoCategory, videoCategoryIdx) => {
          //     videoCategory.videoTopicTestingInfos.forEach((videoTopic, videoTopicIdx) => {
          //       console.info(`http://hptdemo.rsquare.com.tw/hpt/topicArticle/${videoTopic.code}`);
          //     });
          //   });
          // });
          setVideoBaseTestingInfoList(videoBaseTestingInfos || []);
          setDateTimeDesc(lastModifyDateTimeDesc);
        }
      } catch (error) {
        console.log(error);
      }
    }

    const fetchTestCategoryQuestionnaire = async () => {
      try {
        const res = await getTestCategoryQuestionnaireList();
        if (res.data && res.rc === '0000') {
          const result = res.data;
          // result.forEach((testCategory, testCategoryIdx) => {
          //   console.info(`http://hptdemo.rsquare.com.tw/hpt/examOverview/${testCategory.testCategoryCode}`);
          //   testCategory.testCategoryQuestionnaireList.forEach((questionnaire, questionnaireIdx) => {
          //     console.info(`http://hptdemo.rsquare.com.tw/hpt/videoExam/${testCategory.testCategoryCode}/${questionnaire.idx}`);
          //   });
          // });
          setTestCategoryQuestionnaireList(result || []);
        }
      } catch (error) {
        console.log(error);
      }
    }

    const fetchReferenceUrlInfoList = async () => {
      try {
        const res = await getReferenceUrlInfos();
        if (res.data && res.rc === '0000') {
          const result = res.data;
          setReferenceUrlInfoList(result || []);
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchVideoBaseTestingInfo();
    fetchTestCategoryQuestionnaire();
    fetchReferenceUrlInfoList();
  }, [])


  return (
    <main className="site-map">
      <PageTitle title="機車危險感知教育平台 - 網站導覽" />
      <BreadcrumbBanner breadcrumb={breadcrumb} hasSidebar={false} dateTimeDesc={dateTimeDesc} />
      <section className="site-map-container">
        <h2 className="title h3">網站導覽</h2>
        <div>
          本網站依無障礙網頁設計原則建置，網站的主要內容分為三大區塊：<br/>
          <div className="fw-bold">1.上方功能區塊、2.中央內容區塊、3.下方功能區塊。</div>
          <br/>
          如果您的瀏覽器是Firefox，快速鍵的使用方法是 Shift+Alt+(快速鍵字母)，例如 Shift+Alt+C會跳至網頁中央區塊，以此類推。<br/>
          本網站的快速鍵﹝Accesskey﹞設定如下：<br/>

          <div className="my-4">
            <div className="d-flex align-items-center my-3">
              <div className="keyboard">Alt+U</div>
              <div>右上方功能區塊，包括回首頁、網站導覽、網站搜尋、字體選擇等。</div>
            </div>
            <div className="d-flex align-items-center my-3">
              <div className="keyboard">Alt+C</div>
              <div>中央內容區塊，為本頁主要內容區。</div>
            </div>
            <div className="d-flex align-items-center my-3">
              <div className="keyboard">Alt+S</div>
              <div>網站搜尋，可搜尋網站內所有資訊。</div>
            </div>
            <div className="d-flex align-items-center my-3">
              <div className="keyboard">Alt+Z</div>
              <div>下方功能區塊，包括本站聯絡地址、電話及版權等相關資訊。</div>
            </div>
          </div>

          <ul className="link-box mb-3">
            <li>1.<Link to="/">首頁</Link></li>
            <li className="ms-3">1.1.&nbsp;
              <Link to="/reserve/index">預約考照與駕訓補助流程說明</Link>
            </li>
            <li>2.<Link to="/topicOverview">主題總覽</Link></li>
            {videoBaseTestingInfoList.map((videoBase, videoBaseIdx) => (
              <>
              <li className="ms-3">2.{videoBaseIdx+1}. <Link to={`/topicOverview/${videoBase.code}`}>{videoBase.name}</Link></li>
              {videoBase.videoCategoryTestingInfos.map((videoCategory) => (
                <>
                <li className="space2">・<Link to={`/topicOverview/${videoCategory.code}`}>{videoCategory.name}</Link></li>
                  {videoCategory.videoTopicTestingInfos.map((videoTopic) => (
                    <li className="space3" key={videoTopic.code}>・<Link to={`/topicArticle/${videoTopic.code}`}>{videoTopic.name}</Link></li>
                  ))} 
                </>
              ))} 
              </>
            ))}
            <li>3.<Link to="/examInstructions">測驗說明</Link></li>
            <li className="ms-3">3.1.<Link to="/examInstructions/BEHAVIOR">行為判斷</Link></li>
            <li className="ms-3">3.2.<Link to="/examInstructions/QUESTIONNAIRE">風險問答</Link></li>
            <li className="ms-3">3.3.<Link to="/examInstructions/COMBO">複合題型</Link></li>
            <li>4.<Link to="/examOverview">影片測驗</Link></li>
            {testCategoryQuestionnaireList.map((testCategory, testCategoryIdx) => (
              <>
              <li className="space2">4.{testCategoryIdx+1}.<Link to={`/examOverview/${testCategory.testCategoryCode}`}>{testCategory.testCategoryName}</Link></li>
              {testCategory.testCategoryQuestionnaireList.map((questionnaire, questionnaireIdx) => (
                <li className="space3">4.{testCategoryIdx+1}.{questionnaireIdx+1}<Link to={`/videoExam/${testCategory.testCategoryCode}/${questionnaire.idx}`}>{questionnaire.questionnaireName}</Link></li>
                ))}
              </>
            ))}
            <li>5.<Link to="/questionnaire">問卷調查</Link></li>
            <li>6.相關連結</li>
            {referenceUrlInfoList.map((item, idx) => (
              <li className="ms-3" key={`link${idx}`}>6.{idx+1}.<a href={item.url} target="_blank" rel="noreferrer" title={`${item.name}『另開新視窗』`} >{item.name}<span className="icon-open_in"></span></a></li>
            ))}
          </ul>
        </div>
      </section>
    </main>
  );
}

export default Sitemap;