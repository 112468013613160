export const parseExamsAnswer = (result) => {
    if (result?.rc === '0000' && result?.data) {
        const {
            comment,
            correctCount,
            incompleteCount,
            incorrectCount,
            lastModifiedDateTimeDesc,
            passQuestionCount,
            score,
            scoreType,
            testingUUID,
            totalScore,
            totalTestingVideo,
            videoTestingResults,
            testingResultAction,
            isDisplayTopicLink
        } = result.data

        const examQuestions = []
        if (videoTestingResults?.length > 0) {
            videoTestingResults.forEach((item, index) => {
                examQuestions[index] = item
            })
        }

        return {
            ...result.data,
            isReserveExam: true,
            examResultConfig: {
                passEaxmCorrectCount: passQuestionCount,
                isNeedPassExam: testingResultAction === 'PASS',
                isNeedReadAllAnswer: testingResultAction === 'PASS_REVIEW',
            },
            examResultMsg: comment,
            examQuestions,
        }
    }
    return null;
}

export const parseCategory = (code) => {
    if (code === 'GENERAL') {
        return '綜合測驗';
    } else if (code === 'ELDER') {
        return '高齡行車安全';
    } else if (code === 'NEWBIE') {
        return '新手行車安全';
    }
    return '主題測驗';
}