import { useEffect, useState, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";

const TextAndImage = (props) => {

  const location = useLocation();
  const pathName = location.pathname;
  const [isVideoExplain, setIsVideoExplain] = useState(null);
  const [hasOptions, setHasOptions] = useState(false);
  const [answerNo, setAnswerNo] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const optionRef = useRef([]);

  const { question, doneForQuestion, setAnswerTemp, userAnswer, correctAnswer } = props;

  // 因混合題型會包含秒數，查找是否有風險問答題的選項
  const findOptions = useCallback(() => {
    let ans;
    const options = question.questionOptions.map(item => (item.no));
    answerNo.forEach(item => {
      ans = options.find(optionItem => item === optionItem);
    })
    ans ? setHasOptions(true) : setHasOptions(false)
  }, [answerNo])

  const toggleActive = (id, isSingle) => {
    if(isVideoExplain) return;

    if(isSingle) {
      setAnswerNo([id]);
      return;
    }

    if(!answerNo.includes(id)) {
      setAnswerNo(state => [...state, id]);
      return;
    }

    setAnswerNo(state => {
      return state.filter((item) => item !== id)
    })
  }

  const doneAnswer = () => {
    setAnswerTemp(state => ([...state].concat(answerNo)));
    setIsDone(true)
  }


  const handleIsVideoExplain = useCallback(() => {
    if(pathName.includes('videoExplain')) {
      setIsVideoExplain(true)
    } else {
      setIsVideoExplain(false)
    }
  }, [pathName])

  useEffect(() => {
    if (isDone) doneForQuestion();
  }, [isDone])

  useEffect(() => {
    handleIsVideoExplain();
  }, [handleIsVideoExplain])

  useEffect(() => {
    if(pathName.includes('videoExplain')) return;

    findOptions();
  }, [pathName, answerNo, findOptions])

  useEffect(() => {
    optionRef.current[0].focus();
  }, [])

  return (
    <div className="question-image-box text-and-image">
      <div className="question-title">
        {question.single
          ? <span className="question-type">單選</span>
          : <span className="question-type">複選</span>
        }
        <span className="question-title-text">{question.questionText}</span>
      </div>
      <div className="question-btns">
      <div className="row">
        {question.questionOptions.map((item, index) => (
          <div className={`col-6 position-relative
          ${isVideoExplain !== null && isVideoExplain &&
            correctAnswer.includes(item.no) ? 'answer' : ''}`} key={item.no}>
            <button
              onClick={() => toggleActive(item.no, question.single)}
              id={`image${item.no}`}
              type="button"
              className={`btn-secondary
              ${answerNo && answerNo.includes(item.no) ? 'active' : ''}
              ${isVideoExplain && userAnswer.includes(item.no) ? 'userAns' : ''}
              ${isVideoExplain && correctAnswer.includes(item.no) ? 'examAns' : ''}`}
              ref={(el) => (optionRef.current[index] = el)}
            >

              <div className={`btn-ques-text ${item.text.length > 10 ? 'align-items-start' : ''}`}>{item.text}</div>

              <div className="question-img-box">
                <div className="question-img">
                  <img src={item.optionImage} className="img-fluid" alt={item.text}/>
                </div>
              </div>

            </button>

            {isVideoExplain !== null && isVideoExplain &&
            <div className={`answer-text ${index % 2 === 0 ? 'left' : 'right'}`} >
              {correctAnswer.includes(item.no) &&
              <span className="icon-Check me-1"></span>}

              {!correctAnswer.includes(item.no) &&
              userAnswer.includes(item.no) &&
                <span className="icon-error me-1"></span>}

              <div>
                {correctAnswer.includes(item.no) &&
                <div>正確答案</div>}
                {userAnswer.includes(item.no) &&
                  <div>您的選擇</div>
                }
              </div>
            </div>}
          </div>))}

          {isVideoExplain !== null && !isVideoExplain && <button
            type="button"
            onClick={doneAnswer}
            className="btn-secondary complete"
            disabled={answerNo.length === 0 || !hasOptions}>
              <div className="btn-ques-text">完成</div>
          </button>}
        </div>
      </div>
    </div>
  );
}

export default TextAndImage;