import { useEffect, useState } from "react";

const GoTop = () => {
  const [isShowBtn, setIsShowBtn] = useState(false);

  const scrollTop = () => {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    function setScroll() {
      setIsShowBtn(window.scrollY > 100 ? true : false)
    }
    window.addEventListener('scroll', setScroll);

    return () => {
      window.removeEventListener('scroll', setScroll);
    }
  }, [])

  return(
    <>
      {isShowBtn && <div className="gotop" onClick={scrollTop}>
        <div className="text-center">
          <span className="icon-gotop"></span>
          <div className="gotop-text">回頁首</div>
        </div>
      </div>}
    </>
  )
}

export default GoTop;