import BreadcrumbBanner from "../components/BreadcrumbBanner";
import Table from 'react-bootstrap/Table';
import { useRef, useEffect, useContext, useCallback, useState, useMemo } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import context from "../hooks/context";
import { submitQuestionnaire, reviewTestingQuestionnaireResult } from '../API/request';
import localStorage from "../service/localStorageService";
import { textLimit } from "../helps/textLimit";
import { parseExamsAnswer } from "../helps/common";
import PageTitle from "../components/PageTitle";
import TimeoutModal from "../components/questionnaire/TimeoutModal";


const ExamResult = () => {
  const defaultBreadcrumb = [
    { text: '首頁', url: '/' },
    { text: '影片測驗', url: '/examOverview' },
    { text: '測驗結果', url: '/' },
  ]

  const navigate = useNavigate();
  const params = useParams();
  const paramsId = params.id;  
  const [searchParams] = useSearchParams();
  const examType = searchParams.get('type');
  const urlfrom = searchParams.get('urlfrom');
  const code = searchParams.get('code');
  const urltype = searchParams.get('urltype');
  const appendURL = (urlfrom && code) ? `?urlfrom=${urlfrom}&code=${code}&urltype=${urltype}`: '';
  const resultText = useRef();
  const emvTokenReturn = useRef();
  const [isOpenTimeoutModal, setIsOpenTimeoutModal] = useState(false);
  const { userTestingUUID, setUserTestingUUID, userVideoAnswer, userExplainWatched, examExplain, categoryCode, examCode, isFirstExamResult, setIsFirstExamResult } = useContext(context);
  const [previousTestingUUID, setPreviousTestingUUID] = useState('');
  const [breadcrumb, setBreadcrumb] = useState(defaultBreadcrumb);
  const [examsAnswer, setExamsAnswer] = useState({});
  const [isPassExam, setIsPassExam] = useState();
  const [isWatchAll, setIsWatchAll] = useState();
  const [userResult, setUserResult] = useState({
    correct: 0,
    wrong: 0,
    notAnswer: 0,
    result: []
  });
  const [btn, setBtn] = useState({
    left: {},
    right: {}
  });

  const openTimeoutModal = () => {
    setIsOpenTimeoutModal(true);
  }

  const closeTimeoutModal = () => {
    setIsOpenTimeoutModal(false);
  }

  const btnStyle = useMemo(() =>
    {
      console.log('previousTestingUUID===>', previousTestingUUID);
      return {
        testAgain: {
          icon: 'pen',
          text: '再考一次',
          url: `/videoExam/${paramsId}?previousTestingUUID=${previousTestingUUID}`,
          disabled: false,
        },
        testAgainDisabled: {
          icon: 'pen',
          text: '再考一次',
          url: `/videoExam/${paramsId}?previousTestingUUID=${previousTestingUUID}`,
          disabled: true,
        },
        playAgain: {
          icon: 'arrow-big',
          text: '再玩一次',
          url: `/videoExam/${categoryCode}/${examCode}?previousTestingUUID=${previousTestingUUID}`,
          disabled: false,
        },
        playAgainDisabled: {
          icon: 'arrow-big',
          text: '再玩一次',
          url: `/videoExam/${categoryCode}/${examCode}?previousTestingUUID=${previousTestingUUID}`,
          disabled: true,
        },
        questionnaire: {
          icon: 'questionnaire',
          text: '填寫問卷',
          url: '/questionnaire',
          disabled: false,
        },
        questionnaireDisabled: {
          icon: 'questionnaire',
          text: '填寫問卷',
          url: '/questionnaire',
          disabled: true,
        },
        reserveExam: {
          icon: 'card',
          text: '登錄成績',
          url: '',
          disabled: false
        },
        reserveExamDisabled: {
          icon: 'card',
          text: '登錄成績',
          url: '',
          disabled: true,
        },
        startTest: {
          icon: 'arrow-big',
          text: '開始測驗',
          url: `/examOverview`,
          disabled: false,
        },
        returnTestDesc: {
          icon: 'return-circle',
          text: '返回測驗說明',
          url: examType ? `/examInstructions/${examType}${appendURL}`: `/examInstructions${appendURL}`,
          disabled: false,
        },
      }
    }, [paramsId, previousTestingUUID])

  const handleBtn = useCallback(() => {

    if (examsAnswer?.questionnaireInstanceMode === 'TOPIC') {
      setBtn({})
    } else if (examsAnswer?.questionnaireInstanceMode === 'EMV') {
      let left = btnStyle.reserveExam;
      let right = btnStyle.questionnaire;
      if (examsAnswer.testingResultAction === 'FAIL_REVIEW') {
        left = btnStyle.testAgainDisabled;
        right = btnStyle.questionnaireDisabled;
      } else if (examsAnswer.testingResultAction === 'FAIL') {
        left = btnStyle.testAgain;
        right = btnStyle.questionnaireDisabled;
      } else if (examsAnswer.testingResultAction === 'PASS_REVIEW') {
        left = btnStyle.reserveExamDisabled;
        right = btnStyle.questionnaireDisabled;
      } else if(examsAnswer.testingResultAction === 'FREEPASS_REVIEW'){
        left = btnStyle.reserveExamDisabled;
        right = btnStyle.questionnaireDisabled;
      }
      setBtn({
        left,
        right
      })
      
    } else if (examsAnswer?.questionnaireInstanceMode === 'TRAINING') {
      setBtn({
        right: btnStyle.startTest,
        left: btnStyle.returnTestDesc
      })
    } else {
      let left = btnStyle.playAgain;
      let right = btnStyle.questionnaire;
      if (examsAnswer?.testingResultAction === 'PASS_REVIEW' || examsAnswer?.testingResultAction === 'FAIL_REVIEW') {
        left = btnStyle.playAgainDisabled;
        right = btnStyle.questionnaireDisabled;
      }
      setBtn({
        left,
        right
      })
    }
  }, [btnStyle, examsAnswer, isPassExam, isWatchAll])

  const handleNotAnswer = () => {
    setUserResult(state => ({
      ...state,
      notAnswer: state.notAnswer + 1,
      result: [...state.result, false]
    }));
  }

  const submitAnswer = async() => {
    const userInfo = localStorage.getItem(localStorage.keys[1]);
    userVideoAnswer.forEach((item, index) => {
      const userAnswer = userVideoAnswer[index];

      if(!userAnswer) {
        handleNotAnswer();
        return;
      }
    });

    const submitData = {
      testerProfileInfo: userInfo,
      testingUUID: userTestingUUID,
      videoSubmissions: userVideoAnswer
    };
    console.debug('submitData:', submitData);
    const result = await submitQuestionnaire(submitData);
    if (result.rc === '998') {
      setIsOpenTimeoutModal(true);
      return;
    }
    const showResponse = parseExamsAnswer(result);
    if (showResponse) {
      setExamsAnswer(showResponse);
      setPreviousTestingUUID(showResponse.testingUUID);
    } else {
      // refreash page
      reviewTestingResult();
      
    }
    
  }

  const reviewTestingResult = async() => {
    const result = await reviewTestingQuestionnaireResult(paramsId);
    setUserTestingUUID(paramsId);
    const showResponse = parseExamsAnswer(result);
    setExamsAnswer(showResponse);
  }

  const handleIsPassExam = useCallback(() => {
    if(userResult.correct >= examsAnswer?.examResultConfig?.passEaxmCorrectCount) {
      setIsPassExam(true)
    } else {
      setIsPassExam(false)
    }
  }, [userResult, examsAnswer])

  const toTopic = (id) => {
    if (examsAnswer?.questionnaireInstanceMode === 'EMV' && id) {
      window.open(`${process.env.REACT_APP_API_URL || '/'}topicArticle/${validateString(id)}`, '_blank');
    } else {
      navigate(`/topicArticle/${validateString(id)}`, { replace: true });
    }
    
  }

  const validateString = (str) => {
    let _str = "";
      for (let index = 0; index < str.length; index++) {
        let escape = "";
        switch (str.charCodeAt(index)) {
          case 34: // "
            escape = "&quot;";
            break;
          case 38: // &
            escape = "&amp;";
            break;
          case 39: // '
            escape = "&#x27;";
            break;
          case 60: // <
            escape = "&lt;";
            break;
          case 62: // >
            escape = "&gt;";
            break;
          default:
            escape = str[index];
          // continue;
        }
        _str += escape;
      }
    return _str;
  }

  const handleWatchExplain = useCallback(() => {
    if(examsAnswer?.examQuestions && userExplainWatched.length >= examsAnswer.examQuestions.length) {
      setIsWatchAll(true);
    } else {
      setIsWatchAll(false);
    }
  }, [userExplainWatched, examsAnswer])

  const _renderResultAction = useCallback(() => {
    if (examsAnswer?.questionnaireInstanceMode === 'TOPIC') {
      return (<span>{examsAnswer.examQuestions.length > 0 && examsAnswer.examQuestions[0].testingResultDesc}</span>);
    } else if (examsAnswer?.questionnaireInstanceMode === 'TRAINING') {
      return (<span>完成</span>);
    } else if (examsAnswer?.scoreType !== 'TRUE_FALSE') {
      return (<span>{examsAnswer?.score}</span>);
    } else if(examsAnswer?.testingResultAction === 'FREEPASS' || examsAnswer?.testingResultAction === 'FREEPASS_REVIEW'){
      return (<span>不及格</span>)
    } else if (examsAnswer?.testingResultAction !== 'FAIL' && examsAnswer?.testingResultAction !== 'FAIL_REVIEW' ) {
      if (examsAnswer?.passQuestionCount === null || examsAnswer?.passQuestionCount === 0) {
        return (<span>完成</span>);
      }
      return (<span>及格</span>);
    } 
    return (<span>不及格</span>)
  }, [examsAnswer])

  const _renderResultIcon = useCallback(() => {
    if (examsAnswer?.questionnaireInstanceMode === 'TOPIC') {
      if (examsAnswer.examQuestions.length > 0 && examsAnswer.examQuestions[0].testingResultDesc === '答對') {
        return (<span className="icon-smile"></span>);
      }
      return (<span className="icon-no-smile"></span>);
    } else if(examsAnswer?.testingResultAction === 'FREEPASS' || examsAnswer?.testingResultAction === 'FREEPASS_REVIEW'){
      return (<span className="icon-no-smile"></span>);
    } else if (examsAnswer?.testingResultAction !== 'FAIL' && examsAnswer?.testingResultAction !== 'FAIL_REVIEW') {
      return (<span className="icon-smile"></span>);
    } 
    return (<span className="icon-no-smile"></span>);
  }, [examsAnswer])
  
  useEffect(() => {
    if (examExplain !== null) {
      setExamsAnswer(examExplain);
      setPreviousTestingUUID(examExplain.testingUUID);
    } else if (userTestingUUID && isFirstExamResult && userVideoAnswer?.length > 0) {
      submitAnswer();
    } else if (!examsAnswer?.examQuestions){
      reviewTestingResult();
    }
    textLimit(resultText.current, 80);
  }, [paramsId, previousTestingUUID, navigate, examExplain, userVideoAnswer])

  useEffect(() => {
    handleIsPassExam();
  }, [userResult, handleIsPassExam])

  useEffect(() => {
    console.log(userVideoAnswer);
  }, [userVideoAnswer])

  useEffect(() => {
    console.log(examType);
  }, [examType])

  useEffect(() => {
    if (examsAnswer) {
      if (examsAnswer.questionnaireInstanceMode === 'EMV') return; 
      const {testCategoryCode, testCategoryName, videoBaseCode, videoBaseName, videoCategoryCode, videoCategoryName, videoTopicCode, videoTopicName, videoTestingModeCode, videoTestingModeName, questionnaireName, testCategoryIdx, questionnaireId } = examsAnswer;
      if (testCategoryCode && testCategoryName) {
        const { text, url}  = breadcrumb[2];
        breadcrumb[2] = { text: testCategoryName, url: `/examOverview/${testCategoryCode}` };
        breadcrumb[3] = { text: questionnaireName, url: `/videoExam/${testCategoryCode}/${testCategoryIdx}` };
        breadcrumb[4] = { text, url };
      } else if (videoTestingModeCode && videoTestingModeName  && examsAnswer.questionnaireInstanceMode === 'TRAINING') {
        const { url, text }  = breadcrumb[2];
        breadcrumb[1] = { text: '測驗說明', url: `/examInstructions` };
        breadcrumb[2] = { text: videoTestingModeName, url: `/examInstructions/${videoTestingModeCode}` };
        breadcrumb[3] = { text: '練習測驗', url: `/examInstructions/${videoTestingModeCode}?video=true` };
        breadcrumb[4] = { text, url };
      } else if (videoBaseCode && videoBaseName){
        const { text, url}  = breadcrumb[2];
        breadcrumb[1] = { text: videoBaseName, url: `/topicOverview/${videoBaseCode}` };
        breadcrumb[2] = { text: videoCategoryName, url: `/topicOverview/${videoCategoryCode}` };
        breadcrumb[3] = { text: videoTopicName, url: `/topicArticle/${videoTopicCode}` };
        breadcrumb[4] = { text: questionnaireName, url: `/videoExam/topic/${videoTopicCode}/${questionnaireId}` };
        breadcrumb[5] = { text, url };
      }
      setBreadcrumb(breadcrumb);
    }
    
  }, [examsAnswer]);

  useEffect(() => {
    handleBtn();
  }, [examsAnswer, isWatchAll, isPassExam, handleBtn])

  useEffect(() => {
    handleWatchExplain();
  }, [handleWatchExplain])

  return (
    <main className="exam-result">
      <PageTitle title="機車危險感知教育平台 - 測驗結果" />
      <BreadcrumbBanner breadcrumb={breadcrumb} hasSidebar={false} dateTimeDesc={examsAnswer?.lastModifiedDateTimeDesc}/>

      <section className="container">
        <div className="answer-wrapper">
          <div className="answer-box">
            <span className="answer-type">答對</span>
            <span className="answer-number">{examsAnswer?.correctCount}</span>
            <span className="answer-unit">題</span>
          </div>
          <div className="answer-box">
            <span className="answer-type">答錯</span>
            <span className="answer-number">{examsAnswer?.incorrectCount}</span>
            <span className="answer-unit">題</span>
          </div>
          <div className="answer-box">
            <span className="answer-type">未作答</span>
            <span className="answer-number">{examsAnswer?.incompleteCount}</span>
            <span className="answer-unit">題</span>
          </div>
        </div>

        <div className="result-box">
          <div className="result-icon">
            {_renderResultIcon()}
          </div>
          <div className="result-explain">
            <div className="d-flex align-items-center flex-md-wrap mb-1 mb-md-0">
              <div className="fw-bolder text-start flex-shrink-0 h3 me-2">
                {_renderResultAction()}
              </div>
              <div className="h6 mt-md-1 lh-sm">{`本測驗計${examsAnswer?.examQuestions?.length}題${(examsAnswer?.examResultConfig?.passEaxmCorrectCount !== null && examsAnswer?.examResultConfig?.passEaxmCorrectCount !== 0) ? `，答對${examsAnswer?.examResultConfig?.passEaxmCorrectCount}題以上為及格` : ''}`}</div>
            </div>

            <div className="result-text h5" ref={resultText} dangerouslySetInnerHTML={{__html: examsAnswer?.comment}}>
            </div>
          </div>
        </div>

        <div className="table-box">
          <Table responsive className="h5">
            <thead className="text-center text-nowrap">
              <tr>
                <th>影片</th>
                <th>結果</th>
                <th className="d-none d-md-block">說明</th>
                <th>解析</th>
                {examsAnswer?.isDisplayTopicLink !== false && (<th>危險感知</th>)}
              </tr>
            </thead>
            <tbody>
              {examsAnswer?.examQuestions && examsAnswer.examQuestions.map((item, index) =>
                <tr className="tr-row" key={item.testingVideoUUID}>
                  <td className="td-width">
                    <div className="table-img">
                      <img src={item.videoImagePath} className="img-fluid" alt='測驗影片縮圖'/>
                    </div>
                  </td>
                  <td className="td-width">
                    <span>{(examsAnswer.scoreType === 'TRUE_FALSE') ? item.testingResultDesc : item.score}</span>
                  </td>
                  <td className="d-none d-md-table-cell">
                    <div className="explain">
                      <div dangerouslySetInnerHTML={{__html: item.scenario}}>
                      </div>
                    </div>
                  </td>
                  <td className="td-width">
                    {paramsId && item?.testingVideoUUID ? 
                    (
                      <Link to={`/videoExplain/${validateString(paramsId)}/${validateString(item.testingVideoUUID)}${examType?`?type=${validateString(examType)}&mode=${examsAnswer?.questionnaireInstanceMode}`:`?mode=${examsAnswer?.questionnaireInstanceMode}`}`}
                        className={`watch-icon icon-play ${item.isReviewVideo ? 'active' : ''}`}
                        title="觀看解析">
                          <span className="d-none">觀看解析</span>
                    </Link>
                    ):null}
                    <div className="text-nowrap">
                      {item.isReviewVideo ? <span>已觀看</span> : <span>未觀看</span>}
                    </div>
                  </td>
                  {examsAnswer?.isDisplayTopicLink !== false && (
                    <td className="td-width">
                      <button type="button" onClick={() => toTopic(item.videoTopicCode)} className="btn-outline">
                        <span>進入主題</span>
                        {examsAnswer?.questionnaireInstanceMode === 'EMV' ? (
                          <span className="icon-open_in ms-1"></span>
                        ):[]}
                      </button>
                    </td>
                  )}
                  
                </tr>
              )}
            </tbody>
          </Table>

          <div className="row mt-4">
              {btn.left && 
                <div className="col-md-6 col-print-6 order-1 order-md-0 order-print-0 my-2">
                  <button
                    type="button"
                    className='btn-primary h3'
                    disabled={btn.left.disabled}
                    onClick={() => {
                      if (examsAnswer?.emvTokenResponse?.url){
                        if (examsAnswer?.emvTokenResponse?.url) {
                          emvTokenReturn.current.submit();
                        }
                      } else {
                        navigate(btn.left.url, { replace: true })
                      }
                    }}
                  >
                    <span className={`icon-${btn.left.icon}`}></span>
                    <span className="ms-2">{btn.left.text}</span>
                  </button>
                </div>
              }
              {btn.right &&
                <div className="col-md-6 col-print-6 order-2 order-md-1 order-print-1 my-2">
                  <button
                    type="button"
                    className='btn-primary h3'
                    disabled={btn.right.disabled}
                    onClick={() => {
                      navigate(btn.right.url, { replace: true })
                    }}
                  >
                    <span className={`icon-${btn.right.icon}`}></span>
                    <span className="ms-2">{btn.right.text} </span>
                  </button>
                </div>
              }
              <div className="col-md-6 col-print-6 order-0 order-md-2 order-print-2">
                <div className="massage h5">
                  <span>{examsAnswer?.testingResultActionDesc}</span>
                </div>
              </div>
          </div>
        </div>
        <form id="emvTokenReturn" ref={emvTokenReturn} style={{ display: 'none' }} action={examsAnswer?.emvTokenResponse?.url} method={examsAnswer?.emvTokenResponse?.httpMethod} target="_blank">
          <input id="emvToken" name="token" value={examsAnswer?.emvTokenResponse?.token} />
        </form>

      </section>
      <TimeoutModal isOpenTimeoutModal={isOpenTimeoutModal} closeTimeoutModal={closeTimeoutModal} returnUrl={btn.left?.url} />
    </main>
  );
}

export default ExamResult;