import { NavLink, useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect, useState, useCallback, useRef } from "react";

const Sidebar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const paramsId = params.id;
  const { sidebar, hasSubnav } = props;
  const [mobileSubNav, setMobileSubNav] = useState([]);
  const [isShowNav, setIsShowNav] = useState(true);
  const [currentSubIdx, setCurrentSubIdx] = useState(null);
  const subItems = useRef([]);

  const toggleNav = () => {
    setIsShowNav(!isShowNav);
  }

  const clickTitle = () => {
    if(location.pathname.includes('topicOverview')) return;
    setIsShowNav(false);
  }

  const toggleSubNav = (idx, id) => {

    setCurrentSubIdx(idx);

    if(!hasSubnav && id !== undefined) {
      navigate(`/examOverview/${id}`);
      return;
    }

    setMobileSubNav(() => {
      let subNav;
      sidebar.forEach(item => {
        if(item.id === id) subNav = item.sub;
      })
      return subNav;
    })

  }

  const sidebarHeader = location.pathname.includes('topicOverview') ? '主題分類' : '測驗類別';

  const setDefaultNav = useCallback(() => {
    if(sidebar.length <= 0) return;

    if(!hasSubnav) {
      setMobileSubNav(null);
      sidebar.forEach((item, index) => {
        if(!paramsId) return;
        if(item.id !== paramsId) return;
        setCurrentSubIdx(index);
      })
      return;
    }

    if(!paramsId) {
      setMobileSubNav(sidebar[0].sub);
      return;
    }

    sidebar.forEach((item, index) => {
      item.sub.forEach((subItem) => {
        if(subItem.id === paramsId) {
          setMobileSubNav(item.sub)
          setCurrentSubIdx(index);
        }
      })
    })

  }, [hasSubnav, paramsId, sidebar])

  useEffect(() => {
    setDefaultNav();
  }, [setDefaultNav])

  return (
    <div className="position-relative">
      <div className="sidebar">

        <h2 className="sidebar-header h4" onClick={toggleNav}>
          <span>{sidebarHeader}</span>
          <span className={`icon-chevron-top d-md-none d-print-none ${isShowNav ? '' : 'rotate'}`}></span>
        </h2>

        <div className={`sidebar-body h5 d-flex d-md-block d-print-block ${isShowNav ? 'd-flex' : 'd-none'}`}>
          <ul className={`sidebar-items ${hasSubnav ? 'has-subnav' : 'no-subnav'}`} >
            {sidebar.map((item, idx) => (<li className={`sidebar-item ${currentSubIdx === idx ? 'active' : ''} ${location.pathname.includes('topicOverview') ? 'topic' : 'exam'}`}
              key={item.title}
              onClick={clickTitle}>

              <button
                className="sidebar-item-title h5"
                onClick={() => toggleSubNav(idx, item.id)}
                aria-expanded="false">

                <span className="sidebar-title">{item.title}</span>

                {hasSubnav &&
                <span className={`icon-chevron-down d-print-block d-none d-md-block ${currentSubIdx === idx ? 'rotate' : ''}`}></span>}
              </button>

              {hasSubnav && <div className="d-none d-md-block d-print-block">
                <ul className="sidebar-subitems h6" 
                  ref={el => {subItems.current[idx] = el}}
                  style={{maxHeight: currentSubIdx === idx ?
                    subItems.current[idx]?.scrollHeight + 'px' : null}}>
                  {item.sub.map(subItem => (
                    <li key={subItem.id} className={`sidebar-subitem ${paramsId === subItem.id ? 'active' : ''}`}>
                      <NavLink to={`/topicOverview/${subItem.id}`}
                        title={subItem.text}
                        className="d-flex">
                        <div className="me-1">・</div>
                        <div className="subItem-text">{subItem.text}</div>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>}

            </li>))}
          </ul>

          {hasSubnav && <ul className="sidebar-subitems h6 flex-grow-1 d-md-none d-print-none">
            {mobileSubNav.map(item =>
              (<li className="sidebar-subitem" key={item.id}
                onClick={() => setIsShowNav(false)}>
              <NavLink to={`/topicOverview/${item.id}`} title={item.text}>{item.text}</NavLink>
            </li>
            ))}
          </ul>}

        </div>
        </div>
    </div>

  );
}

export default Sidebar;