import axios from 'axios'
import localStorage from '../service/localStorageService'

export const request = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

request.interceptors.response.use(
    async (response) => {
        if (response?.headers && response.headers['user-session']) {
            localStorage.setUserSession(response.headers['user-session'])
        }
        return Promise.resolve(response.data)
    },
    async (error) => {
        // if (axios.isCancel(error)) {
        //   return Promise.reject(error)
        // }

        // return Promise.reject(error)
        if (error.code === 'ECONNABORTED') {
            return Promise.resolve({rc: '998'})
        }
        return Promise.resolve({rc: '999'})
    }
)
