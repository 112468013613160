import BreadcrumbBanner from "../components/BreadcrumbBanner";
import Sidebar from "../components/Sidebar";
import OverviewCard from "../components/OverviewCard";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getVideoTestingPageConfigInfo } from '../API/request';
import PageTitle from "../components/PageTitle";

const TopicOverview = () => {
  const breadcrumb = [
    { text: '首頁', url: '/' },
    { text: '主題總覽', url: '/topicOverview' },
  ]

  const params = useParams();
  const paramsId = params.id;
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState([]);
  const [cards, setCards] = useState({});
  const [dateTimeDesc, setDateTimeDesc] = useState('');
  

  useEffect(() => {
    let subId = '';
    const parseSidebar = (videoPageInfo) => {
      if (videoPageInfo.data && videoPageInfo.rc === '0000') {
        const result = videoPageInfo.data;
        const { lastModifyDateTimeDesc } = videoPageInfo.data;
        const videoBaseTestingInfos = result.videoBaseTestingInfos;
        let infos = [];
        videoBaseTestingInfos.forEach((videoInfo, videoInfoIndex) => {
          let sub = [];
          if (videoInfo.videoCategoryTestingInfos) {
            videoInfo.videoCategoryTestingInfos.forEach((detail, detailIndex) => {
              if (subId === '') {
                if (!paramsId && videoInfoIndex === 0 && detailIndex === 0) {
                  subId = detail.code;
                } else if ((detailIndex === 0 && paramsId === videoInfo.code) || (paramsId === detail.code)) {
                  subId = detail.code;
                }
              }
              let list = [];
              if (detail.videoTopicTestingInfos) {
                detail.videoTopicTestingInfos.forEach((topicInfo, topicInfoIndex) => {
                  list[topicInfoIndex] = {
                    id: topicInfo.code,
                    title: topicInfo.name,
                    dscription: topicInfo.description,
                    randomTestFlag: topicInfo.randomTestFlag,
                    url: '/',
                    image: topicInfo.topicImageUrl
                  };
                });
              }
              sub[detailIndex] = {
                id: detail.code,
                text: detail.name,
                dscription: detail.dscription,
                list
              };
            });
          }
          let info = {
            id: videoInfo.code,
            title: videoInfo.name,
            dscription: videoInfo.dscription,
            sub
          };
          infos[videoInfoIndex] = info;
        });
        setDateTimeDesc(lastModifyDateTimeDesc);
        return infos;
      } 
      return [];
    }

    const parseCards = (sidebarList) => {
      let result;
      for (var videoInfoIndex in sidebarList) {
        const videoInfo = sidebarList[videoInfoIndex];
        const { sub } = videoInfo;
        for (var categoryIndex in sub) {
          const category = sub[categoryIndex];
          if (category.id === paramsId) {
            result = {id:category.id, text: category.text, list: category.list};
            break;
          }
        }
        if (result && result.text) break;
      }
      return result;
    }

    const fetchVideoTestingPageConfigInfo = async () => {
      try {
        const videoPageInfo = await getVideoTestingPageConfigInfo();
        const sidebarList = parseSidebar(videoPageInfo);
        if (subId !== paramsId) {
          navigate(`/topicOverview/${subId}`, { replace: true });
        } else {
          const cards = parseCards(sidebarList);
          setSidebar(sidebarList);
          setCards(cards);
        }
      } catch (error) {
        console.log(error);
      }
    }

    const fetchVideoTopicDescription = (sidebarList) => {
      try {
        const cards = parseCards(sidebarList);
        setCards(cards);
      } catch (error) {
        console.log(error);
      }
    }

    if (paramsId && sidebar && sidebar.length > 0) {
      fetchVideoTopicDescription(sidebar);
    } else {
      fetchVideoTestingPageConfigInfo();
    }
      
    
  }, [paramsId, navigate, sidebar])

  return (
    <main className="overview-page">
      <PageTitle title="機車危險感知教育平台 - 主題總覽" />
      <BreadcrumbBanner breadcrumb={breadcrumb} hasSidebar={true} dateTimeDesc={dateTimeDesc} />
      <section className="container d-md-flex d-print-flex py-3">
        {(sidebar && sidebar.length > 0) ? <Sidebar sidebar={sidebar} hasSubnav={sidebar && sidebar.length > 0}/>  : null}      
        <div className="overview">
          <h2 className="title h4 mb-3">{cards?.text}</h2>

          <div className='row'>
            {cards?.list && cards.list.map(item => (<div className='col-6 col-lg-4 mb-3 col-print-4' key={item.id}>
              <OverviewCard card={item} listId={cards.id}/>
            </div>))}
          </div>
        </div>
      </section>
    </main>
  );
}

export default TopicOverview;