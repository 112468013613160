import {
    useEffect,
    useState,
    useCallback,
    Fragment,
    useRef,
    useMemo,
} from 'react'
import { Link, useParams, useLocation } from "react-router-dom";
import ReactPlayer from 'react-player'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation} from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import BreadcrumbBanner from '../components/BreadcrumbBanner'
import Block1 from '../components/topicArticle/Block1'
import Block2 from '../components/topicArticle/Block2'
import Block3 from '../components/topicArticle/Block3'
import Block4 from '../components/topicArticle/Block4'
import Block5 from '../components/topicArticle/Block5'
import GoTop from "../components/GoTop"
import {videoTopicDescription} from '../API/request'
import {useSearchParams} from "react-router-dom"
import PageTitle from "../components/PageTitle";

const TopicArticle = () => {
    const [searchParams] = useSearchParams();
    const defaultBreadcrumb = [
        {text: '首頁', url: '/'},
        {text: '主題總覽', url: '/topicOverview'},
        {text: '危險感知主題', url: '/'},
        {text: '', url:'', header: '危險感知主題'},
    ]
    
    const params = useParams()
    const paramsId = params.id
    const location = useLocation();
    const pathname = location.pathname;  
    const [breadcrumb, setBreadcrumb] = useState(defaultBreadcrumb)
    const [articles, setArticles] = useState([])

    const [videoData, setVideoData] = useState({
        isPlaying: false,
        isShowStart: true,
        played: 0,
        isPlayIdx: null,
    });    
    const [slide, setSlide] = useState([])
    const [references, setReferences] = useState([])
    const [randomExam, setRandomExam] = useState([])
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    const players = useRef([]);
    const pauseBtns = useRef([]);
    const [dateTimeDesc, setDateTimeDesc] = useState('');

    const validateString = (str) => {
        let _str = "";
          for (let index = 0; index < str.length; index++) {
            let escape = "";
            switch (str.charCodeAt(index)) {
              case 34: // "
                escape = "&quot;";
                break;
              case 38: // &
                escape = "&amp;";
                break;
              case 39: // '
                escape = "&#x27;";
                break;
              case 60: // <
                escape = "&lt;";
                break;
              case 62: // >
                escape = "&gt;";
                break;
              default:
                escape = str[index];
              // continue;
            }
            _str += escape;
          }
        return _str;
    }
    
    const playVideo = (i) => {
        setVideoData(state => ({
            ...state,
            isPlaying: true,
            isShowStart: false,
            isPlayIdx: i,
            played: 0,
          }))
      
        players.current[i].seekTo(0, 'seconds');
        const timer = setTimeout(() => {
            pauseBtns.current[i].focus();
            clearTimeout(timer)
        }, 100);      
    }

    const handleProgress = (progress) => {
        setVideoData(state => ({
          ...state,
          played: progress.played,
        }))
    
        if(progress.played >= 1) {
          setVideoData(state => ({
            ...state,
            played: 0
          }))
        }
      }
    
      const handleEnded = () => {
        setVideoData(state => ({
          ...state,
          isPlaying: false,
          isShowStart: true,
          isPlayIdx: null
        }))
      }
    
      const handlePlaying = () => {
        setVideoData(state => ({
          ...state,
          isPlaying: !state.isPlaying,
        }))
      }
    
      

    const fetchData = useCallback(async () => {
        try {
            const res = await videoTopicDescription(paramsId)
            let result = []
            if (res?.rc === '0000' && res?.data) {
                const {
                    videoTopicName,
                    videoBaseName,
                    videoBaseCode,
                    videoCategoryCode,
                    videoCategoryName,
                    videoTopicDescriptionInfos,
                    videoTopicDataSourceInfos,
                    specificTopicPurposeQuestionnaireList,
                    randomTopicPurposeQuestionnaireList,
                    lastModifyDateTimeDesc
                } = res.data
                videoTopicDescriptionInfos.map((info, infoIndex) => {
                    const {videoTopicDetailDescriptionConfigInfos} = info
                    const blocks = videoTopicDetailDescriptionConfigInfos.map(
                        (block) => {
                            const {
                                theoryTitle,
                                theoryDescription,
                                theoryStyle,
                                theoryImage1Url,
                                theoryImage2Url,
                                theoryImage3Url,
                                topicVideoUrl,
                                theoryImage1Description,
                                theoryImage2Description,
                                theoryImage3Description,
                                videoTopicDataSourceInfos,
                            } = block
                            const referenceList = videoTopicDataSourceInfos.map(
                                ({dataSourceUrl, dataSourceName, dataSourceType, fileName}) => ({
                                    url: dataSourceUrl,
                                    title: dataSourceName,
                                    name: dataSourceType === '檔案' ? fileName : dataSourceName,
                                })
                            )
                            return {
                                blockType: theoryStyle,
                                subTitle: theoryTitle,
                                subContent: theoryDescription,
                                images: [
                                    {
                                        url: theoryImage1Url,
                                        content: theoryImage1Description,
                                    },
                                    {
                                        url: theoryImage2Url,
                                        content: theoryImage2Description,
                                    },
                                    {
                                        url: theoryImage3Url,
                                        content: theoryImage3Description,
                                    },
                                ],
                                videos: topicVideoUrl
                                    ? [{url: topicVideoUrl}]
                                    : [],
                                references: referenceList,
                            }
                        }
                    )

                    result[infoIndex] = {
                        topicId: `${infoIndex}_topicId`,
                        masterHeading: info.theoryTitle,
                        masterContent: info.theoryDescription,
                        blocks,
                    }
                })
                if (specificTopicPurposeQuestionnaireList?.length > 0) {
                    const specificTopics = specificTopicPurposeQuestionnaireList.map(({id, questionnaireName,questionnaireImageUrl}) => 
                    ({id, image: questionnaireImageUrl, title: questionnaireName}));
                    setSlide(specificTopics);
                }

                if (randomTopicPurposeQuestionnaireList?.length > 0) {
                    const randomTopics = randomTopicPurposeQuestionnaireList.map(({id, questionnaireName,questionnaireImageUrl}) => 
                    ({id, image: questionnaireImageUrl, title: questionnaireName}));
                    setRandomExam(randomTopics);
                }
                const referenceList = videoTopicDataSourceInfos.map(
                    ({dataSourceUrl, dataSourceName, dataSourceType, fileName}) => ({
                        url: dataSourceUrl,
                        title: dataSourceName,
                        name: dataSourceType === '檔案' ? fileName : dataSourceName,
                    })
                )
                setArticles(result)
                setReferences(referenceList)
                breadcrumb[1] = { text: videoBaseName, url: `/topicOverview/${videoBaseCode}`};
                breadcrumb[2] = { text: videoCategoryName, url: `/topicOverview/${videoCategoryCode}`};
                breadcrumb[3].text = videoTopicName;
                setBreadcrumb(breadcrumb)
                setDateTimeDesc(lastModifyDateTimeDesc);
            }
        } catch (error) {
            console.error(error)
        }
    }, [paramsId])

    useEffect(() => {
        fetchData()
    }, [fetchData])


    return (
        <main className="topic-article">
            <PageTitle title={`機車危險感知教育平台 - ${breadcrumb[breadcrumb.length-1].text}`} />
            <BreadcrumbBanner breadcrumb={breadcrumb} hasSidebar={false} dateTimeDesc={dateTimeDesc} />
            <section className="topic-article-container">
            <h2 className="article-title fw-bold text-primary">
                {breadcrumb[breadcrumb.length-1].text}
            </h2>
            
            {articles.map((article) => {
                return (
                    <>
                        <div className="main-card">
                            <h2 className="main-card-title">
                                {article.masterHeading}
                            </h2>
                            <div
                                className="main-card-text h5"
                                dangerouslySetInnerHTML={{
                                    __html: article.masterContent || '',
                                }}
                            ></div>
                        </div>

                        {article.blocks &&
                            article.blocks.map((item, idx) => (
                                <div className="sub-card" key={`card_${idx}`}>
                                    {item.subTitle && (
                                        <h3 className="h3 sub-card-title">
                                            {item.subTitle}
                                        </h3>
                                    )}
                                    <div className="h5">
                                        {item.blockType === 'DESC_ONLY' && (
                                            <Block1 block={item} />
                                        )}
                                        {item.blockType ===
                                            'ONE_DESC_ONE_IMAGE_HORIZONTAL' && (
                                            <Block2 block={item} />
                                        )}
                                        {item.blockType ===
                                            'ONE_DESC_ONE_IMAGE' && (
                                            <Block3 block={item} />
                                        )}
                                        {item.blockType ===
                                            'ONE_DESC_TWO_IMAGES' && (
                                            <Block4 block={item} />
                                        )}
                                        {item.blockType ===
                                            'ONE_DESC_THREE_IMAGES' && (
                                            <Block5 block={item} />
                                        )}

                                        <div
                                            className={`video-box ${
                                                item.videos.length > 0 &&
                                                item.references.length > 0
                                                    ? 'has-video-link'
                                                    : ''
                                            }`}
                                        >
                                            {item.videos.length > 0 && (
                                                <div className="player-box">
                                                    {item.videos.map(
                                                        (subItem, subIdx) => (
                                                            <Fragment
                                                                key={`player${subIdx}`}
                                                            >
                                                                <div className="player-wrapper">
                                                                    <ReactPlayer
                                                                        url={
                                                                            subItem.url
                                                                        }
                                                                        width="100%"
                                                                        height="100%"
                                                                        className="react-player"
                                                                        playing={videoData.isPlayIdx === idx && videoData.isPlaying}
                                                                        onProgress={handleProgress}
                                                                        onEnded={handleEnded}
                                                                        playsinline={true}
                                                                        ref={el => (players.current[idx] = el)}                                              
                                                                        controls
                                                                    />
                                                                    {videoData.isPlayIdx !== idx && (
                                                                    <div className="video-overlay h5">
                                                                            <button
                                                                                type="button"
                                                                                className="btn-play"
                                                                                aria-label="影片播放"
                                                                                onClick={() =>
                                                                                    playVideo(
                                                                                        idx
                                                                                    )
                                                                                }
                                                                            >
                                                                                <span className="icon-play"></span>
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                
                                                                {videoData.isPlayIdx === idx && <progress max={1} value={videoData.played} className='progress-bar transition'/>}

                                                                {videoData.isPlayIdx === idx && !videoData.isShowStart && <button type="button" className='pause' onClick={handlePlaying} ref={el => (pauseBtns.current[idx] = el)}>
                                                                {videoData.isPlaying && <span className="icon-pause"></span>}
                                                                {!videoData.isPlaying && <span className="icon-triangle play"></span>}
                                                                </button>}
                                                            </Fragment>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                            {item.references.length > 0 && (
                                                <div className="link-box">
                                                    <div className="fw-bold">
                                                        參考連結
                                                    </div>
                                                    {item.references.map(
                                                        (subItem, subIdx) => (
                                                            <a
                                                                href={
                                                                    subItem.url
                                                                }
                                                                title={`${subItem.name}『另開新視窗』`}
                                                                target="_blank"
                                                                key={`ref${subIdx}`}
                                                                className="link"
                                                                rel="noreferrer"
                                                            >
                                                                <span className="link-text">
                                                                    {
                                                                        subItem.title
                                                                    }
                                                                </span>
                                                                <span className="icon-open_in"></span>
                                                            </a>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </>
                )
            })}
            </section>
            <section className="slide-wrapper">
                <div className="slide-container">
                    {slide?.length > 0 &&
                        (
                            <div className="slide-box">
                                    <div className="d-none d-md-flex d-print-flex justify-content-between mb-3">
                                        <h3 className="fw-bold h4">測驗影片清單</h3>
                                        {slide?.length > 3 && (
                                            <div>
                                                <button
                                                    ref={navigationPrevRef}
                                                    className="navigation prev"
                                                    aria-label="清單上一個"
                                                >
                                                    <span className="icon-chevron-right"></span>
                                                </button>
                                                <button
                                                    ref={navigationNextRef}
                                                    className="navigation"
                                                    aria-label="清單下一個"
                                                >
                                                    <span className="icon-chevron-right"></span>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                <Swiper
                                    slidesPerView={1.4}
                                    spaceBetween={30}
                                    modules={[Navigation]}
                                    navigation={{
                                        prevEl: navigationPrevRef.current,
                                        nextEl: navigationNextRef.current,
                                    }}
                                    onBeforeInit={(swiper) => {
                                        swiper.params.navigation.prevEl =
                                            navigationPrevRef.current
                                        swiper.params.navigation.nextEl =
                                            navigationNextRef.current
                                    }}
                                    breakpoints={{
                                        768: {
                                            slidesPerView: 3.5,
                                        },
                                        1300: {
                                            slidesPerView: 4,
                                        },
                                    }}
                                >
                                    {slide?.length && slide.map((item) => (
                                        <SwiperSlide key={item.id + item.title}>
                                            {paramsId && item?.id ?
                                                (<Link  to={`/videoExam/topic/${validateString(paramsId)}/${item.id}`}
                                                    className="slide-bg">
                                                        <div>
                                                            <img
                                                                src={item.image}
                                                                className="img-fluid"
                                                                alt=''
                                                            />
                                                        </div>
                                                        <div className="slide-text-box h6">
                                                            <div className="slide-text">
                                                                {item.title}
                                                            </div>
                                                        </div>
                                                </Link>):null
                                            }
                                            
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        )
                    }
                    
                    {/* {randomExam?.length > 0 && randomExam.map((item) => (
                        <div className="text-center" key={item.id + item.title}>
                            <Link
                                to={`/videoExam/topic/${paramsId}/${item.id}`}
                                className="btn-random"
                            >
                                <span className="icon-arrow"></span>
                                <span className="h3 ms-2">隨機測驗</span>
                            </Link>
                        </div>
                    ))} */}

                    {paramsId && randomExam?.length > 0 && (
                        <div className="text-center" key={randomExam[0].id + randomExam[0].title}>
                            <Link
                                to={`/videoExam/topic/${validateString(paramsId)}/${randomExam[0].id}`}
                                className="btn-lg"
                            >
                                <span className="icon-arrow"></span>
                                <span className="h3 ms-2">隨機測驗</span>
                            </Link>
                        </div>
                    )}

                    {references?.length > 0 && (
                        <div className="references-content">
                        <h3 className="references-title fw-bold">
                            主題相關連結
                        </h3>
                        <div className="row">
                            {references.map((item, idx) => (
                                <div className="col-md-4 col-print-4" key={idx}>
                                    <a
                                        title={`${item.name}『另開新視窗』`}
                                        href={item.url}
                                        target="_blank"
                                        className="link"
                                        rel="noreferrer"
                                    >
                                        <span className="link-text">
                                            {item.title}
                                        </span>
                                        <span className="icon-open_in"></span>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                    )}
                    
                </div>
            </section>
            <GoTop />
        </main>
    )
}

export default TopicArticle
