import 'bootstrap/js/src/modal';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from 'react';
import context from '../../hooks/context';
import { textLimit } from "../../helps/textLimit";

const SuccessModal = (props) => {
  const {isOpenSuccessModal, closeSuccessModal, openInputModal, reserveExamUrl, reserveExamToken, httpMethod} = props;
  const [url, setUrl] = useState('');
  const [token, setToken] = useState('');
  const [method, setMethod] = useState('POST');
  const { isFromMvdis } = useContext(context);
  const modalTitle = useRef(null);
  const emvTokenReturn = useRef();
  const navigate = useNavigate();
  const handleOpenInputModal = () => {
    closeSuccessModal();
    openInputModal();
  }

  useEffect(() => {    
    if(!isOpenSuccessModal) return;
    textLimit(modalTitle.current, 36);
    setUrl(reserveExamUrl);
    setToken(reserveExamToken);
    setMethod(httpMethod);
  }, [isOpenSuccessModal, token, url])

  return (
    <Modal
      show={isOpenSuccessModal}
      onHide={closeSuccessModal}
      backdrop="static"
      keyboard={false}
      centered
      className='questionnaire-modal modal-lg'
    >
      <Modal.Body >
        <div className='text-center h3 mb-5' ref={modalTitle}>感謝你的填寫，已成功提交問卷！</div>

        <div className='d-flex justify-content-center'>

         {isFromMvdis &&
         <button
         className="btn-modal btn-fill" 
         rel="noreferrer"     
         onClick={() => {
          emvTokenReturn.current.submit();
          navigate('/');
        }} >登錄成績</button>}

         {!isFromMvdis &&
         <Link
         to="/"
         className="btn-modal btn-fill">返回首頁</Link>}

         <button type="button" className="btn-modal btn-outline"
         onClick={handleOpenInputModal}>下載成績單</button>

        </div>
        <form id="emvTokenReturn" ref={emvTokenReturn} style={{ display: 'none' }} action={url} method={method} target="_blank">
          <input id="emvToken" name="token" value={token} />
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default SuccessModal;