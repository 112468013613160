import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import ReactGA from 'react-ga4';
const trackingId = process.env.REACT_APP_GA_TRACKING_ID || 'G-17SPXTH4FS';

ReactGA.initialize(trackingId, { 
  gaOptions: {
    siteSpeedSampleRate: 100
}});

ReactGA.ga('send', 'timing', 'JS Libraries', 'load', 99, 'CDN libs');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />

  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
