import { Fragment } from "react";

// 一文一圖 上下排列
const Block3 = (props) => {
  const { block } = props;

  return (
    <Fragment>
      <div dangerouslySetInnerHTML={{__html: block.subContent}}></div>

      <div className="images-box">
        <div className="rectangle-box">
          <img src={block.images[0].url}
          className='ratio-img'
          alt={block.images[0].content}/>
        </div>

        <div className="mt-3" dangerouslySetInnerHTML={{__html: (block.images[0].content || '')}}></div>
      </div>
    </Fragment>
  );
}

export default Block3;