import 'bootstrap/js/src/modal';
import Modal from 'react-bootstrap/Modal';
import { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate,  } from "react-router-dom";
import context from '../../hooks/context';
import { textLimit } from "../../helps/textLimit";

const InputModal = (props) => {
  const {isOpenTimeoutModal, closeTimeoutModal, returnUrl} = props;
  const navigate = useNavigate();


  const retest = () => {
    closeTimeoutModal();
    if(returnUrl !== undefined && returnUrl !== null){
      navigate(returnUrl);
    }
  }

  return (
    <Modal
      show={isOpenTimeoutModal}
      onHide={closeTimeoutModal}
      backdrop="static"
      keyboard={false}
      centered
      className='questionnaire-modal modal-lg'
    >
      <Modal.Body>
      <div className='d-flex flex-column justify-content-center align-items-center long-div'>
          <div className='text-center h3 mb-5'>網路連線逾時，請重新測驗。</div>

          <div className='d-flex justify-content-center'>        
            <button
            title='重新測驗'
            type='button'
            className="btn-modal btn-fill"
            rel="noreferrer"
            onClick={retest}
            >重新測驗</button>

          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default InputModal;