import { Link } from "react-router-dom";
import { useState, useRef, useCallback, useEffect } from "react";
import footerBg from "../assets/components/footer/footerBg.png";
import footerBgMobile from "../assets/components/footer/footerBg_m.png";
import logo from "../assets/logoNoText.png";
import gov from "../assets/components/footer/gov.png";
import accessibility from "../assets/components/footer/accessibility.png";
import { getReferenceUrlInfos } from '../API/request';

const Footer = () => {
  const info = [
    { icon: 'icon-Clock', title: '上班時間', text: '上午08:30至下午05:30'},
    { icon: 'icon-location', title: '地址', text: '10863 臺北市萬華區東園街65號'},
    { icon: 'icon-Phone', title: '服務專線', text: '0800-231-035'},
  ]

  const phone = [
    { name: '臺北區監理所', phone: '(02)2688-4366' },
    { name: '臺北市區監理所', phone: '(02)2763-0155' },
    { name: '新竹區監理所', phone: '(03)589-2051' },
    { name: '臺中區監理所', phone: '(04)2691-2011' },
    { name: '嘉義區監理所', phone: '(05)362-3939' },
    { name: '高雄區監理所', phone: '(07)771-1101' },
    { name: '高雄市區監理所', phone: '(07)361-3161' },
    { name: '公路人員訓練所', phone: '(02)8221-2888' },
  ]

  const guided = useRef();
  const collapseFooter = useRef();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [ menu, setMenu ] = useState([]);

  const toggleMenu = () => {
    setIsOpenMenu(!isOpenMenu);
    if(isOpenMenu) {
      collapseFooter.current.style.maxHeight = null;

    } else {
      collapseFooter.current.style.maxHeight = `${collapseFooter.current.scrollHeight}px`
    }
  }

  const handleKeypress = useCallback((e) => {
    const { key, altKey } = e;
    if (altKey && key === 'z') {
      guided.current.focus();
    }
  },[]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeypress);

    return () => document.removeEventListener('keydown', handleKeypress);

  }, [handleKeypress]);

  useEffect(() => {
    let defualtMenu = [
      { id: 'home', title: '首頁', url: '/' },
      { id: 'topic', title: '主題總覽', url: '/topicOverview' },
      { id: 'examExplain', title: '測驗說明', url: '/examInstructions' },
      { id: 'videoExam', title: '影片測驗', url: '/examOverview' },
      { id: 'question', title: '問卷調查', url: '/questionnaire?url=main' },
      {
        id: 'link',
        title: '相關連結',
        url: '',
        link: []
      },
    ]

    const fetchMenu = async () => {
      const result = await getReferenceUrlInfos();
      if (result && result.rc === '0000') {
        if (result.data && result.data.length > 0) {
          defualtMenu[5].link = result.data.map(({ name, url }) => {
            return { title: name, url };
          });
        }
      } 
      setMenu(defualtMenu);
    }
    if (menu.length === 0) {
      fetchMenu();
    }
  }, [menu, setMenu]);

  return (
    <footer className="footer d-print-none">
      <div className="position-relative">

        <img src={footerBg} className='d-none d-md-block img-fluid' alt=''/>
        <img src={footerBgMobile} className='d-md-none img-fluid' alt=''/>

        <div className="collapse">
          <button
            className="collapse-btn"
            onClick={toggleMenu}
            aria-expanded="false"
            aria-controls="collapseFooter">
            {isOpenMenu ?
            (<div>
              <div className="collapse-btn-text" tabIndex={0} role='button' >收合</div>
              <span className="icon-chevron-top"></span>
            </div>)
            :
            (<div>
              <div className="collapse-btn-text" tabIndex={0} role='button' >展開</div>
              <span className="icon-chevron-down"></span>
            </div>)
            }
          </button>
          <div className="collapse-btn-shadow"></div>
        </div>
      </div>

      <div className='collapse-box' id='collapseFooter' ref={collapseFooter}>
        <nav className="container py-2">
          <ul className="foot-links d-flex justify-content-between flex-wrap">
            {menu.map(item => (
              <li key={item.id}
                className={`foot-link my-2 ${item.id === 'question' || item.id === 'link' ? 'col-12' : 'col-6'}`}>

                <span className="icon-bot me-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </span>

            {(item.url) ? (
              <Link
                to={item.url}
                title={item.title}
                tabIndex={item.id === 'link' ? -1 : 0}>
                {item.title}
              </Link>
            ) : <span>{item.title}</span>}

                {item.id==='link' && (
                  <ul className="mt-3 mt-md-2">
                    {item.link.map(subItem => (
                    <li key={subItem.title} className='my-2'>
                      <span className="foot-small-dot"></span>

                      <a href={subItem.url} title={`${subItem.title}『另開新視窗』`} target="_blank" rel="noreferrer">
                        {subItem.title}
                        <span className="icon-open_in ms-1"></span>
                      </a>

                    </li>))}
                  </ul>)}

              </li>))
            }
          </ul>
        </nav>
      </div>
      <div className="footer-contact py-3">
        <button type="button" className="guided" title="下方網站資訊區" accessKey="Z"
          ref={guided}>
          <span>:::</span>
        </button>
        <div className="container-lg d-md-flex justify-content-md-center">

          <div className="desktop-footer-logo mb-2 text-center">
            <img src={logo} alt=''/>

            <div className="d-none d-md-flex text-center my-3">
              <a href="https://accessibility.moda.gov.tw/Applications/Detail?category=20230928170216" 
                title='無障礙網站『另開新視窗』' 
                target='_blank'
                rel="noreferrer" >
                <img src={accessibility} className="mx-1" alt='通過AA無障礙網頁檢測'/>
              </a>

              <a href="https://www.gov.tw/Default.aspx"
                target='_blank'
                title='我的e政府『另開新視窗』'
                rel="noreferrer"
              >
                <img src={gov} className="mx-1" alt=''/>
                <span className="d-none">我的e政府</span>
              </a>
            </div>
          </div>

          <div className="desktop-footer-info">
            <div className="footer-logo-name text-center text-md-start">
              中華民國交通部公路局<br/>
              Highway Bureau, MOTC
            </div>
            <ul className="contact-info my-3 mx-auto">
              {info.map(item => (
              <li key={item.title} className='d-flex align-items-center my-2'>
                <span className={`me-2 ${item.icon}`}></span>
                <div>
                  <div>{item.title}</div>
                  <div>{item.text}</div>
                </div>
              </li>))}
            </ul>
            <div className="d-none d-md-block text-start my-3">
              <Link to="/privacy" title="隱私權、資訊安全及政府網站資料開放宣告">隱私權、資訊安全及政府網站資料開放宣告</Link>
              <div>
                填寫意見信箱：<a href='https://www.mvdis.gov.tw/m3-emv-cht/public/appeal'
                title='監理服務網-意見信箱暨申訴平台『另開新視窗』' target='_blank' rel="noreferrer">監理服務網-意見信箱暨申訴平台
                <span className="icon-open_in ms-1"></span>
                </a>
              </div>
            </div>
          </div>

          <div className="contact-phone">
            <ul>
              {phone.map(item => (
                <li key={item.name}
                  className='d-flex align-items-center my-2'>
                <span className="icon-Phone me-2"></span>
                <span className="me-2">{item.name}</span>
                <span>{item.phone}</span>
              </li>))}
            </ul>
          </div>

          <div className="d-md-none text-center my-3">
            <Link to='/privacy' title='隱私權、資訊安全及政府網站資料開放宣告'>
              隱私權、資訊安全及政府網站資料開放宣告
            </Link>
            <div>
              填寫意見信箱：
              <a href='https://www.mvdis.gov.tw/m3-emv-cht/public/appeal'
                title='監理服務網-意見信箱暨申訴平台『另開新視窗』' target='_blank' rel="noreferrer">
                監理服務網-意見信箱暨申訴平台
                <span className="icon-open_in ms-1"></span>
              </a>
            </div>
          </div>

          <div className="d-md-none text-center mt-3">
            <img src={accessibility} alt="" className="mx-1"/>
            <img src={gov} alt="GOV標章" className="mx-1" />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;