// 一文一圖 左右排列
const Block2 = (props) => {
  const { block } = props;

  return (
    <div className="block02-box">
      <div className="block02-content" dangerouslySetInnerHTML={{__html: block.subContent}}></div>

      <div className="block02-image">
        <div className="square-box">
          <img src={block.images[0].url}
          className='ratio-img'
          alt={block.images[0].content}/>
        </div>

        <div className="square-content mt-1" dangerouslySetInnerHTML={{__html: (block.images[0].content || '')}}></div>
      </div>
    </div>
  );
}

export default Block2;