import BreadcrumbBanner from "../components/BreadcrumbBanner";
import Step from "../components/Step";
import StepInfo from "../components/StepInfo";
import { Link, NavLink, useParams } from "react-router-dom";
import reserveImg1 from "../assets/pages/reserve/index/1.jpg";
import reserveImg2 from "../assets/pages/reserve/index/2.jpg";
import reserveImg3 from "../assets/pages/reserve/index/3.jpg";
import reserveImg4 from "../assets/pages/reserve/index/4.jpg";
import reserveImg5 from "../assets/pages/reserve/index/5.jpg";
import reserveImg6 from "../assets/pages/reserve/index/6.jpg";
import GoTop from "../components/GoTop";
import { useState, useEffect, useCallback } from "react";
import { getReserve } from '../API/reserve'
import {getAssistanceFolwDescription} from '../API/request'
import PageTitle from "../components/PageTitle";

const tab = [
  {
    title: '預約考照流程',
    url: '/reserve/index'
  },
  {
    title: '駕訓補助流程',
    url: '/reserve/subsidy'
  },
]

const steps = {
  index: [
    { id: 1, title: '進入專區'},
    { id: 2, title: '進行測驗'},
    { id: 3, title: '測驗結果'},
    { id: 4, title: '登錄成績'},
    { id: 5, title: '預約考照'},
  ],
  subsidy: [
    { id: 1, title: '進入專區'},
    { id: 2, title: '進行測驗'},
    { id: 3, title: '測驗結果'},
    { id: 4, title: '登錄成績'},
  ]
}

const setImg = (img) => {
  switch (img) {
    case 'reserveImg1':
      return reserveImg1;
    case 'reserveImg2':
      return reserveImg2;
    case 'reserveImg3':
      return reserveImg3;
    case 'reserveImg4':
      return reserveImg4;
    case 'reserveImg5':
      return reserveImg5;
    case 'reserveImg6':
      return reserveImg6;
    default:
      return ''
  }
}

const Reserve = () => {
  const [breadcrumb, setBreadcrumb] = useState([
    { text: '首頁', url: '/' },
    { text: '預約考照流程', url: '/' },
  ]);

  const params = useParams();
  const paramsId = params.id;
  const [step, setStep] = useState([]);
  const [stepInfoData, setStepInfoData] = useState({
    index: [],
    subsidy: []
  });
  const [stepInfo, setStepInfo] = useState([]);
  const [targetIdx, setTargetIdx] = useState(null);

  const changeTab = (title) => {
    setBreadcrumb([
      { text: '首頁', url: '/' },
      { text: title, url: '/' },
    ])
  }
  
  const [dateTimeDesc, setDateTimeDesc] = useState('');

  const fetchData = useCallback(async () => {
    try {
      const res = await getAssistanceFolwDescription();
      if (res.data && res.rc === '0000') {
        const result = res.data    
        setStepInfoData(() => {
          return {
            index: result.appointmentExam.map(item => {
              return {
                ...item,                
                data: item.data.map(subItem => {
                  return {
                    ...subItem,
                    image: subItem.image
                  }
                })
              }
            }),
            subsidy: result.drivingCourse.map(item => {
              return {
                ...item,
                data: item.data.map(subItem => {
                  return {
                    ...subItem,
                    image: subItem.image
                  }
                })
              }
            }),
          }
        });

        if (result.lastModifyDateTimeDesc) {
          setDateTimeDesc(result.lastModifyDateTimeDesc);
        }

      }
    } catch (error) {
      console.log(error);
    }
  }, [dateTimeDesc])

  useEffect(() => {
    setStep(steps[paramsId])
    setStepInfo(stepInfoData[paramsId])
  }, [paramsId, stepInfoData])

  useEffect(() => {
    fetchData();
  }, [fetchData])

  return (
    <main className="reserve-page">
      <PageTitle title="機車危險感知教育平台 - 預約考照流程" />
      <BreadcrumbBanner breadcrumb={breadcrumb} hasSidebar={false} customTitle="預約考照與駕訓補助說明" dateTimeDesc={dateTimeDesc} />

      <section>
        <div className="container">
          <div className="d-flex justify-content-center tab-content">
            <div className="tab-box">
              {tab.map(item =>
                <NavLink to={item.url}
                className="tab"
                tabIndex={`${item.url.includes(paramsId) ? -1 : 0}`}
                key={item.url} onClick={() => changeTab(item.title)}>{item.title}</NavLink>)}
            </div>
          </div>

          <div className="step-box">
            <Step step={step} clickTarget={setTargetIdx}/>
          </div>

          <div className="bg-white reserve-content">
            <StepInfo data={stepInfo} targetIdx={targetIdx} targetTo={setTargetIdx}/>
          </div>

          <Link to='/examOverview?emvtoken=emvtoken' state={{ isGeneralTest: false }}className="btn-lg">
            <span className="icon-arrow"></span>
            <span className="h3 ms-2">前往測驗</span>
          </Link>
        </div>
      </section>

      <GoTop />
    </main>
  )
}

export default Reserve;
