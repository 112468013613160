import { Link } from "react-router-dom";

const Step = ({ step, clickTarget }) => {
  return (
    <div className="step d-flex justify-content-center">
      {step.map((item, index) => <div className="d-flex align-items-md-center" key={item.id}>
        <div className="text-center text-md-start d-flex align-items-center flex-column flex-md-row">
          <div className="circle-num mb-1 mb-md-0">{item.id}</div>
          <Link className="h5 text-nowrap link" onClick={() => clickTarget(index)}>{item.title}</Link>
        </div>
        {step.length - 1 !== index && <span className="icon-chevron-right mt-1 mt-md-0"></span>}
      </div>)}
    </div>
  )
}

export default Step;