import {
  useEffect,
  useState,
  useCallback,
  Fragment,
  useContext,
} from 'react'
import BreadcrumbBanner from "../components/BreadcrumbBanner";
import SuccessModal from "../components/questionnaire/SuccessModal";
import InputModal from "../components/questionnaire/InputModal";
import { useSearchParams, useNavigate } from "react-router-dom";
import localStorage from '../service/localStorageService';
import { getSurvey, submitSurvey} from '../API/request';
import context from "../hooks/context";
import PageTitle from "../components/PageTitle";

const Questionnaire = () => {
  const breadcrumb = [
    { text: '首頁', url: '/' },
    { text: '問卷調查', url: '/' },
  ]

  // const submitForm = (formState, formInfo) => {
  //   setIsShowForm(false);
  //   localStorage.setItem([
  //     {key: localStorage.keys[0], value: false},
  //     {key: localStorage.keys[1], value: formState},
  //     {key: localStorage.keys[2], value: formInfo}
  //   ]);
  // }

  const question = [
    {
      id: 'Q1',
      title: '1.本網站提供之機車危險感知影片內容，有助於提升您辨識危險的能力? (5 為非常同意，1 為非常不同意)',
      type: 'radio',
      list: [
        { value: '5', text: '5 非常同意' },
        { value: '4', text: '4 同意' },
        { value: '3', text: '3 普通' },
        { value: '2', text: '2 不同意' },
        { value: '1', text: '1 非常不同意' },
      ],
    },
    {
      id: 'Q2',
      title: '2.請問您，對於測驗影片主題認為哪一項對您的行車安全有幫助?',
      type: 'radio',
      list: [
        { value: '5', text: '機車肇事原因' },
        { value: '4', text: '防禦駕駛' },
        { value: '3', text: '交通部宣導政策' },
        { value: '2', text: '機車特性' },
        { value: '1', text: '以上都有幫助' },
        { value: '0', text: '以上都沒有幫助' },
      ],
    },
    {
      id: 'Q3',
      title: '3.請問您，覺得哪些主題特別重要，需要再加強宣導，以提升機車行車安全? (可複選)必填欄位請問您，覺得哪些主題特別重要，需要再加強宣導，以提升機車行車安全? (可複選)',
      type: 'checkbox',
      list: [
        { value: '1', text: '主題 1-路口讓車問題' },
        { value: '2', text: '主題 2-路段讓車問題' },
        { value: '3', text: '主題 3-行駛錯誤車道' },
        { value: '4', text: '主題 4-方向燈問題' },
        { value: '5', text: '主題 5-保持安全距離' },
        { value: '6', text: '主題 6-車輛闖紅燈' },
        { value: '7', text: '主題 7-違反雙黃線或雙白線' },
        { value: '8', text: '主題 8-閃光號誌' },
        { value: '9', text: '主題 9-逆向斜穿' },
        { value: '10', text: '主題 10-變換車道不當' },
        { value: '11', text: '主題 11-路口附近穿越道路' },
        { value: '12', text: '主題 12-視線遮蔽處有行人' },
        { value: '13', text: '主題 13-前方有特殊狀況' },
        { value: '14', text: '主題 14-注意車輛或行人的行向變化' },
        { value: '15', text: '主題 15-視線問題' },
        { value: '16', text: '主題 16-行經車輛出入口與匝道' },
        { value: '17', text: '主題 17-注意公車停靠' },
        { value: '18', text: '主題 18-路旁狀況' },
        { value: '19', text: '主題 19-注意開車門' },
        { value: '20', text: '主題 20-遭遇特殊車輛' },
        { value: '21', text: '主題 21-視線死角與視線遮蔽' },
        { value: '22', text: '主題 22-彎道' },
        { value: '23', text: '主題 23-遭遇道路施工' },
        { value: '24', text: '主題 24-掌握周邊車輛動態' },
        { value: '25', text: '主題 25-車輛迴轉與倒車' },
        { value: '26', text: '主題 26-車道縮減' },
        { value: '27', text: '主題 27-行駛於狹窄道路' },
        { value: '28', text: '主題 28-違停車輛的影響' },
        { value: '29', text: '主題 29-大型車轉彎' },
        { value: '30', text: '主題 30-大型車遮蔽視線' },
        { value: '31', text: '主題 31-注意內輪差' },
        { value: '32', text: '主題 32-大型車視線死角' },
        { value: '33', text: '主題 33-兩段式左轉問題' },
        { value: '34', text: '主題 34-道路上的障礙' },
        { value: '35', text: '主題 35-其他車輛造成的危險' },
      ],
    },
    {
      id: 'Q4',
      title: '4.請問您，對於危險感知主題哪些主題印象深刻?(可複選)',
      type: 'checkbox',
      list: [
        { value: '1', text: '主題 1-路口讓車問題' },
        { value: '2', text: '主題 2-路段讓車問題' },
        { value: '3', text: '主題 3-行駛錯誤車道' },
        { value: '4', text: '主題 4-方向燈問題' },
        { value: '5', text: '主題 5-保持安全距離' },
        { value: '6', text: '主題 6-車輛闖紅燈' },
        { value: '7', text: '主題 7-違反雙黃線或雙白線' },
        { value: '8', text: '主題 8-閃光號誌' },
        { value: '9', text: '主題 9-逆向斜穿' },
        { value: '10', text: '主題 10-變換車道不當' },
        { value: '11', text: '主題 11-路口附近穿越道路' },
        { value: '12', text: '主題 12-視線遮蔽處有行人' },
        { value: '13', text: '主題 13-前方有特殊狀況' },
        { value: '14', text: '主題 14-注意車輛或行人的行向變化' },
        { value: '15', text: '主題 15-視線問題' },
        { value: '16', text: '主題 16-行經車輛出入口與匝道' },
        { value: '17', text: '主題 17-注意公車停靠' },
        { value: '18', text: '主題 18-路旁狀況' },
        { value: '19', text: '主題 19-注意開車門' },
        { value: '20', text: '主題 20-遭遇特殊車輛' },
        { value: '21', text: '主題 21-視線死角與視線遮蔽' },
        { value: '22', text: '主題 22-彎道' },
        { value: '23', text: '主題 23-遭遇道路施工' },
        { value: '24', text: '主題 24-掌握周邊車輛動態' },
        { value: '25', text: '主題 25-車輛迴轉與倒車' },
        { value: '26', text: '主題 26-車道縮減' },
        { value: '27', text: '主題 27-行駛於狹窄道路' },
        { value: '28', text: '主題 28-違停車輛的影響' },
        { value: '29', text: '主題 29-大型車轉彎' },
        { value: '30', text: '主題 30-大型車遮蔽視線' },
        { value: '31', text: '主題 31-注意內輪差' },
        { value: '32', text: '主題 32-大型車視線死角' },
        { value: '33', text: '主題 33-兩段式左轉問題' },
        { value: '34', text: '主題 34-道路上的障礙' },
        { value: '35', text: '主題 35-其他車輛造成的危險' }
      ],
    },
    {
      id: 'Q5',
      title: '5.您對於測驗時操控的靈敏度及回饋介面是否滿意? (5 為非常同意，1 為非常不同意)',
      type: 'radio',
      list: [
        { value: '5', text: '5 非常同意' },
        { value: '4', text: '4 同意' },
        { value: '3', text: '3 普通' },
        { value: '2', text: '2 不同意' },
        { value: '1', text: '1 非常不同意' },
      ],
    },
    {
      id: 'Q6',
      title: '6.您對於網站使用者介面的流暢度是否滿意? (5 為非常同意，1 為非常不同意)',
      type: 'radio',
      list: [
        { value: '5', text: '5 非常同意' },
        { value: '4', text: '4 同意' },
        { value: '3', text: '3 普通' },
        { value: '2', text: '2 不同意' },
        { value: '1', text: '1 非常不同意' },
      ],
    },
    {
      id: 'Q7',
      title: '7.您對於測驗結果解析內容與呈現方式是否滿意?(5 為非常同意，1 為非常不同意)',
      type: 'radio',
      list: [
        { value: '5', text: '5 非常同意' },
        { value: '4', text: '4 同意' },
        { value: '3', text: '3 普通' },
        { value: '2', text: '2 不同意' },
        { value: '1', text: '1 非常不同意' },
      ],
    },
    {
      id: 'Q8',
      title: '8.您是否憑直覺就能了解影片測驗的操作方式?(5 為非常同意，1 為非常不同意)',
      type: 'radio',
      list: [
        { value: '5', text: '5 非常同意' },
        { value: '4', text: '4 同意' },
        { value: '3', text: '3 普通' },
        { value: '2', text: '2 不同意' },
        { value: '1', text: '1 非常不同意' },
      ],
    },
    {
      id: 'Q9',
      title: '9.您是否推薦家人朋友使用「機車危險感知教育平台」?(5 為非常推薦，1 為非常不推薦)',
      type: 'radio',
      list: [
        { value: '5', text: '5 非常推薦' },
        { value: '4', text: '4 推薦' },
        { value: '3', text: '3 普通' },
        { value: '2', text: '2 不推薦' },
        { value: '1', text: '1 非常不推薦' },
      ],
    }
  ]

  const [form, setForm] = useState([])

  const [searchParams] = useSearchParams();
  const urlSource = searchParams.get("url");
  const navigate = useNavigate();
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
  const [isOpenInputModal, setIsOpenInputModal] = useState(false);
  const [surveyID, setSurveyID] = useState('');
  const [hasError, setHasError] = useState(false);
  const [questionInfo, setQuestionInfo] = useState([])
  const {userTestingUUID } = useContext(context);
  const [reserveExamUrl, setReserveExamUrl] = useState('');
  const [reserveExamToken, setReserveExamToken] = useState('');
  const [httpMethod, setHttpMethod] = useState('');
  const [dateTimeDesc, setDateTimeDesc] = useState('');
  
  const closeSuccessModal = () => {
    setIsOpenSuccessModal(false);
  }

  const openInputModal = () => {
    setIsOpenInputModal(true);
  }

  const closeInputModal = () => {
    setIsOpenInputModal(false);
  }

  const handleRadio = (value, name) => {
    setForm(state => {
      state[name].questionOptionIds = [value];
      return {
        ...state
      }
    })
  }

  const handleCheckbox = (value, name) => {
    setForm(state => {
      if(form[name].questionOptionIds.includes(value)){
        state[name].questionOptionIds = state[name].questionOptionIds.filter((item) => item !== value)
        return {
          ...state
        }
      } else {
        state[name].questionOptionIds.push(value);
        return {
          ...state
        }
      }
    })
  }

  const changeInput = (e) => {
    setForm(state => {
      state[e.target.id].answer = e.target.value
      return {
        ...state
      }
    })
  }

  const submit = useCallback(async() => {
    // validation required
    // const requiredField = ['Q1', 'Q2', 'Q3', 'Q4', 'Q5', 'Q6', 'Q7', 'Q8', 'Q9'];

    // for(const item of requiredField) {
    //   if(form[item] === '' || form[item] === []) {
    //     setHasError(true);
    //     return;
    //   }
    // }

    // Call Submit API
    const formData = {
      surveyUUID: surveyID,
      surveyQuestionAnswerInfos: [],
      testingUUID: userTestingUUID
    }
    let validtae = false;
    form.forEach((item) => {
      if(item.answer === '' && (item.questionOptionIds && !item.questionOptionIds.length) && item.isMultipleCorrectAnswer !== null) {
        validtae = true;
      }

      formData.surveyQuestionAnswerInfos.push({
            questionId : item.questionId,
            questionOptionIds: item.questionOptionIds,
            answer: item.answer
      });
    })

    if(validtae){      
      setHasError(true);
        return;
    }
    try {
      const result = await submitSurvey(formData);
      if(result && result.rc === '0000' && result.data){
        const {url, token, httpMethod} = result.data;
        setReserveExamUrl(url);
        setReserveExamToken(token);
        setHttpMethod(httpMethod);
      }
    } catch (error) {
      console.error('confirmReviewTestingVideo unknow error', error);
    }

    // url === 'main' 從主功能表點擊
    if(urlSource === 'main' ) {
      navigate('/');
    } else {
      setIsOpenSuccessModal(true);
    }
  }, [surveyID, reserveExamUrl, reserveExamToken, httpMethod])

  const fetchData = async () => {
    const surveyInfo = await getSurvey();
    if (surveyInfo && surveyInfo.rc === '0000' && surveyInfo.data) {
        const {surveyUUID, questionInfos, lastModifyDateTimeDesc} = surveyInfo.data;
        questionInfos.forEach((item, index) => {
          form[item.questionId] = {
            questionId : item.questionId,
            questionOptionIds: [],
            answer: '',
            isMultipleCorrectAnswer : item.isMultipleCorrectAnswer
          };
        })
        setQuestionInfo(questionInfos || []);
        setSurveyID(surveyUUID);
        setDateTimeDesc(lastModifyDateTimeDesc);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <main className="questionnaire">
      <PageTitle title="機車危險感知教育平台 - 問卷調查" />
      <BreadcrumbBanner breadcrumb={breadcrumb} hasSidebar={false} dateTimeDesc={dateTimeDesc}/>

      <section className="questionnaire-container">
        <form>
          <div className="required">* 必填</div>

          {questionInfo.map(item =>
          <div className="form-group" key={item.questionId}>
            {item.isMultipleCorrectAnswer != null &&
              <div className="my-2">
                {item.questionId}.{item.description}
                <span className="required mx-1">*</span>
              </div>
            }
            {item.questionOptionInfos.map(subItem =>
            <div className="input-group" key={subItem.questionOptionId}>
              <div className="form-check">
                <input
                  id={`${item.questionId}_${subItem.questionOptionId}`}
                  name={`${item.questionId}${item.questionType}`}
                  type={item.isMultipleCorrectAnswer ? 'checkbox' : 'radio'}
                  className="form-check-input"
                  value={subItem.questionOptionId}
                  aria-label={subItem.label}
                  onChange={() => item.type === "radio"
                  ? handleRadio(subItem.questionOptionId, item.questionId)
                  : handleCheckbox(subItem.questionOptionId, item.questionId)}
                  />
              </div>
              <label
                htmlFor={`${item.questionId}_${subItem.questionOptionId}`}
                >{subItem.label}
              </label>
            </div>)}
          </div>)}

          {questionInfo.map(item =>
            <div className="form-group d-md-inline-block d-print-inline-block"  key={item.questionId}>
            {item.isMultipleCorrectAnswer === null &&
              
              <Fragment>
                  <div className="my-2">
                    {item.questionId}.{item.description}
                  </div>
                  <input
                  id={item.questionId}
                  type="text"
                  placeholder="相關建議"
                  className="form-control"
                  value={form[item.questionId].answer}
                  onChange={changeInput}
                  aria-label="輸入對機車危險感知影片之建議"
                  style={{borderColor: `${form[item.questionId] === '' ? '' : '#333'}`}}
                  />
              </Fragment>
            } 
            </div>
          )}

        </form>

        {hasError && <div className='required text-center mb-3'>請輸入必填欄位</div>}

        <button type="button" className="submit" onClick={submit}>
          提交問卷
        </button>
      </section>

      <SuccessModal isOpenSuccessModal={isOpenSuccessModal} closeSuccessModal={closeSuccessModal} openInputModal={openInputModal} reserveExamUrl={reserveExamUrl} reserveExamToken={reserveExamToken} httpMethod={httpMethod}/>

      <InputModal isOpenInputModal={isOpenInputModal} closeInputModal={closeInputModal} reserveExamUrl={reserveExamUrl} reserveExamToken={reserveExamToken} httpMethod={httpMethod} />
    </main>
  );
}

export default Questionnaire;