import { useRef, useEffect, useCallback } from 'react';
import BreadcrumbList from "../components/Breadcrumb";

const BreadcrumbBanner = (props) => {
  const { breadcrumb, hasSidebar, customTitle, dateTimeDesc } = props;

  const activeTitle = () => {
    return breadcrumb[breadcrumb.length - 1].text;
  }

  const guided = useRef();

  const print = () => {
    window.print();
  }

  const handleKeypress = useCallback((e) => {
    const { key, altKey } = e;
    if (altKey && key === 'c') {
      guided.current.focus();
    }
  },[]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeypress);

    return () => document.removeEventListener('keydown', handleKeypress);

  }, [handleKeypress])

  return (
    <div className={`breadcrumb-banner ${hasSidebar ? 'has-menu' : ''}`}>
      <button type="button" className="guided" title="主要內容顯示區"
        ref={guided}>
        <span>:::</span>
      </button>
      <div className="container">

        <h2 className='banner-title mb-1 mb-md-0'>{customTitle ? customTitle : activeTitle()}</h2>
        <BreadcrumbList breadcrumb={breadcrumb} />

        <button type="button" className='print-btn d-none d-md-flex' onClick={print} title="列印">
          <span className="icon-printer"></span>
          <span className='d-none'>列印</span>
        </button>

        <div className='time'>
        {dateTimeDesc || '111-11-11 00:00'}
        </div>
      </div>
    </div>
  );
}

export default BreadcrumbBanner;