import BreadcrumbBanner from "../components/BreadcrumbBanner";
import { Link, useSearchParams } from "react-router-dom";
import moto from "../assets/pages/ExamInstructions/moto.png";
import icon1 from "../assets/pages/ExamInstructions/icon1.png";
import icon2 from "../assets/pages/ExamInstructions/icon2.png";
import icon3 from "../assets/pages/ExamInstructions/icon3.png";
import icon4 from "../assets/pages/ExamInstructions/icon4.png";
import behavior from "../assets/pages/ExamInstructions/behavior.png";
import question from "../assets/pages/ExamInstructions/question.png";
import mix from "../assets/pages/ExamInstructions/mix.png";
import PageTitle from "../components/PageTitle";

const ExamInstructions = () => {
  const breadcrumb = [
    { text: '首頁', url: '/' },
    { text: '測驗說明', url: '/' },
  ]

  const [searchParams] = useSearchParams();
  const urlfrom = searchParams.get('urlfrom');
  const code = searchParams.get('code');
  const urltype = searchParams.get('urltype') || '';

  const step = [
    {
      image: icon1,
      text: '1. 作答前請詳讀測驗題型及題目內容。'
    },
    {
      image: icon2,
      text: '2.	注意點擊次數限制，請謹慎作答。'
    },
    {
      image: icon3,
      text: '3.測驗影片結束後，可透過影片解析說明，幫助您瞭解影片所傳達的觀念。'
    },
    {
      image: icon4,
      text: '4.點擊上方的「結束測驗」即中斷作答。'
    },
  ]

  const questionType = [
    {
      id: '1',
      image: behavior,
      title: '行為判斷',
      text: '如測驗影片中出現危險時，請點擊「發現危險」按鍵或「影片畫面區域」。',
      url: 'BEHAVIOR'
    },
    {
      id: '2',
      image: question,
      title: '風險問答',
      text: '觀看影片時，當螢幕彈出問答視窗，請點選正確答案。',
      url: 'QUESTIONNAIRE'
    },
    {
      id: '3',
      image: mix,
      title: '複合題型',
      text: '如測驗影片中出現危險時，請點擊「發現危險」按鍵或「影片畫面區域」，當螢幕彈出問答視窗，請點選正確答案。',
      url: 'COMBO'
    }
  ]

  return (
    <div className='exam-instructions'>
      <PageTitle title="機車危險感知教育平台 - 測驗說明" />
      <BreadcrumbBanner breadcrumb={breadcrumb} hasSidebar={false} />

      <div className='instructions-container'>
        <div className="text-center">
          <h2 className="main-title">機車駕駛危險感知測驗</h2>
          <div className="main-desrc fw-bolder">
            <div>本平台之測驗提供您多樣題型</div>
            <div>包括⾏為判斷、風險問答及複合題型</div>
          </div>
          <img src={moto} className='mt-1 img-fluid' alt='機車駕駛危險感知測驗圖片'/>
        </div>

        <div>
          <h3 className="sub-title fw-bold mb-4">測驗說明</h3>
          <div className="text-center">
            本平台測驗題型共計3種，分別有行為判斷、風險問答及複合題型。<br/>
            開始播放影片前請詳讀測驗題型及題目內容，測驗結束螢幕將顯示測驗評分及測驗影片解析說明。
          </div>
          <div className="row text-center">
            {step.map(item =>
            <div className="col-md-3 col-print-3" key={item.text}>
              <div className="step-box">
                <img src={item.image} className='img-fluid' alt={item.text}/>
                <div className="text-start">{item.text}</div>
              </div>
            </div>)}
          </div>
        </div>

        <div>
          {questionType.map(item =>
          <Link
            to={urlfrom ? `/examInstructions/${item.url}?urlfrom=${urlfrom}&code=${code}&urltype=${urltype}` : `/examInstructions/${item.url}`}  className="question-card" key={item.id}>
            <div className="d-flex flex-shrink-0">
              <div className="question-image me-3">
                <img src={item.image} className='img-fluid' alt={`${item.title}題型介紹`}/>
              </div>

              <div className="question-type-box">
                <div className="question-title">{item.title}</div>
                <div className="question-type">題型介紹</div>
              </div>
            </div>

            <div className="question-text-box">
              <div className="question-text">{item.text}</div>

              <div
                className="question-enter mt-2 mt-md-0">
                <span>進入說明</span>
                <span className="icon-chevron-right"></span>
              </div>
            </div>

          </Link>)}
        </div>

      </div>
    </div>
  );
}

export default ExamInstructions;