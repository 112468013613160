import {Fragment, useState, useRef, useEffect, useCallback} from 'react'
import {Link} from 'react-router-dom'
import {Carousel} from 'react-responsive-carousel'
import {getHomeData} from '../API/request'

import '../styles/helpers/carousel.scss'
import iconTopic from '../assets/pages/home/iconTopic.png'
import iconReserve from "../assets/pages/home/iconReserve.png"
import iconDanger from '../assets/pages/home/iconDanger.png'
import iconExam from '../assets/pages/home/iconExam.png'
import reserveDesc from "../assets/pages/home/reserve/desc.png";
import reserveImage from "../assets/pages/home/reserve/reserve.png";
import reserveSubsidy from "../assets/pages/home/reserve/subsidy.png";
import reason from '../assets/pages/home/danger/reason.png'
import shield from '../assets/pages/home/danger/shield.png'
import policy from '../assets/pages/home/danger/policy.png'
import spec from '../assets/pages/home/danger/spec.png'
import young from '../assets/pages/home/exam/young.png'
import youngMobile from '../assets/pages/home/exam/young_m.png'
import old from '../assets/pages/home/exam/old.png'
import oldMobile from '../assets/pages/home/exam/old_m.png'
import back from '../assets/pages/home/purpose/back.png'
import side from '../assets/pages/home/purpose/side.png'
import { domTextLimit, domHtmlTextLimit } from "../helps/textLimit";


const Home = () => {
    const [carouselList, setCarouselList] = useState([])
    const [topic, setTopic] = useState([])
    const [examPurpose, setExamPurpose] = useState([])
    const [exam, setExam] = useState([])
    const [danger, setDanger] = useState([])
    const [reserve, setReserve] = useState([])

    useEffect(() => {
        const exam = [
            {
                id: 1,
                desktopImg: old,
                mobileImg: oldMobile,
                title: '',
                url: '/examOverview/',
            },
            {
                id: 2,
                desktopImg: young,
                mobileImg: youngMobile,
                title: '',
                url: '/examOverview/',
            },
        ]

        const defaultExamPurpose = [
            {
                id: 'purpose',
                title: '測驗目的',
                text: '',
                img: back,
            },
            {
                id: 'thinking',
                title: '測驗思維',
                text: '',
                img: side,
            },
        ]

        const danger = [
            {
                id: 1,
                img: reason,
                title: '',
                url: '/',
            },
            {id: 2, img: shield, title: '', url: '/'},
            {id: 3, img: policy, title: '', url: '/'},
            {id: 4, img: spec, title: '', url: '/'},
        ]

        const reserve = [
            {
              id: 1,
              title: '預約考照與駕訓補助流程說明',
              image: reserveDesc,
              url: '/reserve/index',
            },
            {
              id: 2,
              title: '預約考照-進行測驗',
              image: reserveImage,
              url: '/examOverview?emvtoken=emvtoken',
            },
            {
              id: 3,
              title: '駕訓補助-進行測驗',
              image: reserveSubsidy,
              url: '/examOverview?emvtoken=emvtoken',
            },
          ]

        const fetchHomeData = async () => {
            try {
                const res = await getHomeData()
                if (res.data && res.rc === '0000') {
                    const result = res.data
                    setCarouselList(result.webImagesSliderInfoList || [])
                    setTopic(result.videoTopicInfoList || [])
                    if (result.purpose) {
                        defaultExamPurpose[0].text = result.purpose
                    }
                    if (result.thinking) {
                        defaultExamPurpose[1].text = result.thinking
                    }
                    setExamPurpose(defaultExamPurpose)
                    if (result.testCategoryList) {
                        const {testCategoryList} = result
                        exam[0].url += testCategoryList[2].code
                        exam[0].title = testCategoryList[2].name
                        exam[1].url += testCategoryList[3].code
                        exam[1].title = testCategoryList[3].name
                    }
                    setExam(exam)
                    if (result.videoBaseList) {
                        const {videoBaseList} = result
                        videoBaseList.forEach((info, infoIndex) => {
                            danger[infoIndex].code = info.code
                            danger[infoIndex].title = info.name
                            danger[
                                infoIndex
                            ].url = `/topicOverview/${info.code}`
                        })
                        setDanger(danger)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchHomeData()
        setReserve(reserve)
    }, [])

    const [isSlideMore, setIsSlideMore] = useState(true)
    const guided = useRef()
    const topicTitle= useRef([]);
    const topicText= useRef([]);
    const [isFocusSlideBtn, setIsFocusSlideBtn] = useState(false);


    const toggleSlide = () => {
        setIsSlideMore(!isSlideMore)
    }

    const handleKeypress = useCallback((e) => {
        const {key, altKey} = e
        if (altKey && key === 'c') {
            guided.current.focus()
        }
    }, [])

    const slideBtnFocus = () => {
        setIsFocusSlideBtn(true);
      }
    
      const leaveFocus = () => {
        setIsFocusSlideBtn(false);
      }

    useEffect(() => {
        document.addEventListener('keydown', handleKeypress)

        return () => document.removeEventListener('keydown', handleKeypress)
    }, [handleKeypress])

    useEffect(() => {
        domTextLimit(topicText.current, 52);

      }, [topic])
    
    useEffect(() => {
        domHtmlTextLimit(document.querySelectorAll('.legend-text'), 250);
        // 更改 carousel dots 順序
        // 因 dots 為套件自動生成，為了 tab focus 順序，將 dots 插入到最後面
        if (document.querySelector('.carousel-slider')) document.querySelector('.carousel-slider').append(document.querySelector('.control-dots'));
        if (document.querySelector('.selected .circle-toggle')) document.querySelector('.selected .circle-toggle').setAttribute('tabIndex', 0);
    }, [carouselList])
    

    return (
        <Fragment>
            <main className="home-page">
                <section>
                    <div className="text-center py-3 position-relative">
                        <button
                            type="button"
                            className="guided"
                            title="主要內容顯示區"
                            accesskey="C" 
                            id="content" 
                            tabIndex={0}
                            ref={guided}
                        >
                            <span>:::</span>
                        </button>

                        
                        <div className="home-container">
                            <h2 className="home-title h2 mt-1">
                                機車危險感知教育平台
                            </h2>
                            <div className="home-text h5 mt-2">
                                <span>
                                    本網站是專為提升機車騎士，行車安全所規劃的網站。
                                </span>
                                <span className="d-md-block">
                                    藉由觀看實境影片，測驗您對行駛道路時，所遇到行車危險的認知。
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* 手機版 Carousel 隱藏 */}
                    {/* {carouselList.length > 0 ? (
                        <Carousel
                          showArrows={false}
                          swipeable={true}
                          showThumbs={false}
                          showIndicators={false}
                          useKeyboardArrows={true}
                          showStatus={false}
                          infiniteLoop={true}
                          autoPlay={true}
                          interval={15000}
                          className="mobile d-md-none"
                      >
                          {carouselList.map((item, itemIndex) => (
                              <div key={`img_m_${itemIndex}`}>
                                  <img src={item.webImageUrl} alt="" />
                                  <div
                                      className={`legend h3 ${
                                          isSlideMore ? 'expend' : ''
                                      }`}
                                  >
                                      <h2
                                          className="legend-title h3"
                                          onClick={toggleSlide}
                                      >
                                          <span className="banner-title">{item.imageTitle}</span>
                                          <span className="icon-chevron-top"></span>
                                      </h2>

                                      <div
                                          className="legend-text h6"
                                          dangerouslySetInnerHTML={{
                                              __html: item.imageDescription,
                                          }}
                                      ></div>
                                  </div>
                              </div>
                          ))}
                      </Carousel>
                    ):[]} */}

                    {carouselList.length > 0 ? (
                        <Carousel
                            showArrows={false}
                            swipeable={true}
                            showThumbs={false}
                            useKeyboardArrows={true}
                            showStatus={false}
                            infiniteLoop={true}
                            autoPlay={true}
                            interval={15000}
                            className="desktop d-none d-md-block"
                            renderIndicator={(onClickHandler, isSelected, index, label) => {
                                return (
                                  <button
                                    className={`dot ${isSelected ? 'selected' : ''}`}
                                    onClick={onClickHandler}
                                    onKeyDown={onClickHandler}
                                    value={index}
                                    key={index}
                                    type="button"
                                    tabIndex={0}
                                    aria-label={`${label} ${index + 1}`}
                                  >
                                  </button>
                                );
                            }}
                        >
                            {carouselList.map((item, itemIndex) => (
                                <div key={`img_${itemIndex}`}>
                                    <img src={item.webImageUrl} alt="" />

                                    <div
                                        className={`legend ${
                                            isSlideMore ? 'expend' : ''
                                        }`}
                                    >
                                        <div className="legend-text-box">
                                            <div className="d-flex align-items-center mb-3">
                                                <span className="icon-bot h5 me-2">
                                                    <span className="path1"></span>
                                                    <span className="path2"></span>
                                                </span>
                                                <h2 className="h3 banner-title">
                                                    {item.imageTitle}
                                                </h2>
                                            </div>

                                            <div
                                                className="legend-text h6"
                                                dangerouslySetInnerHTML={{
                                                    __html: item.imageDescription,
                                                }}
                                            ></div>

                                            <button
                                                className={`circle-toggle ${isFocusSlideBtn ? 'fake-focus' : ''}`}
                                                onClick={toggleSlide}
                                                tabIndex={0}
                                                onFocus={slideBtnFocus}
                                                onBlur={leaveFocus}
                                            >
                                                <span
                                                    className="icon-arrow-circle" 
                                                    style={{transform: `${isSlideMore ? 'scaleX(-1)' : 'scaleX(1)'}`}}></span>
                                                <span style={{display: 'none'}}>circle-toggle</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    ) : (
                        []
                    )}
                </section>

                <section className="reserve-box py-5 home-box">
                <div className="home-container">
                    <div className="d-flex align-items-center mb-2">
                    <img src={iconReserve} alt='' className="title-icon me-3" />
                    <h2 className='title h2'>預約考照與駕訓補助專區</h2>
                    </div>

                    <div className="row">
                    {reserve.map(item => <Link to={item.url} state={{ isGeneralTest: false }} className="col-12 col-md-4" key={item.id}>
                        <div className={`reserve-card  text-center h3 bg${item.id} p-3`}>
                        <div>
                            <img src={item.image} alt='' className="reserve-img img-fluid"/>
                        </div>
                        <div className="reserve-text">{item.title}</div>
                        </div>
                    </Link>)}
                    </div>
                </div>
                </section>

                <section className="topic-box py-5 home-box">
                    <div className="home-container">
                        <div className="d-flex align-items-center mb-2">
                            <img
                                src={iconTopic}
                                alt=""
                                className="title-icon me-3"
                            />
                            <h2 className="title h2">每月主題</h2>
                        </div>
                        <div className="row">
                            {topic.map((item, i) => (
                                <Link
                                    to={`/topicArticle/${item.code}`}
                                    className="topic-card-box col-md-6 my-2"
                                    key={item.code}
                                    title={item.name}
                                >   
                                    <div className="topic-card">
                                        <div>
                                            <h3 className="topic-title h3 mb-2"
                                            ref={(el) => (topicTitle.current[i] = el)}>{item.name}</h3>
                                            <div className="topic-text h5 fw-normal"
                                            ref={(el) => (topicText.current[i] = el)}>{item.description}</div>
                                        </div>

                                        <div className="circle-out">
                                            <div className="circle-in"></div>
                                        </div>

                                        <div className="topic-more h4">
                                            了解更多
                                            <span className="icon-chevron-right"></span>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </section>

                <section className="danger-box py-5 home-box">
                    <div className="home-container">
                        <div className="d-flex align-items-center mb-2">
                            <img
                                src={iconDanger}
                                alt=""
                                className="title-icon me-3"
                            />
                            <h2 className="title h2">危險感知總覽</h2>
                        </div>

                        <div className="row px-1 px-md-0">
                            {danger.map((item) => (
                                <Link
                                    to={item.url}
                                    className="col-6 col-md-3 p-1 p-md-2 text-center"
                                    key={item.id}
                                    title={item.title}
                                >
                                    <img
                                        src={item.img}
                                        alt={item.title}
                                        className="danger-img img-fluid"
                                    />
                                </Link>
                            ))}
                        </div>
                    </div>
                </section>

                <section className="exam-box py-5 home-box">
                    <div className="home-container">
                        <div className="d-flex align-items-center mb-2">
                            <img
                                src={iconExam}
                                alt=""
                                className="title-icon me-3"
                            />
                            <h2 className="title h2">行車安全測驗</h2>
                        </div>

                        <div className="row">
                            {exam.map((item) => (
                                <div className="col-md-6 my-2" key={item.id}>
                                    <Link
                                        to={item.url}
                                        title={item.title}
                                        className="exam-card"
                                    >
                                        <img
                                            src={item.desktopImg}
                                            alt={item.title}
                                            className="d-none d-md-block img-fluid"
                                        />
                                        <img
                                            src={item.mobileImg}
                                            alt={item.title}
                                            className="d-md-none img-fluid w-100"
                                        />
                                        <span className="d-none">
                                            行車安全測驗
                                        </span>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className="purpose-box">
                    <div className="purpose-bg">
                        <div className="home-container">
                            <div className="purpose-dialogs">
                                {examPurpose.map((item) => (
                                    <Fragment key={item.id}>
                                        <div
                                            className={`purpose-dialog ${
                                                item.id === 'purpose'
                                                    ? 'blue'
                                                    : 'pink'
                                            }`}
                                        >
                                            <h2 className="purpose-title 3 mb-2">
                                                {item.title}
                                            </h2>
                                            <div
                                                style={{position: 'relative', zIndex: '1'}}
                                                className="h5"
                                                dangerouslySetInnerHTML={{
                                                    __html: item.text,
                                                }}
                                            ></div>
                                        </div>

                                        <div className="purpose-img">
                                            <img
                                                src={item.img}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Fragment>
    )
}

export default Home
