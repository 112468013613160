import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import ReserveId from './pages/ReserveId';
import TopicOverview from './pages/TopicOverview';
import ExamOverview from './pages/ExamOverview';
import VideoExam from './pages/VideoExam';
import ExamInstructions from './pages/ExamInstructions';
import ExamInstructionsId from './pages/ExamInstructionsId';
import ExamResult from './pages/ExamResult';
import Questionnaire from './pages/Questionnaire';
import VideoExplain from './pages/VideoExplain';
import TopicArticle from './pages/TopicArticle';
import Sitemap from './pages/Sitemap';
import Privacy from './pages/Privacy';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "./hooks/context";
import { useState } from "react";
import ScrollToTop from "./helps/ScrollToTop";
import "./styles/main.scss";

const App = () => {
  const [userTestingUUID, setUserTestingUUID] = useState('');
  const [userVideoAnswer, setUserVideoAnswer] = useState([]);
  const [userExplainWatched, setUserExplainWatched] = useState([]);
  const [isFromMvdis, setIsFromMvdis] = useState(false);
  const [categoryCode, setCategoryCode] = useState('');
  const [examCode, setExamCode] = useState('');
  const [emvToken, setEmvToken] = useState('');
  const [examExplain, setExamExplain] = useState(null);
  const [isFirstExamResult, setIsFirstExamResult] = useState(true);
  const [isGeneralTest, setIsGeneralTest] = useState(true);

  const contextValue = {
    userTestingUUID,
    setUserTestingUUID,
    userVideoAnswer,
    setUserVideoAnswer,
    userExplainWatched,
    setUserExplainWatched,
    isFromMvdis,
    setIsFromMvdis,
    categoryCode,
    setCategoryCode,
    examCode,
    setExamCode,
    emvToken,
    setEmvToken,
    examExplain,
    setExamExplain,
    isFirstExamResult,
    setIsFirstExamResult,
    isGeneralTest,
    setIsGeneralTest
  }

  return (
    <Provider value={contextValue}>
      <Router basename={process.env.REACT_APP_BASE_URL || "/"}>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='reserve/:id' element={<ReserveId />}></Route>
          <Route path='topicOverview' element={<TopicOverview />}></Route>
          <Route path='topicOverview/:id' element={<TopicOverview />}></Route>
          <Route path='examOverview' element={<ExamOverview />}></Route>
          <Route path='examOverview/:id' element={<ExamOverview />}></Route>
          <Route path='videoExam/:token' element={<VideoExam />}></Route>
          <Route path='videoExam/:code/:id' element={<VideoExam />}></Route>
          <Route path='videoExam/topic/:topicCode/:topicId' element={<VideoExam />}></Route>
          <Route path='examInstructions' element={<ExamInstructions />}></Route>
          <Route path='examInstructions/:id' element={<ExamInstructionsId />}></Route>
          <Route path='examResult/:id' element={<ExamResult />}></Route>
          <Route path='questionnaire' element={<Questionnaire />}></Route>
          <Route path='videoExplain/:testId/:id' element={<VideoExplain />}></Route>
          <Route path='topicArticle/:id' element={<TopicArticle />}></Route>
          <Route path='sitemap' element={<Sitemap />}></Route>
          <Route path='*' element={<Home/>}></Route>
          <Route path='privacy' element={<Privacy />}></Route>
        </Routes>
        <Footer />
      </Router>
    </Provider>
  );
}

export default App;