import { useState, useEffect, useCallback, useRef, useContext } from 'react';
import BreadcrumbBanner from "../components/BreadcrumbBanner";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import ReactPlayer from 'react-player';
import OnlyText from "../components/videoExam/OnlyText";
import TextAndImage from "../components/videoExam/TextAndImage";
import TitleImage from "../components/videoExam/TitleImage";
import context from "../hooks/context";
import { getReviewTestingVideo, confirmReviewTestingVideo } from '../API/request';
import { parseExamsAnswer } from "../helps/common";
import PageTitle from "../components/PageTitle";

const VideoExplain = () => {
  const params = useParams();
  const examId = params.testId
  const paramsId = params.id;
  const navigate = useNavigate();
  const defaultBreadcrumb = [
    { text: '首頁', url: '/' },
    { text: '影片測驗', url: '/examOverview' },
    { text: '測驗結果', url: `/examResult/${examId}` },
    { text: '影片解析', url: '/' },
  ]

  const validateString = (str) => {
    let _str = "";
      for (let index = 0; index < str.length; index++) {
        let escape = "";
        switch (str.charCodeAt(index)) {
          case 34: // "
            escape = "&quot;";
            break;
          case 38: // &
            escape = "&amp;";
            break;
          case 39: // '
            escape = "&#x27;";
            break;
          case 60: // <
            escape = "&lt;";
            break;
          case 62: // >
            escape = "&gt;";
            break;
          default:
            escape = str[index];
          // continue;
        }
        _str += escape;
      }
    return _str;
  }

  const getUserAgent = () =>
     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && (window.orientation !== 180 && window.orientation !== 0);

  const [searchParams] = useSearchParams();
  const examType = searchParams.get('type');
  const mode = searchParams.get('mode');
  const [isMobileUserAgent, setUserAgent] = useState(false);
  const [examsAnswer, setExamsAnswer] = useState({});
  const [isReturn, setIsReturnr] = useState(false);
  const [showSeconds, setShowSeconds] = useState(-1);
  const [breadcrumb, setBreadcrumb] = useState(defaultBreadcrumb);
  const [examData, setExamData] = useState({
    isPlaying: false,
    isShowStart: true,
    played: 0,
    showQuestion: false,
    isDone: false,
    isDisabledReturn: true
  })
  const [dateTimeDesc, setDateTimeDesc] = useState('');
  const [behaviorAnsScope, setBehaviorAnsScope] = useState({
    marginLeft: 0,
    width: 0
  });

  const [userAnswer, setUserAnswer] = useState();
  const [userBehaviorPostion, setUserBehaviorPostion] = useState();
  const { userVideoAnswer, setUserExplainWatched, setExamExplain, isFirstExamResult, setIsFirstExamResult } = useContext(context);
  const player = useRef();
  // const pauseBtn = useRef();
  const returnBtn = useRef();
  const [videoTestingMode, setVideoTestingMode] = useState('');
  const [seconds, setSeconds] = useState(0);
  const intervalId = useRef();
  const playVideo = () => {
    setExamData(state => ({
      ...state,
      isPlaying: true,
      isShowStart: false
    }))


    if(returnBtn) {
      const timer = setTimeout(() => {
        returnBtn.current.focus();
        clearTimeout(timer)
      }, 100);
    }

  }

  // const handlePlaying = () => {
  //   setExamData(state => ({
  //     ...state,
  //     isPlaying: !state.isPlaying,
  //     isShowStart: false
  //   }))
  // }

  const handleBehaviorAnsScope = useCallback(() => {
    if(!examsAnswer.videoRiskPoints) return;

    const durationSeconds = examsAnswer.videoDuration;

    const ansStart = examsAnswer.videoRiskPoints[0].timeRangeFrom;

    const ansEnd = examsAnswer.videoRiskPoints[0].timeRangeTo;
    const width = ((ansEnd - ansStart) / durationSeconds) * 100;

    setBehaviorAnsScope({
      marginLeft: (ansStart / durationSeconds) * 100,
      width
    })
  }, [examsAnswer])

  const handleComboAnsScope = useCallback(() => {
    if(!examsAnswer.questionAndAnswerInfo) return;

    const durationSeconds = examsAnswer.videoDuration;

    const ansStart = examsAnswer.questionAndAnswerInfo.timeRangeFrom;

    const ansEnd = examsAnswer.questionAndAnswerInfo.timeRangeTo;

    setBehaviorAnsScope({
      marginLeft: (ansStart / durationSeconds) * 100,
      width: ((ansEnd - ansStart) / durationSeconds) * 100
    })
  }, [examsAnswer])

  const getUserAnswer = useCallback(() => {
    const userAns = examsAnswer.clickTimeList;
    const durationSeconds = player.current.getDuration();

    switch (examsAnswer.videoTestingMode) {
      case 'BEHAVIOR':
        if(!userAns) {
          setUserBehaviorPostion([]);
          return;
        }

        const userAnswerPosition = userAns.map(item => {
          return (item / durationSeconds) * 100;
        })
        setUserBehaviorPostion(userAnswerPosition)

        break;

      case 'COMBO':

        if(!userAns) {
          setUserBehaviorPostion([]);
          return;
        }
        const userBehaviorAnswerPosition = userAns.map(item => {
          return (item / durationSeconds) * 100;
        })
        setUserBehaviorPostion(userBehaviorAnswerPosition)

        break;

      default:
        break;
    }
  }, [examsAnswer, userVideoAnswer])

  const handleDuration = () => {
    if(examsAnswer.videoTestingMode === 'BEHAVIOR'){
      handleBehaviorAnsScope();
    } else if (examsAnswer.videoTestingMode === 'COMBO') {
      handleComboAnsScope();
    }

    getUserAnswer();
  }

  const seekToSecond = (idx) => {
    const userSecond = userAnswer[idx];
    player.current.seekTo(userSecond, 'seconds')
  }

  const doneForQuestion = () => {
    setExamData(state => ({
      ...state,
      isPlaying: true,
      showQuestion: false
    }))
  }

  const handleEnded = () => {
    setExamData(state => ({
      ...state,
      isPlaying: false,
      isShowStart: false,
      isEnabledReturn: true
    }))

    // 播放完畢自動跳回測驗結果頁
    // returnExam();
  }

  const transferSecond = (time) => {
    const minute = Number(time.split(':')[0]);
    const second = Number(time.split(':')[1]);
    return minute * 60 + second;
  }

  const handleProgress = useCallback((progress) => {
    const durationSeconds = player.current.getDuration();
    const currentSeconds = player.current.getCurrentTime() ? player.current.getCurrentTime().toFixed(2) : 0;
    const played = Math.round(((parseFloat(currentSeconds) + 1.3) / durationSeconds) * 100) / 100;
    let updateState = {};

    if (!examData.isDone && showSeconds != -1 && currentSeconds >= showSeconds) {
      updateState = {
        showQuestion: true,
        isDone: true
      };
    }
    
    setExamData(state => ({
      ...state,
      played: examData.isShowStart ? 0 : played,
      isDisabledReturn: examsAnswer.isReviewVideoFlag && currentSeconds < examsAnswer.reviewVideoMinSeconds,
    }))
    // console.info(`currentSeconds: ${currentSeconds}`);
    // console.info(`played: ${played}`);
    switch (videoTestingMode) {
      case 'QUESTIONNAIRE':
      case 'COMBO':
        const quesTime = showSeconds;

        if(currentSeconds > quesTime) {
          setExamData(state => ({
            ...state,
            isPlaying: false,          
            ...updateState
          }))
          const timer = setTimeout(() => {
            setExamData(state => ({
              ...state,
              isDisabledReturn: false
            }));
            clearTimeout(timer)
          }, examsAnswer.dwellSeconds * 1000);
          intervalId.current = setInterval(() => {
            setSeconds(seconds => seconds + 1);
          }, 1000);
        }
        break;       
      default:        
        setExamData(state => ({
          ...state,
          ...updateState
        }))
        break;
    }

    // if(progress.played >= 1) {
    //   setExamData(state => ({
    //     ...state,
    //     played: 0
    //   }))
    // }
  }, [examsAnswer, userVideoAnswer, examData])

  const fetchData = useCallback(async () => {
    try {
      const result = await getReviewTestingVideo(paramsId);

      if (result?.rc === '0000' && result?.data) {
        const {scoreType, testingResultDesc, videoTestingMode, 
          reviewVideoUrl, isReviewVideoFlag, clickTimeList, videoRiskPoints, questionAndAnswerInfo, lastModifiedDateTimeDesc} = result.data;
          let events = {};

          if (questionAndAnswerInfo?.triggerType) {
            let type;
            let showTime = null;
            let showTimeRange = null;
            let questionOptions = [];
            const {triggerType, triggerTime, timeRangeFrom, timeRangeTo, question, questionImageUrl, isMultipleCorrectAnswer, answerOptionList, qaType } = questionAndAnswerInfo;
            if (triggerType === 'AUTO') {
              const min = parseInt(triggerTime / 60, 10);
              const sec = triggerTime % 60;
              let secTxt = (sec > 9) ? `${sec}` : `0${sec}`;
              showTime = `0${min}:${secTxt}`;
            } else if (triggerType === 'CLICK') {
              const startMin = parseInt(timeRangeFrom / 60, 10);
              const startSec = timeRangeFrom % 60;
              let startSecTxt = (startSec > 9) ? `${startSec}` : `0${startSec}`;
              const endMin = parseInt(timeRangeTo / 60, 10);
              const endSec = timeRangeTo % 60;
              let endSecTxt = (endSec > 9) ? `${endSec}` : `0${endSec}`;
              showTimeRange = {
                start: `0${startMin}:${startSecTxt}`, 
                end: `0${endMin}:${endSecTxt}`, 
              };
            }
            if (answerOptionList?.length > 0) {
              questionOptions = answerOptionList.map(({optionId,option,optionImageUrl}) => ({ no: optionId,text: option,optionImage: optionImageUrl}));              
            }
            if (qaType === 'Q_IMG') {
              type = 'TitleImage';
            } else if (qaType === 'A_IMG') {
              type = 'TextAndImage';
            } else {
              type = 'OnlyText';
            }
            events = {
              questionText: question,
              triggerType,
              showTime,
              showTimeRange,
              single: !isMultipleCorrectAnswer,
              type,
              questionImage: questionImageUrl,
              questionOptions
            };
          }

          const showResponse = {
            ...result.data,
            ...events
          };
          if (showResponse.videoTestingMode === 'QUESTIONNAIRE') {
            const { showTime } = showResponse;
            const quesTime = transferSecond(showTime);
            setShowSeconds(quesTime);
          } else if (showResponse.videoTestingMode === 'COMBO') {
            const userAns = showResponse.clickTimeList;
            if(!userAns) return [];
            const endTime = showResponse.showTimeRange.end;
            const endSecond = transferSecond(endTime);
            setShowSeconds(endSecond);
          }
          setVideoTestingMode(showResponse.videoTestingMode);
          setUserAnswer(clickTimeList);
          setExamsAnswer(showResponse);
          setUserExplainWatched(idx => {
            if(idx.includes(paramsId)) {
              return idx
            } else {
              return [...idx, paramsId]
            }
          });
          setDateTimeDesc(lastModifiedDateTimeDesc);
      }
    } catch (error) {
      console.log(error);
    }
  }, [paramsId, examId, setUserExplainWatched])

  const returnExam = useCallback(async() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
    let currentSeconds = player.current.getCurrentTime() ? player.current.getCurrentTime().toFixed(2) : 0;
    if (currentSeconds > examsAnswer.reviewVideoSeconds) {
      console.debug('currentSeconds:', currentSeconds, ',reviewVideoSeconds:', examsAnswer.reviewVideoSeconds);
      currentSeconds = examsAnswer.reviewVideoSeconds;
    }
    if(seconds > 0){
      currentSeconds = Number(currentSeconds) + seconds;
    }
    try {
      const result = await confirmReviewTestingVideo(currentSeconds, paramsId);
      if (result?.rc === '0000' && result?.data) {
        const showResponse = parseExamsAnswer(result);
        setExamExplain(showResponse);
        setIsReturnr(true);
        setDateTimeDesc(showResponse.lastModifiedDateTimeDesc);
      } else {
        console.error('confirmReviewTestingVideo error', result);
      }
    } catch (error) {
      console.error('confirmReviewTestingVideo unknow error', error);
    }
  })

  window.addEventListener("onorientationchange" in window ? "orientationchange" : "resize", function() {
    setUserAgent(getUserAgent());
  }, false);

  useEffect(() => {
    setExamExplain(null);
    fetchData();
  }, [fetchData])

  useEffect(() => {
    setUserAgent(getUserAgent());
  }, [])

  useEffect(() => {
    if (examsAnswer) {
      if (mode === 'EMV') return;
      const {testCategoryCode, testCategoryName, videoBaseCode, videoBaseName, videoCategoryCode, videoCategoryName, videoTopicCode, videoTopicName, videoTestingModeCode, videoTestingModeName, questionnaireName, testCategoryIdx, questionnaireId } = examsAnswer;
      if (testCategoryCode && testCategoryName) {
        const { text, url}  = breadcrumb[3];
        const url2 = breadcrumb[2].url;
        const text2 = breadcrumb[2].text;
        breadcrumb[2] = { text: testCategoryName, url: `/examOverview/${testCategoryCode}` };
        breadcrumb[3] = { text: questionnaireName, url: `/videoExam/${testCategoryCode}/${testCategoryIdx}` };
        breadcrumb[4] = { text: text2, url: url2 };
        breadcrumb[5] = { text, url };

      } else if (videoTestingModeCode && videoTestingModeName && mode === 'TRAINING') {
        const { text, url}  = breadcrumb[3];
        const url2 = breadcrumb[2].url;
        const text2 = breadcrumb[2].text;
        breadcrumb[1] = { text: '測驗說明', url: `/examInstructions` };
        breadcrumb[2] = { text: videoTestingModeName, url: `/examInstructions/${videoTestingModeCode}` };
        breadcrumb[3] = { text: '練習測驗', url: `/examInstructions/${videoTestingModeCode}` };
        breadcrumb[4] = { text: text2, url: url2 };
        breadcrumb[5] = { text, url };

      } else if (videoBaseCode && videoBaseName){
        const { text, url}  = breadcrumb[3];
        const url2 = breadcrumb[2].url;
        const text2 = breadcrumb[2].text;
        breadcrumb[1] = { text: videoBaseName, url: `/topicOverview/${videoBaseCode}` };
        breadcrumb[2] = { text: videoCategoryName, url: `/topicOverview/${videoCategoryCode}` };
        breadcrumb[3] = { text: videoTopicName, url: `/topicArticle/${videoTopicCode}` };
        breadcrumb[4] = { text: questionnaireName, url: `/videoExam/topic/${videoTopicCode}/${questionnaireId}` };
        breadcrumb[5] = { text: text2, url: url2 };
        breadcrumb[6] = { text, url };
      } 
      setBreadcrumb(breadcrumb);
    }
    
  }, [examsAnswer]);

  useEffect(() => {
    if (isReturn && !isFirstExamResult && examId) {
      navigate(`/examResult/${validateString(examId)}${examType?`?type=${validateString(examType)}`:''}`, { replace: true });
    }
  }, [isFirstExamResult, isReturn])

  useEffect(() => {
    switch (videoTestingMode) {
      case 'QUESTIONNAIRE':
      case 'COMBO':
        break;       
      default:        
        if (examData.isDone && examData.showQuestion) {
          const timer = setTimeout(() => {
            setExamData(state => ({
              ...state,
              showQuestion: false
            }));
            clearTimeout(timer)
          }, 3000);
        }
        break;
    }    
  }, [examData])

  useEffect(() => {
    setIsFirstExamResult(false);
  }, [isFirstExamResult])

  return (
    <main className="video-exam explain">
      <PageTitle title="機車危險感知教育平台 - 影片解析" />
      <BreadcrumbBanner breadcrumb={breadcrumb} hasSidebar={false} dateTimeDesc={dateTimeDesc} />
      <section className={`${isMobileUserAgent ? '' : 'p-3 pt-0 p-lg-0'}`}>
        <div className="video-container">
          <div className={`d-flex justify-content-end ${isMobileUserAgent ? 'px-3 px-lg-0' : ''}`}>
            <button ref={returnBtn} type="button" className='btn-end video-explain-btn-end video-explain btn-outline' disabled={examData.isDisabledReturn} onClick={returnExam}>
              返回測驗結果
              <span className="icon-return ms-2"></span>
            </button>
          </div>

          <div className="video-box">
            <div className={`${isMobileUserAgent ? 'player-box' : ''}`}>
              <div className="player-wrapper">
                <ReactPlayer
                  url={examsAnswer.reviewVideoUrl}
                  width='100%'
                  height='100%'
                  className="react-player"
                  ref={player}
                  onEnded={handleEnded}
                  onProgress={handleProgress}
                  playing={examData.isPlaying}
                  playsinline={true}
                  onDuration={handleDuration}
                  progressInterval={100}
                />
              </div>

              {examData.isShowStart && <div className='play-box'>
                <button type='button' className='btn-play' onClick={playVideo}>
                  <span className='icon-play'></span>
                </button>
              </div>}

              {examsAnswer.videoTestingMode !== 'QUESTIONNAIRE' && (
                <div className={'legend-box h6'}>
                  <div className='legend'>
                    <span className="icon-triangle"></span>
                    <span className='ms-1 ms-md-2'>有效點擊位置</span>
                  </div>
                  <div className='legend'>
                    <span className="icon-triangle invalid"></span>
                    <span className='ms-1 ms-md-2'>無效點擊位置</span>
                  </div>
                  <div className='legend'>
                    <span className="answer-bar"></span>
                    <span className='ms-1 ms-md-2'>判斷區間</span>
                  </div>
                </div>
              )}

              <div className='answer-scope'>
                <div className='answer-bar' style={{
                  marginLeft: `${behaviorAnsScope.marginLeft}%`,
                  width: `${behaviorAnsScope.width}%`
                }}></div>
              </div>

              <progress max={1} value={examData.played} className={`progress-bar ${examData.isPlaying ? 'transition' : ''}`}/>

              {/* {!examData.isShowStart &&
              <button type="button" className='pause' onClick={handlePlaying} ref={pauseBtn}>
                {examData.isPlaying && <span className="icon-pause"></span>}
                {!examData.isPlaying && <span className="icon-triangle play"></span>}
              </button>} */}

              {userBehaviorPostion && <div className='triangle-box'>
                {userBehaviorPostion.map((item, idx) =>
                  <span
                    className={`icon-triangle ${idx >= examsAnswer.clickLimit ? 'invalid' : ''}`}
                    style={{ left: `calc(${item}% - 0.4rem)` }}
                    key={item}
                    // onClick={() => seekToSecond(idx) }
                    ></span>)
                    }
              </div>}

              {examData.showQuestion && <div className={`video-question h6 ${examData.showQuestion ? 'semi-darkness video-explain' : ''}`}>

                {examsAnswer.type === 'OnlyText' &&
                <OnlyText
                  question={examsAnswer}
                  doneForQuestion={doneForQuestion}
                  userAnswer={examsAnswer.selectedOptions}
                  correctAnswer={examsAnswer.correctOptions}
                />}

                {examsAnswer.type === 'TextAndImage' &&
                <TextAndImage
                  question={examsAnswer}
                  doneForQuestion={doneForQuestion}
                  userAnswer={examsAnswer.selectedOptions}
                  correctAnswer={examsAnswer.correctOptions}
                />}

                {examsAnswer.type === 'TitleImage' &&
                <TitleImage
                  question={examsAnswer}
                  doneForQuestion={doneForQuestion}
                  userAnswer={examsAnswer.selectedOptions}
                  correctAnswer={examsAnswer.correctOptions}
                />}
              </div>}
            </div>
          </div>

          <div className={`${isMobileUserAgent ? 'px-3 px-lg-0' : ''}`}>
            <div className='explain-box'>
              <div className='explain-title'>
                <span className="icon-Check me-2"></span>
                <span>{examsAnswer.thinkingTitle}</span>
              </div>
              <div className='h5' dangerouslySetInnerHTML={{__html: examsAnswer.thinking}}></div>
            </div>
          </div>
        </div>
      </section>

    </main>
  );
}

export default VideoExplain;