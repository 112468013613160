class LocalStorage {
    constructor() {
        this.storage = window.localStorage
        this.keys = [
            'isFirstTime',
            'clientInfo',
            'videoAttributeTypeList',
            'answer',
            'user-session',
        ]
        this.userSession = this.getItem(this.keys[4]);
    }
    setItem(items) {
        if (items?.length > 0) {
            items.forEach(({key, value}) => {
                this.storage.setItem(key, JSON.stringify(value))
            })
        }
    }

    getItem(key) {
        try {
            return JSON.parse(this.storage.getItem(key))
        } catch (error) {
            return null
        }
    }

    getKey() {
        return this.storage.key()
    }

    removeItem(key) {
        this.storage.removeItem(key)
    }

    clear() {
        this.storage.clear()
    }

    setUserSession(userSession) {
        if (this.userSession === '') {
            this.userSession = this.getItem(this.keys[4]);
        }
        if (this.userSession !== userSession) {
            console.info('old userSession:', this.userSession, ',new userSession:', userSession)
            this.userSession = userSession
            this.setItem([{key: this.keys[4], value: userSession}, {key: this.keys[0], value: true}])
        }
    }
}

const localStorage = new LocalStorage()
Object.seal(localStorage)
export default localStorage
