const ImageModal = ({ image, title, closeModal }) => {
  const stopPropagation = (e) => {
    e.stopPropagation();
  }

  return (
    <div className="position-fixed image-modal d-flex justify-content-center align-items-center" onClick={closeModal}>
      <div className="image-box position-relative" onClick={stopPropagation}>
        <img src={image} alt={title} className="img-fluid" />

        <button type="button" className="btn-close icon-clear" onClick={closeModal}></button>
      </div>
    </div>
  )
}

export default ImageModal;